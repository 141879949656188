import { Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { ToolType } from "api/resources/aiTools/types"
import clsx from "clsx"
import LoadingOverlay from "components/LoadingOverlay"
import useTabs, { Tabs } from "components/TabBar/hooks/useTabs"
import { getToolNamePlural } from "features/AItools/helper"
import { useFeatureToggle } from "global/FeatureToggleProvider"

import TabContent from "../TabContent"

const tabs: Tabs = [
  { name: "All", content: TabContent },
  {
    toolType: ToolType.lessonPlan,
    name: getToolNamePlural(ToolType.lessonPlan),
    content: () => <TabContent type={ToolType.lessonPlan} />,
  },
  {
    toolType: ToolType.rubrics,
    name: getToolNamePlural(ToolType.rubrics),
    content: () => <TabContent type={ToolType.rubrics} />,
  },
  {
    toolType: ToolType.quiz,
    name: getToolNamePlural(ToolType.quiz),
    content: () => <TabContent type={ToolType.quiz} />,
  },
  {
    toolType: ToolType.handout,
    name: getToolNamePlural(ToolType.handout),
    content: () => <TabContent type={ToolType.handout} />,
  },
  {
    toolType: ToolType.narration,
    name: getToolNamePlural(ToolType.narration),
    content: () => <TabContent type={ToolType.narration} />,
  },
  {
    toolType: ToolType.subjectiveAssessment,
    name: getToolNamePlural(ToolType.subjectiveAssessment),
    content: () => <TabContent type={ToolType.subjectiveAssessment} />,
  },
  {
    toolType: ToolType.assignment,
    name: getToolNamePlural(ToolType.assignment),
    content: () => <TabContent type={ToolType.assignment} />,
  },
  {
    toolType: ToolType.activityPlanner,
    name: getToolNamePlural(ToolType.activityPlanner),
    content: () => <TabContent type={ToolType.activityPlanner} />,
  },
  {
    toolType: ToolType.videoSummary,
    name: getToolNamePlural(ToolType.videoSummary),
    content: () => <TabContent type={ToolType.videoSummary} />,
  },
  {
    toolType: ToolType.unitPlanner,
    name: getToolNamePlural(ToolType.unitPlanner),
    content: () => <TabContent type={ToolType.unitPlanner} />,
  },
]

const HomeSidebar = () => {
  const [activeTab, setActiveTab] = useTabs({ tabs })

  const featureToggle = useFeatureToggle()
  const inactiveTools = featureToggle.aiTools.inactiveTools || []

  // This is just used to see if there is any history. Don't worry about pagination here.
  // Individual tabs will handle their own pagination
  const historyQuery = useInfiniteQuery({
    queryKey: queries.aiTools.listHistory().queryKey,
    queryFn: () =>
      api.aiTools.listHistory({
        type: null,
        params: { page: 1 },
      }),
  })

  const history = historyQuery.data?.pages.flatMap(i => i.data)

  return (
    <div className="absolute right-0 top-0 hidden h-full w-[420px] overflow-hidden border border-solid border-surface-200 pr-0 pt-3 md:grid">
      <div className="overflow-y-auto">
        <Typography variant="title3" className="mb-3 pl-3">
          History
        </Typography>
        {historyQuery.isLoading && <LoadingOverlay />}
        {historyQuery.isSuccess && history && history.length === 0 && (
          <Typography variant="body" className="px-3">
            Your past generations will show up here. Click on any tool and start
            generating!
          </Typography>
        )}

        {historyQuery.isSuccess && history && history.length > 0 && (
          <>
            <div className="w-full overflow-hidden">
              <div className="flex flex-nowrap gap-1 overflow-auto pb-2  pl-3 pt-1">
                {tabs
                  .filter(x => !inactiveTools.includes(x.toolType))
                  .map(i => (
                    <Button
                      key={i.name}
                      className={clsx({
                        "!bg-onSurface-100": i.name !== activeTab.name,
                      })}
                      size="sm"
                      onClick={() => setActiveTab(i.name)}
                      color={i.name === activeTab.name ? "primary" : "white"}
                      rounded
                    >
                      {i.name}
                    </Button>
                  ))}
              </div>
            </div>
            <div className="px-3">
              <activeTab.content />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default HomeSidebar
