import { Typography } from "@suraasa/placebo-ui"
import { ToolType } from "api/resources/aiTools/types"
import useTabs, { Tabs } from "components/TabBar/hooks/useTabs"
import { useHandoutWithLessonPlanner } from "features/AItools/components/Generator/LessonPlanGenerator/useHandoutWithLessonPlanner"
import { LessonPlannerReturnType } from "features/AItools/components/Generator/LessonPlanGenerator/useLessonPlanner"
import { useNarrationWithLessonPlanner } from "features/AItools/components/Generator/LessonPlanGenerator/useNarrationWithLessonPlanner"
import { useNarration } from "features/AItools/components/Generator/NarrationGenerator/useNarration"
import Paper from "features/AItools/components/Paper"
import { getToolName } from "features/AItools/helper"
import React, { useState } from "react"

import OutputToolbar from "../../../OutputToolbar"
import ToolOutput from "../../../ToolOutput"
import LessonPlanTabs, { TabNames } from "../../LessonPlanTabs"
import HandoutContent from "./HandoutContent"
import NarrationContent from "./NarrationContent"

const toolType = ToolType.lessonPlan

const tabs: Tabs = [
  {
    name: TabNames.lessonPlan,
    content: () => <div />,
  },
  {
    name: TabNames.handout,
    content: () => <div />,
  },
  {
    name: TabNames.narration,
    content: () => <div />,
  },
]
export type ContentType = Pick<
  LessonPlannerReturnType,
  | "isLoading"
  | "regenerateResponse"
  | "userVote"
  | "setCurrentResponseId"
  | "currentStreamValues"
  | "finalizedOutputs"
  | "refetchOverview"
  | "onBack"
>

const Content = ({
  sequence,
  isLoading,
  regenerateResponse,
  userVote,
  currentStreamValues,
  finalizedOutputs,
  responseIds,
  handoutIds,
  narrationIds,
  refetchOverview,
  onBack,
  disableTabs,
  ...props
}: {
  handoutIds: { id: number; responseIds: number[] }[]
  narrationIds: { id: number; responseIds: number[] }[]
  sequence: number
  responseIds: number[]
  disableTabs?: boolean
} & ContentType) => {
  const [activeTab, setActiveTab] = useTabs({ tabs, useSearchParams: false })

  const [currentResponseId, setState] = useState(
    responseIds && responseIds[0] ? responseIds[0] : undefined
  )
  const handoutContext = useHandoutWithLessonPlanner({
    handoutIds: handoutIds,
    onSuccess() {
      refetchOverview()
    },
  })
  const narrationContext = useNarrationWithLessonPlanner({
    narrationIds: narrationIds,
    onSuccess() {
      refetchOverview()
    },
  })

  const outputData =
    finalizedOutputs.find(item => item.id === currentResponseId)?.output ||
    currentStreamValues
  const isPositiveResponse = finalizedOutputs.find(
    item => item.id === currentResponseId
  )?.isPositiveResponse

  const setCurrentResponseId = (id: number, t?: { skipCall: boolean }) => {
    setState(id)
    if (!t?.skipCall) {
      props.setCurrentResponseId(id)
    }
  }

  return (
    <Paper className="!p-0">
      <div className="flex items-center gap-1 p-3">
        <div>
          <Typography
            variant="title2"
            color="common.white.500"
            className="rounded-3xl bg-black px-3 py-0 text-white"
          >
            {sequence}
          </Typography>
        </div>
        <Typography variant="title2">{getToolName(toolType)}</Typography>
      </div>

      <LessonPlanTabs
        className="mb-0"
        tabs={tabs}
        disable={
          disableTabs || handoutContext.isLoading || narrationContext.isLoading
            ? tabs
                .map(item => item.name)
                .filter(item => item !== activeTab.name)
            : []
        }
        activeTabName={activeTab.name}
        onClick={state => {
          setActiveTab(state)
        }}
      />

      {activeTab.name === TabNames.lessonPlan && (
        <>
          <div>
            <ToolOutput
              isLoading={isLoading}
              onClick={onBack}
              className="px-4 py-3"
            >
              {outputData}
            </ToolOutput>
          </div>
          {!isLoading && (
            <OutputToolbar
              toolType={ToolType.lessonPlanPro}
              responseIds={responseIds}
              currentResponseId={currentResponseId || null}
              copyText={outputData}
              isPositiveResponse={isPositiveResponse}
              onResponseIdChange={responseId => {
                setCurrentResponseId(responseId)
              }}
              onUserReaction={reaction => {
                userVote.mutate({ reaction, isPro: true })
              }}
              onRegenerate={reason => {
                regenerateResponse.mutate({
                  reason,
                  id: currentResponseId,
                  isPro: true,
                  onSuccess(id) {
                    setCurrentResponseId(id, { skipCall: true })
                  },
                })
              }}
            />
          )}
        </>
      )}
      {activeTab.name === TabNames.handout && (
        <HandoutContent
          {...handoutContext}
          handoutIds={handoutIds}
          lessonPlanId={currentResponseId}
          onBack={onBack}
        />
      )}
      {activeTab.name === TabNames.narration && (
        <NarrationContent
          {...narrationContext}
          narrationIds={narrationIds}
          lessonPlanId={currentResponseId}
          onBack={onBack}
        />
      )}
    </Paper>
  )
}

export default React.memo(Content)
