import { CircularProgress } from "@suraasa/placebo-ui"
import { ToolType } from "api/resources/aiTools/types"
import { getToolName } from "features/AItools/helper"

import Heading from "../../Heading"
// import ActivityPlannerOutput from "../GenerationOutput/ActivityPlannerOutput"
import ContentActions from "../GenerationOutput/Export/ContentActions"
import VideoSummaryOutput from "../GenerationOutput/VideoSummaryOutput"
import ProForm from "./ProForm"
import { useVideoSummary } from "./useVideoSummary"
const VideoSummaryGenerator = ({
  toggleHistorySideBar,
}: {
  toggleHistorySideBar: (state: boolean) => void
}) => {
  const {
    currentResponseId,
    setCurrentResponseId,
    onBack,
    promptDataId,
    pageLoading,
    ...videoSummaryContext
  } = useVideoSummary()

  if (promptDataId && pageLoading) {
    return (
      <div className="grid place-items-center p-4">
        <CircularProgress />
      </div>
    )
  }
  if (currentResponseId) {
    return (
      <>
        <ContentActions
          onBack={onBack}
          currentResponseId={currentResponseId}
          toolType={ToolType.videoSummary}
          title={videoSummaryContext.overviewData?.title}
          toggleHistorySideBar={toggleHistorySideBar}
        />

        <VideoSummaryOutput
          {...videoSummaryContext}
          currentResponseId={currentResponseId}
          setCurrentResponseId={setCurrentResponseId}
          onBack={onBack}
        />
      </>
    )
  }
  return (
    <>
      <Heading
        title={`${getToolName(ToolType.videoSummary)} Generator`}
        subtitle="Get clear and concise summaries of YouTube videos, perfect for quick reviews or lesson integration."
        toggleHistorySideBar={toggleHistorySideBar}
        isPro
      />
      <ProForm {...videoSummaryContext} />
    </>
  )
}

export default VideoSummaryGenerator
