import { useMenuState } from "@szhsin/react-menu"
// import { DueAssignmentList } from "api/resources/assignments/types"
import {
  Enrollment,
  LearningRestrictions,
} from "api/resources/learningItems/types"
import { Profile } from "api/resources/profile/types"
import {
  Email,
  SocialAccount,
  UserPreference,
} from "api/resources/settings/types"
import { AuthData } from "api/resources/users/types"
import { ITOParticipationDetails } from "features/ITO/useITOAccess"
import React, { useContext } from "react"
import { saveAuthInfo } from "utils/helpers"

export type PlatformSettings = null | { [x: string]: UserPreference }
export type ResumeFillerStageType = "fetch-filler" | "done" | null

type Context = {
  authInfo: AuthData | null
  setAuthInfo: (auth: AuthData) => void
  logout: (options?: { redirect?: boolean }) => void
  isAuthenticated: boolean
  itoParticipationDetails: ITOParticipationDetails
  emails: Email[]
  updateEmails: (emails: Email[]) => void
  // null means loading
  enrollments: Enrollment[] | null
  profile: Profile
  fetchProfile: () => void
  notificationsPopupMenuState: ReturnType<typeof useMenuState>
  learningRestrictions?: LearningRestrictions[]
  platformSettings: PlatformSettings
  // dueAssignmentList?: DueAssignmentList[]
  // retrieveResumeFiller: {
  //   data?: ResumeFillerType | null
  //   isLoading: boolean
  //   id?: number
  // }
  // updateResumeFillerStage: (type: ResumeFillerStageType) => void
  // resumeFillerStage: ResumeFillerStageType
  socialAccounts: SocialAccount[]
  refetchSocialAccounts: () => Promise<any>
}

export const defaultContextValue: Context = {
  authInfo: null,
  logout: () => {},
  setAuthInfo: saveAuthInfo,
  isAuthenticated: false,
  notificationsPopupMenuState: [
    { state: undefined, endTransition: () => {} },
    () => {},
  ],
  emails: [],
  updateEmails: () => {},
  enrollments: null,
  itoParticipationDetails: {
    currentITOAttemptStatus: undefined,
    pastYears: {
      isITO2022Participant: undefined,
    },
    itoRegistrationDetailsLoaded: false,
    itoRegistrationDetails: undefined,
    registrationStatus: "notStarted",
    waitlistStatus: undefined,
    timeline: undefined,
  },
  profile: {
    id: "",
    totalWorkingExperience: 0,
    careerAspiration: null,
    countriesWorkedIn: [],
    country: null,
    currentWorkingExperience: null,
    dateOfBirth: null,
    gender: null,
    isVerified: false,
    lookingForJobs: false,
    nationality: null,
    phoneNumber: null,
    picture: null,
    state: null,
    user: {
      uuid: "",
      email: "",
      firstName: "",
      lastName: null,
      middleName: "",
      username: "",
    },
  },
  learningRestrictions: [],
  // dueAssignmentList: [],
  fetchProfile: () => {},
  platformSettings: null,
  // retrieveResumeFiller: {
  //   data: undefined,
  //   isLoading: false,
  //   id: undefined,
  // },
  // updateResumeFillerStage: () => {},
  // resumeFillerStage: null,
  socialAccounts: [],
  refetchSocialAccounts: async () => {},
}

export const context = React.createContext<Context>(defaultContextValue)

export const useGlobalContext = () => useContext(context)
