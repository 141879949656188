import {
  Button,
  Checkbox,
  Select,
  TextArea,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import CreatingFor from "features/AItools/components/CreatingFor"
import Paper from "features/AItools/components/Paper"
import {
  BLOOMS_TAXONOMY_OPTIONS,
  DURATION_MINUTES,
} from "features/AItools/helper"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Link } from "react-router-dom"

import { BasicFormType, LessonPlannerReturnType } from "../useLessonPlanner"

type Props = {
  onGenerateHandout: () => void
  onGenerateNarration: () => void
} & Pick<
  LessonPlannerReturnType,
  "generateTool" | "formData" | "preferredPromptData"
>

type AutoFillableFields = "grade" | "curriculum" | "subject" | "country"

const BasicForm = (props: Props) => {
  const {
    control,
    register,
    setError,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<BasicFormType>({
    defaultValues: props.formData?.basicForm || {
      grade: props.preferredPromptData?.grade || "",
      curriculum: props.preferredPromptData?.curriculum || "",
      subject: props.preferredPromptData?.subject || "",
      country: props.preferredPromptData?.country || "",
    },
  })

  const [isEditing, setEditing] = useState(true)

  useEffect(() => {
    const prefillFields: AutoFillableFields[] = [
      "grade",
      "curriculum",
      "subject",
      "country",
    ]

    if (prefillFields.every(field => props.preferredPromptData?.[field])) {
      setEditing(false)
    }

    reset(
      props.formData?.basicForm || {
        grade: props.preferredPromptData?.grade || "",
        curriculum: props.preferredPromptData?.curriculum || "",
        subject: props.preferredPromptData?.subject || "",
        country: props.preferredPromptData?.country || "",
      }
    )
  }, [props.preferredPromptData])

  const onSubmit = handleSubmit(async data => {
    if (data.generateHandout) {
      props.onGenerateHandout()
    }
    if (data.generateNarration) {
      props.onGenerateNarration()
    }

    await props.generateTool.mutateAsync({ data, setError })
  })

  const subject = watch("subject")
  const country = watch("country")
  const curriculum = watch("curriculum")
  const grade = watch("grade")

  return (
    <Paper>
      <form onSubmit={onSubmit} className="flex w-full flex-col gap-2">
        {!isEditing && (
          <CreatingFor
            country={country}
            curriculum={curriculum}
            grade={grade}
            subject={subject}
            onEditClick={() => setEditing(true)}
          />
        )}
        <div className="flex flex-wrap gap-2 md:flex-nowrap">
          {isEditing && (
            <TextField
              required
              label="Class/Grade"
              placeholder="Ex: 10th Grade"
              errors={errors.grade?.message}
              {...register("grade", {
                required: { value: true, message: "Required" },
              })}
            />
          )}
          {isEditing && (
            <TextField
              {...register("curriculum", {
                required: { value: true, message: "Required" },
              })}
              required
              label="Curriculum"
              placeholder="Ex: IB"
              errors={errors.curriculum?.message}
            />
          )}
        </div>
        <div className="flex flex-wrap gap-2 md:flex-nowrap">
          {isEditing && (
            <TextField
              required
              label="Subject"
              placeholder="Ex: Chemistry"
              errors={errors.subject?.message}
              {...register("subject", {
                required: { value: true, message: "Required" },
              })}
            />
          )}
          {isEditing && (
            <TextField
              {...register("country", {
                required: { value: true, message: "Required" },
              })}
              required
              label="Country"
              placeholder="Ex: United Arab Emirates"
              errors={errors.country?.message}
              helperText="We use this to tailor the lesson plan to your region"
            />
          )}
        </div>
        <TextArea
          required
          rows={3}
          label="topic"
          placeholder="Ex: Analyzing the Chemical Properties and Reactions of Acids and Bases, with a Focus on pH, Neutralization, and Their Applications in Everyday Life"
          errors={errors.topic?.message}
          {...register("topic", {
            required: { value: true, message: "Required" },
          })}
        />
        <div className="flex flex-wrap gap-2 md:flex-nowrap">
          <Controller
            control={control}
            name="duration"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                required
                label="Class Duration"
                errors={errors.duration?.message}
                options={DURATION_MINUTES}
                placeholder="60 minutes"
                value={DURATION_MINUTES.find(c => c.value === value)}
                mountOnBody
                onBlur={onBlur}
                onChange={val => onChange(val?.value)}
              />
            )}
            rules={{ required: { value: true, message: "Required" } }}
          />

          <div className="flex flex-[50%] shrink-0 flex-col">
            <Controller
              control={control}
              name="bloomTaxonomyLevel"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  required
                  isMulti
                  closeMenuOnSelect={false}
                  label="Bloom's Taxonomy Level"
                  errors={errors.bloomTaxonomyLevel?.message}
                  options={BLOOMS_TAXONOMY_OPTIONS}
                  placeholder="Ex: Analyse, Apply"
                  value={
                    value &&
                    BLOOMS_TAXONOMY_OPTIONS.filter(c => value.includes(c.value))
                  }
                  mountOnBody
                  onBlur={onBlur}
                  onChange={val => onChange(val.map(x => x.value))}
                />
              )}
              rules={{ required: { value: true, message: "Required" } }}
            />
            <Link
              to="https://www.suraasa.com/blog/learning-taxonomies"
              target="_blank"
            >
              <Typography
                className="mt-0.5 text-interactive-500 hover:underline"
                variant="smallBody"
              >
                What is Bloom&apos;s Taxonomy?
              </Typography>
            </Link>
          </div>
        </div>
        <div>
          <div>
            <Controller
              control={control}
              name="generateHandout"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  containerClass="flex space-x-1"
                  label={
                    <div>
                      <Typography>Generate Handout</Typography>
                      <Typography
                        variant="smallBody"
                        className="text-onSurface-500"
                      >
                        Handouts are supplementary materials that help students
                        understand the lesson better.
                      </Typography>
                    </div>
                  }
                  checked={value}
                  onCheckedChange={checked => onChange(checked)}
                />
              )}
            />
          </div>
          <div className="mt-2">
            <Controller
              control={control}
              name="generateNarration"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  containerClass="flex space-x-1"
                  label={
                    <div>
                      <Typography>Generate Narration</Typography>
                      <Typography
                        variant="smallBody"
                        className="text-onSurface-500"
                      >
                        Narration is a concise guide outlining how a teacher
                        will teach, including objectives, strategies, materials,
                        assessments, and closure.
                      </Typography>
                    </div>
                  }
                  checked={value}
                  onCheckedChange={checked => onChange(checked)}
                />
              )}
            />
          </div>
        </div>
        <Button type="submit" loading={isSubmitting}>
          Generate Lesson Plan
        </Button>
      </form>
      {/* <AiToolsLoading type={toolType} open={isLoading} /> */}
    </Paper>
  )
}

export default BasicForm
