import { Typography } from "@suraasa/placebo-ui"
import { useEffect, useState } from "react"
import Joyride, { Step } from "react-joyride"
import { BROWSER_STORAGE_KEYS, isLocalhost } from "utils/constants"
import { getAuthInfo, sleep } from "utils/helpers"
import { UserPreferenceManager } from "utils/userPreferenceManager"

import { PROFILE_ONBOARDING_TARGETS } from "./constants"
import GetStarted from "./GetStarted"
import CustomTooltip from "./OnboardingStep"

const { profileTourCompleted } = BROWSER_STORAGE_KEYS

const ProfileOnboarding = () => {
  const isCompleted = UserPreferenceManager.get(profileTourCompleted) === "true"

  const [step, setStep] = useState<"intro" | "joyride" | null>(null)

  useEffect(() => {
    const auth = getAuthInfo()
    if (!auth || auth.shadow) return

    if (isCompleted) {
      setStep(null)
      return
    }

    ;(async () => {
      // Start the onboarding after a delay
      await sleep(1500)
      setStep("intro")
    })()
  }, [isCompleted])

  const onComplete = () => {
    setStep(null)
    UserPreferenceManager.set(profileTourCompleted, "true")
  }

  const handleJoyrideCallback = data => {
    const { status, lifecycle } = data

    const tourCompletedStatuses = ["finished"]
    if (tourCompletedStatuses.includes(status) && lifecycle === "complete") {
      onComplete()
    }
  }

  const steps: Step[] = [
    {
      target: `.${PROFILE_ONBOARDING_TARGETS.videoProfile}`,
      content: (
        <div>
          <Typography variant="title3" className="mb-1 text-start">
            Add Video Portfolio
          </Typography>
          <Typography
            variant="smallBody"
            className="mb-3 text-start text-onSurface-600"
          >
            Adding a video portfolio can boost your chances of landing a job.
          </Typography>
        </div>
      ),
      disableBeacon: true,
      placement: "top",
    },
    {
      target: `.${PROFILE_ONBOARDING_TARGETS.resume}`,
      content: (
        <div>
          <Typography variant="title3" className="mb-1 text-start">
            Add Resume
          </Typography>
          <Typography
            variant="smallBody"
            className="mb-3 text-start text-onSurface-600"
          >
            Create and add your resume, and keep it up to date to ease your job
            search.
          </Typography>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: `.${PROFILE_ONBOARDING_TARGETS.academics}`,
      content: (
        <div>
          <Typography variant="title3" className="mb-1 text-start">
            Add Academic Qualifications
          </Typography>
          <Typography
            variant="smallBody"
            className="mb-3 text-start text-onSurface-600"
          >
            Add your academic qualifications to help recruiters understand your
            academic background
          </Typography>
        </div>
      ),
      disableBeacon: true,
      placement: "top",
    },
    // {
    //   target: `.${PROFILE_TARGET_KEYS.profileStrength}`,
    //   content: (
    //     <div>
    //       <Typography variant="title3" className="mb-1 text-start">
    //         Profile Strength
    //       </Typography>
    //       <Typography
    //         variant="smallBody"
    //         className="mb-3 text-start text-onSurface-600"
    //       >
    //         Profile strength measures how complete and active your Suraasa
    //         profile is.
    //       </Typography>
    //     </div>
    //   ),
    //   disableBeacon: true,
    //   placement: "bottom",
    // },
  ]

  return (
    <div>
      <GetStarted
        open={step === "intro"}
        handleContinue={() => {
          setStep("joyride")
        }}
        handleClose={isLocalhost ? () => setStep(null) : undefined}
      />
      <Joyride
        steps={steps}
        run={step === "joyride"}
        tooltipComponent={CustomTooltip}
        continuous
        disableOverlayClose
        scrollOffset={200}
        disableCloseOnEsc
        hideBackButton
        hideCloseButton
        callback={handleJoyrideCallback}
      />
    </div>
  )
}

export default ProfileOnboarding
