import { CircularProgress } from "@suraasa/placebo-ui-legacy"
import { context } from "global/Context/context"
import { useContext, useLayoutEffect, useState } from "react"
import { Outlet, useSearchParams } from "react-router-dom"
import { redirectToLogin } from "utils/helpers"
import { UserPreferenceManager } from "utils/userPreferenceManager"

import { processAuthCode } from "./processAuthCode"

const Authentication = () => {
  const { isAuthenticated, setAuthInfo } = useContext(context)

  const [userPreferencesLoading, setUserPreferencesLoading] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const authCode = searchParams.get("code")

  useLayoutEffect(() => {
    const getPreferences = async () => {
      setUserPreferencesLoading(true)
      await UserPreferenceManager.init()
      setUserPreferencesLoading(false)
    }

    getPreferences()
  }, [isAuthenticated])

  useLayoutEffect(() => {
    if (authCode) {
      processAuthCode(authCode).then(auth => {
        setAuthInfo(auth)
        searchParams.delete("code")
        setSearchParams(searchParams)
      })
      return
    }

    if (!isAuthenticated) {
      redirectToLogin()
    }
  }, [isAuthenticated, authCode])

  return (
    <>
      {(!isAuthenticated || userPreferencesLoading) && (
        <div className="flex items-center justify-center p-4">
          <CircularProgress />
        </div>
      )}
      {isAuthenticated && !userPreferencesLoading && <Outlet />}
    </>
  )
}

export default Authentication
