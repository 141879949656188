import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Typography,
} from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { APIError } from "api/utils"
import Stars from "assets/AITools/stars-gradient.json"
import PDFSvg from "assets/PDFSvg.svg"
import clsx from "clsx"
import TextSlider from "features/AItools/components/AiToolsLoading/TextSlider"
import ProfileContext from "features/Profile/context"
import { PROFILE_ONBOARDING_TARGETS } from "features/Profile/ProfileOnboarding/constants"
import { ProfileQuickAccessTabs, usePreviewMode } from "features/Profile/utils"
import { NavArrowDown, Upload } from "iconoir-react"
import { useContext, useState } from "react"
import { createUseStyles } from "react-jss"
import Lottie from "react-lottie"
import { buildUserName, getAuthInfo, handleErrors } from "utils/helpers"

import AddResume from "../Overview/AddResume"
import Section from "../Section"
import SectionContent from "../Section/SectionContent"
import SectionHeading from "../Section/SectionHeading"
import SectionTitle from "../Section/SectionTitle"
import FakePDF from "./FakePDF"
import PDFViewer from "./PDFViewer"

const useStyles = createUseStyles({
  sectionContainer: {
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
})

const Resume = () => {
  const [openSheet, setOpenSheet] = useState(false)
  const { isViewingAsOtherPlatform, previewMode } = usePreviewMode()

  const classes = useStyles()
  const isLoggedIn = Boolean(getAuthInfo())

  const [uploadResumeDialogOpen, setUploadResumeDialogOpen] = useState(false)

  // const { retrieveResumeFiller, resumeFillerStage } = useContext(context)
  const resumeFillerStage = null
  const { profile, isPublic, updateProfile } = useContext(ProfileContext)

  const { resume, user } = profile

  const fullName = buildUserName(user)

  const { mutate } = useMutation({
    mutationFn: () =>
      api.profile.update({
        data: { resume: null },
      }),
    onSuccess: res => {
      if (res.isSuccessful) updateProfile({ resume: null })
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  const NotUploaded = (
    <Typography className="text-onSurface-400" variant="smallBody">
      {profile.user.firstName} has not uploaded their resume yet.
    </Typography>
  )

  /**
   * This only caters public view
   */
  const PublicResumeComponent = (() => {
    if (!isPublic) return null

    if (isViewingAsOtherPlatform || previewMode) {
      if (resume) return <PDFViewer url={resume} />
      return NotUploaded
    }

    return <FakePDF name={fullName} />
  })()

  if (resumeFillerStage === "fetch-filler") {
    return (
      <div className="flex flex-col-reverse items-start gap-2 border border-onSurface-200 bg-white p-2 sm:rounded-lg md:flex-row md:items-center">
        <TextSlider
          textArray={[
            "Analyzing resume.....",
            "Gathering necessary details......",
          ]}
          className="h-3.5"
        />
        <Lottie
          isClickToPauseDisabled
          style={{
            cursor: "default",
            marginBottom: 10,
            width: 60,
            height: 60,
            marginLeft: 0,
          }}
          speed={1.75}
          options={{
            loop: true,
            autoplay: true,
            animationData: Stars,
          }}
        />
      </div>
    )
  }
  if (resumeFillerStage === "done") {
    return (
      <>
        {/* {retrieveResumeFiller.data && retrieveResumeFiller.id && (
          <ResumeProfileFiller
            data={retrieveResumeFiller.data}
            open={openSheet}
            resumeFillerId={retrieveResumeFiller.id}
            onClose={() => {
              setOpenSheet(false)
            }}
          />
        )} */}

        <div className="flex flex-col items-center gap-2 bg-interactive-500 p-2 !text-white sm:rounded-lg md:flex-row ">
          <Typography variant="subtitle2">
            Identified few items from resume which can be added to your profile.
          </Typography>
          <Button
            rounded
            color="white"
            size="sm"
            onClick={() => {
              setOpenSheet(true)
            }}
            className="!w-full !text-interactive-500 md:!w-max"
          >
            Review Items
          </Button>
        </div>
      </>
    )
  }
  if (isPublic) {
    return (
      <Section id={ProfileQuickAccessTabs.resume}>
        <SectionHeading
          heading={<SectionTitle title="Resume" />}
          xPadding={2}
        />
        <SectionContent xPadding={2} className={classes.sectionContainer}>
          <div>{PublicResumeComponent}</div>
        </SectionContent>
      </Section>
    )
  }

  return (
    <>
      <Section
        className={PROFILE_ONBOARDING_TARGETS.resume}
        id={ProfileQuickAccessTabs.resume}
      >
        <SectionHeading
          heading={
            <SectionTitle
              actionIcon={
                isLoggedIn && resume ? (
                  <Button
                    variant="text"
                    component="a"
                    href={resume}
                    download="resume.pdf"
                    target="_blank"
                  >
                    View
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    onClick={() => setUploadResumeDialogOpen(true)}
                    startAdornment={<Upload />}
                  >
                    Upload
                  </Button>
                )
              }
              title="Resume"
            />
          }
          xPadding={2}
        />
        <SectionContent className={clsx(classes.sectionContainer)}>
          {resume && (
            <div className="flex flex-wrap items-center justify-between gap-1">
              <div className="flex items-center space-x-1">
                <img
                  src={PDFSvg}
                  alt="pdf-icon"
                  className="h-7 w-5 shadow-[0px_3px_7.5px_0px_#0000000D]"
                />
                <div>
                  <Typography variant="strong">
                    {profile.user.firstName}&apos;s Resume
                  </Typography>
                </div>
              </div>
              <div className="flex flex-wrap items-center gap-1.5">
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <Button
                      endAdornment={<NavArrowDown />}
                      size="sm"
                      rounded
                      variant="outlined"
                      className="!border-onSurface-400"
                      color="black"
                    >
                      Edit Resume
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem
                      onClick={() => {
                        setUploadResumeDialogOpen(true)
                      }}
                    >
                      Change Resume
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="text-critical-500"
                      onClick={() => mutate()}
                    >
                      Delete Resume
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
          )}
          {!resume && (
            <Typography variant="smallBody" className="text-onSurface-400">
              Upload your resume to make it visible to schools
            </Typography>
          )}
        </SectionContent>
      </Section>
      <AddResume
        open={uploadResumeDialogOpen}
        onClose={() => setUploadResumeDialogOpen(false)}
      />
    </>
  )
}

export default Resume
