import { Button, Select, TextField, Typography } from "@suraasa/placebo-ui"
import { LessonPlanProForm } from "api/resources/aiTools/types"
import CreatingFor from "features/AItools/components/CreatingFor"
import {
  BLOOMS_TAXONOMY_OPTIONS,
  DURATION_MINUTES,
} from "features/AItools/helper"
import { useSuraasaPlusContext } from "features/AItools/PRO/context/SuraasaPlusContext"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Link } from "react-router-dom"

import Paper from "../../../Paper"
import { LessonPlannerReturnType } from "../useLessonPlanner"

type Form = LessonPlanProForm

type AutoFillableFields = "grade" | "curriculum" | "subject" | "country"

const ProForm = (
  props: Pick<
    LessonPlannerReturnType,
    "generateProTool" | "formData" | "preferredPromptData"
  >
) => {
  const {
    control,
    register,
    setError,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<Form>({
    defaultValues: props.formData?.proForm || {
      grade: props.preferredPromptData?.grade || "",
      curriculum: props.preferredPromptData?.curriculum || "",
      subject: props.preferredPromptData?.subject || "",
      country: props.preferredPromptData?.country || "",
    },
  })

  useEffect(() => {
    const prefillFields: AutoFillableFields[] = [
      "grade",
      "curriculum",
      "subject",
      "country",
    ]

    if (prefillFields.every(field => props.preferredPromptData?.[field])) {
      setEditing(false)
    }

    reset(
      props.formData?.basicForm || {
        grade: props.preferredPromptData?.grade || "",
        curriculum: props.preferredPromptData?.curriculum || "",
        subject: props.preferredPromptData?.subject || "",
        country: props.preferredPromptData?.country || "",
      }
    )
  }, [props.preferredPromptData])

  const [isEditing, setEditing] = useState(true)

  const { ensurePlusAccess } = useSuraasaPlusContext()

  const onSubmit = handleSubmit(async data => {
    if (!data.book) data.book = undefined
    if (!data.topic) data.topic = undefined
    if (!data.numberOfClasses) data.numberOfClasses = undefined

    ensurePlusAccess(async () => {
      await props.generateProTool.mutateAsync({ data, setError })
    })
  })

  const subject = watch("subject")
  const country = watch("country")
  const curriculum = watch("curriculum")
  const grade = watch("grade")

  return (
    <Paper>
      <form onSubmit={onSubmit} className="flex w-full flex-col gap-2">
        {!isEditing && (
          <CreatingFor
            country={country}
            curriculum={curriculum}
            grade={grade}
            subject={subject}
            onEditClick={() => setEditing(true)}
          />
        )}
        {(!grade || !curriculum || isEditing) && (
          <div className="flex flex-wrap gap-2 md:flex-nowrap">
            {(!grade || isEditing) && (
              <TextField
                label="Class/Grade"
                placeholder="Ex: 10th Grade"
                errors={errors.grade?.message}
                {...register("grade", {
                  required: { value: true, message: "Required" },
                })}
              />
            )}
            {(!curriculum || isEditing) && (
              <TextField
                {...register("curriculum", {
                  required: { value: true, message: "Required" },
                })}
                label="Curriculum"
                placeholder="Ex: IGCSE"
                errors={errors.curriculum?.message}
              />
            )}
          </div>
        )}
        {(!subject || !country || isEditing) && (
          <div className="flex flex-wrap gap-2 md:flex-nowrap">
            {(!subject || isEditing) && (
              <TextField
                label="Subject"
                placeholder="Ex: Biology"
                errors={errors.subject?.message}
                {...register("subject", {
                  required: { value: true, message: "Required" },
                })}
              />
            )}
            {(!country || isEditing) && (
              <TextField
                {...register("country", {
                  required: { value: true, message: "Required" },
                })}
                label="Country"
                placeholder="Ex: United States"
                errors={errors.country?.message}
                helperText="We use this to tailor the lesson plan to your region"
              />
            )}
          </div>
        )}
        <div className="flex flex-wrap gap-2 md:flex-nowrap">
          <TextField
            label="Chapter/Topic"
            placeholder="Ex: Cell Structure"
            errors={errors.topic?.message}
            {...register("topic", {
              required: { value: true, message: "Required" },
            })}
          />

          <TextField
            {...register("book")}
            label="Book Name"
            placeholder="Ex: Biology for Cambridge IGCSE"
            errors={errors.book?.message}
          />
        </div>
        <div className="flex flex-wrap gap-2 md:flex-nowrap">
          <Controller
            control={control}
            name="duration"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                label="Class Duration"
                errors={errors.duration?.message}
                options={DURATION_MINUTES}
                placeholder="60 minutes"
                value={DURATION_MINUTES.find(c => c.value === value)}
                mountOnBody
                onBlur={onBlur}
                onChange={val => onChange(val?.value)}
              />
            )}
            rules={{ required: { value: true, message: "Required" } }}
          />
          <div className="flex flex-[49%] shrink-0 flex-col">
            <Controller
              control={control}
              name="bloomTaxonomyLevel"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  label="Bloom's Taxonomy Level"
                  errors={errors.bloomTaxonomyLevel?.message}
                  options={BLOOMS_TAXONOMY_OPTIONS}
                  placeholder="Ex: Understand, Apply"
                  value={
                    value &&
                    BLOOMS_TAXONOMY_OPTIONS.filter(c => value.includes(c.value))
                  }
                  mountOnBody
                  onBlur={onBlur}
                  onChange={val => onChange(val.map(x => x.value))}
                />
              )}
              rules={{ required: { value: true, message: "Required" } }}
            />
            <Link
              to="https://www.suraasa.com/blog/learning-taxonomies"
              target="_blank"
            >
              <Typography
                className="mt-0.5 text-interactive-500 hover:underline"
                variant="smallBody"
              >
                What is Bloom&apos;s Taxonomy?
              </Typography>
            </Link>
          </div>
        </div>

        <TextField
          {...register("numberOfClasses")}
          label="Number of Lessons"
          placeholder="Ex: 4"
          errors={errors.numberOfClasses?.message}
        />

        <Button type="submit" loading={isSubmitting}>
          Generate Lesson Plan
        </Button>
      </form>
      {/* <AiToolsLoading type={toolType} open={isSubmitting} /> */}
    </Paper>
  )
}

export default ProForm
