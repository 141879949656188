import { Container } from "@suraasa/placebo-ui-legacy"
import { ToolType } from "api/resources/aiTools/types"
import ErrorPage404 from "components/ErrorPage404"
import { useFeatureToggle } from "global/FeatureToggleProvider"
import { useState } from "react"

import HistorySidebar from "../History/HistorySidebar"
import ActivityPlannerGenerator from "./ActivityPlannerGenerator"
import AssignmentGenerator from "./AssignmentGenerator"
import HandoutGenerator from "./HandoutGenerator"
import LessonGenerator from "./LessonPlanGenerator"
import NarrationGenerator from "./NarrationGenerator"
import QuizGenerator from "./QuizGenerator"
import RubricsGenerator from "./RubricsGenerator"
import SubjectiveAssessmentGenerator from "./SubjectiveAssessmentGenerator"
import UnitPlanGenerator from "./UnitPlanGenerator"
import VideoSummaryGenerator from "./VideoSummaryGenerator"

const Generator = ({ type }: { type: ToolType }) => {
  // const [searchParams] = useSearchParams()
  // const id = searchParams.get("id")
  // const [temporaryHistory, setTemporaryHistory] = useState<ListPromptHistory[]>(
  //   []
  // )

  const featureToggle = useFeatureToggle()

  const isAIToolsEnabled = featureToggle.aiTools.isEnabled

  // const [promptDataId, setPromptDataId] = useState<number | undefined | null>()

  // const [responseId, setResponseId] = useState<number | undefined>()
  // const [responses, setResponses] = useState<number[] | undefined>()

  const [openHistorySideBar, setOpenHistorySideBar] = useState(false)

  // const resetState = () => {
  //   setPromptDataId(null)
  //   setResponseId(undefined)
  //   setResponses(undefined)
  // }

  // const { data: overviewData, refetch: refetchOverviewData } = useQuery({
  //   queryKey: queries.aiTools.promptData(type, promptDataId).queryKey,
  //   queryFn: () => {
  //     // resetState()
  //     return api.aiTools.getPromptData({
  //       urlParams: {
  //         type: type,
  //         // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  //         id: promptDataId!,
  //       },
  //     })
  //   },
  //   enabled: false,
  //   // onSuccess: res => {
  //   //   if (res.responseIds && isNumberArray(res.responseIds)) {
  //   //     setResponses(res.responseIds)
  //   //     setResponseId(res.responseIds[0])
  //   //   }
  //   // },
  // })
  const toggleHistorySideBar = (state: boolean) => {
    setOpenHistorySideBar(state)
  }
  // const streamTitle = useStreamQuery({
  //   responseType: StreamResponseEnum.title,
  //   toolType: type,
  //   onSuccess: () => {
  //     refetchOverviewData()
  //     // setTimeout(refetchOverviewData, 1000)
  //   },
  // })

  // useEffect(() => {
  //   if (id) {
  //     setPromptDataId(parseInt(id))
  //     setResponses(undefined)
  //     setResponseId(undefined)
  //   }
  // }, [id])

  if (!isAIToolsEnabled) {
    return <ErrorPage404 />
  }

  return (
    // <AIToolsContext.Provider
    //   value={{
    //     promptDataId,
    //     setPromptDataId,
    //     responseIds: responses,
    //     setResponseIds: setResponses,
    //     currentResponseId: responseId,
    //     setCurrentResponseId: setResponseId,
    //     onResponseIdChange: id => {
    //       setResponseId(id)
    //     },
    //     openHistorySideBar,
    //     setOpenHistorySideBar,
    //     type,
    //     overviewData,
    //     resetState,
    //     refetchOverviewData() {
    //       refetchOverviewData()
    //     },
    //     streamTitle: {
    //       data: streamTitle.data,
    //       refetch: () => {
    //         streamTitle.refetchAsync({
    //           // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    //           currentResponseId: responseId!,
    //           responseType: StreamResponseEnum.title,
    //           toolType: type,
    //         })
    //       },
    //     },
    //   }}
    // >
    <div className="relative h-full min-h-screen pb-10">
      <div className="lg:hidden">
        <HistorySidebar
          slideIn={openHistorySideBar}
          onClose={() => toggleHistorySideBar(false)}
          type={type}
          isGenerator
          tempHistory={[]}
          openHistorySideBar={openHistorySideBar}
          toggleHistorySideBar={toggleHistorySideBar}
        />
      </div>
      {/* {JSON.stringify(
          {
            currentResponseId: responseId,
            responseIds: responses,
            promptDataId,
          },
          null,
          "\n"
        )} */}

      <div
        className="absolute left-0 z-10 hidden lg:block"
        style={{
          height: "100%",
          top: 0,
          overflow: "auto",
        }}
      >
        <HistorySidebar
          positioned
          isGenerator
          type={type}
          tempHistory={[]}
          openHistorySideBar={openHistorySideBar}
          toggleHistorySideBar={toggleHistorySideBar}
        />
      </div>

      <div className="flex">
        <div className="w-full">
          <Container className="lg:!pl-[calc(420px+32px)]">
            <>
              {type === ToolType.lessonPlan && (
                <LessonGenerator toggleHistorySideBar={toggleHistorySideBar} />
              )}
              {type === ToolType.rubrics && (
                <RubricsGenerator toggleHistorySideBar={toggleHistorySideBar} />
              )}
              {type === ToolType.quiz && (
                <QuizGenerator toggleHistorySideBar={toggleHistorySideBar} />
              )}
              {type === ToolType.handout && (
                <HandoutGenerator toggleHistorySideBar={toggleHistorySideBar} />
              )}
              {type === ToolType.narration && (
                <NarrationGenerator
                  toggleHistorySideBar={toggleHistorySideBar}
                />
              )}
              {type === ToolType.subjectiveAssessment && (
                <SubjectiveAssessmentGenerator
                  toggleHistorySideBar={toggleHistorySideBar}
                />
              )}
              {type === ToolType.assignment && (
                <AssignmentGenerator
                  toggleHistorySideBar={toggleHistorySideBar}
                />
              )}
              {type === ToolType.activityPlanner && (
                <ActivityPlannerGenerator
                  toggleHistorySideBar={toggleHistorySideBar}
                />
              )}
              {type === ToolType.videoSummary && (
                <VideoSummaryGenerator
                  toggleHistorySideBar={toggleHistorySideBar}
                />
              )}
              {type === ToolType.unitPlanner && (
                <UnitPlanGenerator
                  toggleHistorySideBar={toggleHistorySideBar}
                />
              )}
            </>
          </Container>
        </div>
      </div>
    </div>
    // </AIToolsContext.Provider>
  )
}

export default Generator
