import {
  Button,
  Select,
  Switch,
  TextArea,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import { Divider } from "@suraasa/placebo-ui-legacy"
import {
  DifficultyLevel,
  QuizProForm,
  ToolType,
} from "api/resources/aiTools/types"
import CreatingFor from "features/AItools/components/CreatingFor"
import Paper from "features/AItools/components/Paper"
import { getToolName } from "features/AItools/helper"
import { useSuraasaPlusContext } from "features/AItools/PRO/context/SuraasaPlusContext"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { pluralize } from "utils/helpers"

import { QuizReturnType } from "../useQuiz"

const LEVEL_OF_DIFFICULTY: { value: DifficultyLevel; label: string }[] = [
  { value: "easy", label: "Beginner" },
  { value: "medium", label: "Intermediate" },
  { value: "difficult", label: "Advanced" },
  { value: "expert", label: "Expert" },
]
const safeParseInt = (value?: string | number) => {
  if (typeof value === "number") return value
  return parseInt(value || "0")
}

const toolType = ToolType.quizPro
type AutoFillableFields = "grade" | "curriculum" | "subject" | "country"

type Form = QuizProForm

type Props = Pick<
  QuizReturnType,
  "generateProTool" | "formData" | "preferredPromptData"
>

const ProForm = ({ generateProTool, formData, preferredPromptData }: Props) => {
  const [enableComprehension, setEnableComprehension] = useState(false)
  const {
    register,
    control,
    handleSubmit,
    setError,
    watch,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Form>({
    defaultValues: formData?.proForm || {
      grade: preferredPromptData?.grade || "",
      curriculum: preferredPromptData?.curriculum || "",
      subject: preferredPromptData?.subject || "",
      country: preferredPromptData?.country || "",
    },
  })

  useEffect(() => {
    const prefillFields: AutoFillableFields[] = [
      "grade",
      "curriculum",
      "subject",
      "country",
    ]

    if (prefillFields.every(field => preferredPromptData?.[field])) {
      setEditing(false)
    }

    reset(
      formData?.basicForm || {
        grade: preferredPromptData?.grade || "",
        curriculum: preferredPromptData?.curriculum || "",
        subject: preferredPromptData?.subject || "",
        country: preferredPromptData?.country || "",
      }
    )
  }, [preferredPromptData])

  const numberOfComprehensions = safeParseInt(watch("numberOfComprehensions"))
  const numberOfComprehensionQuestions = safeParseInt(
    watch("numberOfComprehensionQuestions")
  )
  const numberOfIndividualQuestions = safeParseInt(
    watch("numberOfIndividualQuestions")
  )

  const totalComprehensionPassageQuestions =
    numberOfComprehensions * numberOfComprehensionQuestions

  const totalQuestions =
    numberOfIndividualQuestions + totalComprehensionPassageQuestions

  const { ensurePlusAccess } = useSuraasaPlusContext()

  const onSubmit = handleSubmit(async data => {
    let hasError = false
    if (enableComprehension) {
      if (!data.numberOfComprehensions) {
        setError("numberOfComprehensions", { message: "Required" })
        hasError = true
      }
      if (!data.numberOfComprehensionQuestions) {
        setError("numberOfComprehensionQuestions", { message: "Required" })
        hasError = true
      }
    }

    if (hasError) return

    if (!data.numberOfComprehensions) {
      delete data.numberOfComprehensions
    } else {
      data.numberOfComprehensions = safeParseInt(data.numberOfComprehensions)
    }

    if (!data.numberOfComprehensionQuestions) {
      delete data.numberOfComprehensionQuestions
    } else {
      data.numberOfComprehensionQuestions = safeParseInt(
        data.numberOfComprehensionQuestions
      )
    }

    data.numberOfIndividualQuestions = safeParseInt(
      data.numberOfIndividualQuestions
    )

    ensurePlusAccess(async () => {
      await generateProTool.mutateAsync({ data, setError })
    })
  })

  useEffect(() => {
    setValue("numberOfComprehensions", undefined)
    setValue("numberOfComprehensionQuestions", undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableComprehension])

  const [isEditing, setEditing] = useState(true)

  const grade = watch("grade")
  const curriculum = watch("curriculum")
  const subject = watch("subject")
  const country = watch("country")

  return (
    <Paper>
      <form onSubmit={onSubmit} className="flex flex-col gap-2">
        {!isEditing && (
          <CreatingFor
            country={country}
            curriculum={curriculum}
            grade={grade}
            subject={subject}
            onEditClick={() => setEditing(true)}
          />
        )}
        {(!grade || !curriculum || isEditing) && (
          <div className="flex flex-wrap gap-2 md:flex-nowrap">
            {(!grade || isEditing) && (
              <TextField
                {...register("grade", {
                  required: { value: true, message: "Required" },
                })}
                required
                label="Grade"
                placeholder="Ex: 11th Grade"
                errors={errors.grade?.message}
              />
            )}
            {(!curriculum || isEditing) && (
              <TextField
                {...register("curriculum", {
                  required: { value: true, message: "Required" },
                })}
                required
                label="Curriculum"
                placeholder="Ex: UAE National Curriculum"
                errors={errors.curriculum?.message}
              />
            )}
          </div>
        )}
        {(!subject || !country || isEditing) && (
          <div className="flex flex-wrap gap-2 md:flex-nowrap">
            {(!country || isEditing) && (
              <TextField
                {...register("country", {
                  required: { value: true, message: "Required" },
                })}
                required
                label="Country"
                placeholder="Ex: United Arab Emirates"
                errors={errors.country?.message}
              />
            )}
            {(!subject || isEditing) && (
              <TextField
                {...register("subject", {
                  required: { value: true, message: "Required" },
                })}
                required
                label="Subject"
                placeholder="Ex: Physics"
                errors={errors.subject?.message}
              />
            )}
          </div>
        )}
        <Controller
          control={control}
          name="difficultyLevel"
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              required
              label="Level of Difficulty"
              errors={errors.difficultyLevel?.message}
              options={LEVEL_OF_DIFFICULTY}
              placeholder="Ex: Expert"
              value={LEVEL_OF_DIFFICULTY.find(c => c.value === value)}
              mountOnBody
              onBlur={onBlur}
              onChange={val => onChange(val?.value)}
            />
          )}
          rules={{ required: { value: true, message: "Required" } }}
        />

        <TextArea
          rows={3}
          required
          {...register("topic", {
            required: { value: true, message: "Required" },
          })}
          label="Topic"
          placeholder="Ex: Circular Motion"
          errors={errors.topic?.message}
        />
        <Divider />

        <div className="mb-1 mt-0.5">
          <div className="flex items-center gap-1">
            <Typography variant="strong">Comprehension Questions</Typography>
            <Switch
              size="xs"
              checked={enableComprehension}
              onCheckedChange={checked => {
                setEnableComprehension(checked)
              }}
            />
          </div>
          <Typography className="mt-0.5 !text-onSurface-500">
            Add comprehension passages to your quiz.
          </Typography>
        </div>

        <TextField
          {...register("numberOfIndividualQuestions", {
            required: {
              value: true,
              message: "Required",
            },
          })}
          required
          label="Number of Individual Questions"
          inputMode="numeric"
          pattern="[0-9]*"
          type="number"
          placeholder="Ex: 10"
          errors={errors.numberOfIndividualQuestions?.message}
        />

        {enableComprehension && (
          <>
            <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
              <TextField
                {...register("numberOfComprehensions")}
                required
                label="No. of Comprehension Passages"
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
                placeholder="Ex: 2"
                errors={errors.numberOfComprehensions?.message}
              />
              <TextField
                {...register("numberOfComprehensionQuestions")}
                required
                label="Number of Questions (in each passage)"
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
                placeholder="Ex: 4"
                errors={errors.numberOfComprehensionQuestions?.message}
              />
            </div>
            {Boolean(
              numberOfIndividualQuestions &&
                numberOfComprehensions &&
                numberOfComprehensionQuestions
            ) && (
              <div className="rounded-lg border-2 border-highlight-100 bg-highlight-50 p-2">
                <Typography
                  variant="smallBody"
                  className="mb-0.25 !text-onSurface-500"
                >
                  Quiz Structure
                </Typography>
                <Typography variant="strong" className="!text-onSurface-700">
                  {`${pluralize("Total Question", totalQuestions)} (${pluralize(
                    "individual question",
                    numberOfIndividualQuestions
                  )}, ${pluralize(
                    "passage",
                    safeParseInt(numberOfComprehensions)
                  )} with ${pluralize(
                    "question",
                    safeParseInt(numberOfComprehensionQuestions)
                  )} each)`}
                </Typography>
              </div>
            )}
          </>
        )}
        <Button type="submit" loading={isSubmitting}>
          Generate {getToolName(toolType)}
        </Button>
      </form>
    </Paper>
  )
}

export default ProForm
