import {
  Certification,
  LanguageProficiency,
  Qualification,
  WorkExperienceType,
} from "../profile/types"

export enum ApplicationStatus {
  NOT_STARTED = "not_started",
  IN_PROGRESS = "in_progress",
  SUBMITTED = "submitted",
  UNDER_REVIEW = "under_review",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  ABANDONED = "abandoned",
  SUBMITTED_BY_COUNSELLOR = "submitted_by_counsellor",
}

export enum ReviewStatus {
  NOT_STARTED = "not_started",
  REVIEW_STARTED = "review_started",
  CHANGES_REQUESTED = "changes_requested",
  CHANGES_STARTED = "changes_started",
  CHANGES_DONE = "changes_done",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}

export enum InterviewStatus {
  SCHEDULED = "scheduled",
  NO_SHOW = "no_show",
  CANCELLED = "cancelled",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}

export type ApplicationFormOverview = {
  id: number
  productId: number
  productSlug: string
  centreId: number
  questionnaireSlug: string
  evidenceUploaded: boolean
  status: ApplicationStatus
  review: {
    id: number
    status: ReviewStatus
  } | null
  interview: {
    id: number
    status: InterviewStatus
    meetingUrl: string // Interview joining url
    scheduledDate: string | null
  } | null
  eligibleForInterview: boolean
}

export type Profiles = {
  languageProficiencies: LanguageProficiency[]
  qualifications: (Certification & {
    instituteName: string
    certificates: Evidence[]
  })[]
  certifications: (Qualification & {
    instituteName: string
    certificates: Evidence[]
  })[]
  workExperiences: WorkExperienceType[]
}

export enum AttachmentType {
  TRANSCRIPT = 1, // Also called MARKSHEET
  DEGREE,
}

export type Evidence = {
  id: number | undefined
  attachment: File | null
  attachmentName?: string | null
  attachmentType?: AttachmentType | null
  url?: string | null
}

export type QuestionnaireProduct = {
  id: number
  productId: number
  productSlug: string
  centreId: number
  questionnaire: number
}
