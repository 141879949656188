import { SocialAuthProvider } from "api/resources/settings/types"
import {
  AI_TOOLS_FIREBASE_KEY,
  getFeatureToggleConfig,
  SOCIAL_AUTH_FIREBASE_KEY,
} from "firebaseInit"

import { ValueOfEnum } from "./types"

const LOCAL_CACHE_KEY = "featureToggleManagerCache"

export type FeatureToggleType = {
  socialAuth: {
    isEnabled: boolean
    providers: ValueOfEnum<SocialAuthProvider>[]
  }
  aiTools: {
    inactiveTools?: string[]
    isEnabled: boolean
  }
}
export const featureToggleDefaultValues: FeatureToggleType = {
  socialAuth: {
    isEnabled: false,
    providers: [],
  },
  aiTools: {
    inactiveTools: [],
    isEnabled: true,
  },
}

export const FeatureToggleManager = {
  socialAuth: featureToggleDefaultValues.socialAuth,
  aiTools: featureToggleDefaultValues.aiTools,

  updateCache() {
    const data = JSON.stringify({
      socialAuth: this.socialAuth,
      aiTools: this.aiTools,
    })
    localStorage.setItem(LOCAL_CACHE_KEY, data)
  },
  getConfig() {
    return {
      socialAuth: this.socialAuth,
      aiTools: this.aiTools,
    }
  },

  init() {
    try {
      const data: {
        [x: string]: any
      } = JSON.parse(localStorage.getItem(LOCAL_CACHE_KEY) as string)

      Object.entries(data).forEach(([key, value]) => {
        if (["socialAuth", "aiTools"].includes(key)) {
          this[key] = value
        }
      })
      return this
    } catch (error) {
      return this
    }
  },
  async syncFromFirebase() {
    const data = await getFeatureToggleConfig()

    const socialAuthData = data[SOCIAL_AUTH_FIREBASE_KEY]
    const aiToolsData = data[AI_TOOLS_FIREBASE_KEY]

    if (socialAuthData) {
      this.socialAuth.isEnabled =
        socialAuthData.enabled.web && socialAuthData.providers.length > 0
      this.socialAuth.providers = socialAuthData.providers
    }

    if (aiToolsData) {
      this.aiTools.isEnabled = aiToolsData.enabled.web
      this.aiTools.inactiveTools = aiToolsData.inactiveTools
    }

    this.updateCache()
    return this
  },
}
