import {
  Button,
  Select,
  TextArea,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import { HandoutForm, ToolType } from "api/resources/aiTools/types"
import CreatingFor from "features/AItools/components/CreatingFor"
import Paper from "features/AItools/components/Paper"
import {
  BLOOMS_TAXONOMY_OPTIONS,
  DURATION_MINUTES,
  getToolName,
} from "features/AItools/helper"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Link } from "react-router-dom"

import { HandoutReturnType } from "../useHandout"
type AutoFillableFields = "grade" | "curriculum" | "subject" | "country"

const toolType = ToolType.handout

type Props = Pick<
  HandoutReturnType,
  "generateTool" | "formData" | "preferredPromptData"
>

const BasicForm = ({ generateTool, formData, preferredPromptData }: Props) => {
  const {
    control,
    register,
    setError,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<HandoutForm>({
    defaultValues: formData || {
      grade: preferredPromptData?.grade || "",
      curriculum: preferredPromptData?.curriculum || "",
      subject: preferredPromptData?.subject || "",
      country: preferredPromptData?.country || "",
    },
  })

  useEffect(() => {
    const prefillFields: AutoFillableFields[] = [
      "grade",
      "curriculum",
      "subject",
      "country",
    ]

    if (prefillFields.every(field => preferredPromptData?.[field])) {
      setEditing(false)
    }

    reset(
      formData || {
        grade: preferredPromptData?.grade || "",
        curriculum: preferredPromptData?.curriculum || "",
        subject: preferredPromptData?.subject || "",
        country: preferredPromptData?.country || "",
      }
    )
  }, [preferredPromptData])

  const onSubmit = handleSubmit(async data => {
    await generateTool.mutateAsync({ data, setError })
  })

  const [isEditing, setEditing] = useState(true)

  const subject = watch("subject")
  const country = watch("country")
  const grade = watch("grade")
  const curriculum = watch("curriculum")

  return (
    <Paper>
      <form onSubmit={onSubmit} className="flex w-full flex-col gap-2">
        {!isEditing && (
          <CreatingFor
            country={country}
            curriculum={curriculum}
            grade={grade}
            subject={subject}
            onEditClick={() => setEditing(true)}
          />
        )}
        {(!grade || !curriculum || isEditing) && (
          <div className="flex flex-wrap gap-2 md:flex-nowrap">
            {(!grade || isEditing) && (
              <TextField
                label="Class/Grade"
                placeholder="Ex: 8th Grade"
                errors={errors.grade?.message}
                {...register("grade", {
                  required: { value: true, message: "Required" },
                })}
              />
            )}
            {(!curriculum || isEditing) && (
              <TextField
                {...register("curriculum", {
                  required: { value: true, message: "Required" },
                })}
                label="Curriculum"
                placeholder="Ex: CBSE"
                errors={errors.curriculum?.message}
              />
            )}
          </div>
        )}
        {(!subject || !country || isEditing) && (
          <div className="flex flex-wrap gap-2 md:flex-nowrap">
            {(!subject || isEditing) && (
              <TextField
                label="Subject"
                placeholder="Ex: English"
                errors={errors.subject?.message}
                {...register("subject", {
                  required: { value: true, message: "Required" },
                })}
              />
            )}
            {(!country || isEditing) && (
              <TextField
                {...register("country", {
                  required: { value: true, message: "Required" },
                })}
                label="Country"
                placeholder="Ex: India"
                helperText={`We use this to tailor the ${getToolName(
                  toolType
                )} to your region`}
                errors={errors.country?.message}
              />
            )}
          </div>
        )}
        <TextField
          label="topic"
          placeholder="Ex: Direct and Indirect Speech"
          errors={errors.topic?.message}
          {...register("topic", {
            required: { value: true, message: "Required" },
          })}
        />
        <TextArea
          rows={4}
          label="Objective/Description"
          placeholder="Ex: Students should learn the difference between direct and indirect speech\nStudents should be able to convert direct speech to indirect speech"
          errors={errors.lessonPlanObjectives?.message}
          {...register("lessonPlanObjectives", {
            required: { value: true, message: "Required" },
          })}
        />
        <div className="flex flex-wrap gap-2 md:flex-nowrap">
          <Controller
            control={control}
            name="duration"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                label="Class Duration"
                errors={errors.duration?.message}
                options={DURATION_MINUTES}
                placeholder="Ex: 45 minutes"
                value={DURATION_MINUTES.find(c => c.value === value)}
                mountOnBody
                onBlur={onBlur}
                onChange={val => onChange(val?.value)}
              />
            )}
            rules={{ required: { value: true, message: "Required" } }}
          />

          <div className="flex flex-[50%] shrink-0 flex-col">
            <Controller
              control={control}
              name="bloomTaxonomyLevel"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  label="Bloom's Taxonomy Level"
                  errors={errors.bloomTaxonomyLevel?.message}
                  options={BLOOMS_TAXONOMY_OPTIONS}
                  placeholder="Ex: Remember, Understand, Apply"
                  value={
                    value &&
                    BLOOMS_TAXONOMY_OPTIONS.filter(c => value.includes(c.value))
                  }
                  mountOnBody
                  onBlur={onBlur}
                  onChange={val => onChange(val.map(x => x.value))}
                />
              )}
              rules={{ required: { value: true, message: "Required" } }}
            />
            <Link
              to="https://www.suraasa.com/blog/learning-taxonomies"
              target="_blank"
            >
              <Typography
                className="mt-0.5 text-interactive-500 hover:underline"
                variant="smallBody"
              >
                What is Bloom&apos;s Taxonomy?
              </Typography>
            </Link>
          </div>
        </div>
        <Button type="submit" loading={isSubmitting}>
          Generate {getToolName(toolType)}
        </Button>
      </form>
    </Paper>
  )
}

export default BasicForm
