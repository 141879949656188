import { Typography } from "@suraasa/placebo-ui"
import {
  Button,
  ButtonComponentProps,
  Divider,
  IconButton,
  TextField,
} from "@suraasa/placebo-ui-legacy"
import { ToolType } from "api/resources/aiTools/types"
import LikeSvg from "assets/AITools/LikeSvg"
import clsx from "clsx"
import ErrorMessage from "components/ErrorMessage"
import {
  Check,
  Copy,
  NavArrowLeft,
  NavArrowRight,
  Refresh,
} from "iconoir-react"
import { useEffect, useState } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import { Controller, useForm } from "react-hook-form"
import { trackingService } from "utils/tracking"

import GenerateButton from "./GenerateButton"

const Vote = {
  LIKED: true,
  DISLIKED: false,
  NEUTRAL: null,
}
enum RegenerationInstructionsEnum {
  MAKE_IT_SHORT = "make_it_short",
  MAKE_IT_LONG = "make_it_detailed",
  MAKE_IT_SIMPLE = "use_simpler_language",
  MAKE_THE_FORMAT_MORE_CASUAL = "make_it_more_casual",
  MAKE_THE_FORMAT_MORE_FORMAL = "make_it_more_professional",
  OTHERS = "others",
}

const regenerationInstructions = [
  { title: "Make It short", value: RegenerationInstructionsEnum.MAKE_IT_SHORT },
  {
    title: "Make it detailed",
    value: RegenerationInstructionsEnum.MAKE_IT_LONG,
  },
  {
    title: "Make it simple",
    value: RegenerationInstructionsEnum.MAKE_IT_SIMPLE,
  },
  {
    title: "Make the format more casual",
    value: RegenerationInstructionsEnum.MAKE_THE_FORMAT_MORE_CASUAL,
  },
  {
    title: "Make the format more formal",
    value: RegenerationInstructionsEnum.MAKE_THE_FORMAT_MORE_FORMAL,
  },
  { title: "Others", value: RegenerationInstructionsEnum.OTHERS },
]

export type RegenerateOutputProps = {
  responseIds: number[] | null
  currentResponseId: number | null
  onResponseIdChange: (responseId: number) => void
  onRegenerate: (reason: string) => void
  onUserReaction: (reaction: boolean | null | undefined) => void
  toolType: ToolType
  // onRegenerate?: {
  //   regenerateResponse?: (reason: string) => void
  //   isLoading?: boolean
  //   isError?: boolean
  //   isSuccess?: boolean
  //   onSuccess?: () => void
  //   onError?: () => void
  // }
}

const OutputToolbar = ({
  copyText,
  isPositiveResponse,
  currentResponseId,
  onResponseIdChange,
  responseIds,
  onRegenerate,
  onUserReaction,
  toolType,
}: RegenerateOutputProps & {
  copyText?: string
  isPositiveResponse?: boolean | null
}) => {
  let data = copyText
  const regexForToken =
    /\[\[__STOP__\]\]|\[\[__ERROR__\]\]|\[\[__BREAK__\]\]|\[\[__END__\]\]|\{'status':\s*'success'\}|\{'status':\s*'failed'\}/g
  const tokenMatch = data?.match(regexForToken)
  if (tokenMatch) {
    data = data?.replace(regexForToken, "")
  }

  const [userVote, setUserVote] = useState<boolean | null | undefined>()
  const [generateNew, setGenerateNew] = useState(false)

  const [copied, setCopied] = useState(false)

  const {
    reset,
    watch,
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<{
    instructions: RegenerationInstructionsEnum | null
    customInstruction: string
  }>({ defaultValues: { instructions: null, customInstruction: "" } })

  const instruction = watch("instructions")

  useEffect(() => {
    setUserVote(isPositiveResponse)
  }, [isPositiveResponse])

  const clearState = () => {
    reset({ instructions: null, customInstruction: "" })
    setGenerateNew(false)
  }
  const upVote = () => {
    onUserReaction(Vote.LIKED)
    setUserVote(Vote.LIKED)
    clearState()
  }
  const downVote = () => {
    onUserReaction(Vote.DISLIKED)
    setUserVote(Vote.DISLIKED)
  }
  function copyToClipboard() {
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }

  const onSubmit = handleSubmit(data => {
    let hasErrors = false
    if (
      data.instructions === RegenerationInstructionsEnum.OTHERS &&
      !data.customInstruction
    ) {
      hasErrors = true
      setError("customInstruction", { message: "Required" })
    }
    if (hasErrors) return

    const finalInstruction =
      data.instructions &&
      data.instructions !== RegenerationInstructionsEnum.OTHERS
        ? data.instructions
        : data.customInstruction

    onRegenerate(finalInstruction)
  })

  return (
    <>
      {/* <AiToolsLoading type={type} open={isLoading} /> */}

      <div className=" bg-surface-50 px-3 py-2">
        <div className="flex flex-col items-center gap-1 sm:flex-row ">
          {responseIds && responseIds.length > 0 && (
            <div className="flex items-center gap-0.5">
              <IconButton
                disabled={
                  currentResponseId !== null
                    ? responseIds.indexOf(currentResponseId) ===
                      responseIds.length - 1
                    : false
                }
                size="xs"
                color="black"
                onClick={() => {
                  if (onResponseIdChange && currentResponseId !== null) {
                    const currentIndex = responseIds.indexOf(currentResponseId)

                    if (currentIndex < responseIds.length - 1) {
                      setUserVote(isPositiveResponse)
                      onResponseIdChange(responseIds[currentIndex + 1])
                    }
                  }
                }}
              >
                <NavArrowLeft width={20} height={20} />
              </IconButton>

              <span>
                {currentResponseId !== null &&
                  responseIds.length - responseIds.indexOf(currentResponseId)}
                /{responseIds.length}
              </span>
              <IconButton
                size="xs"
                color="black"
                disabled={
                  currentResponseId !== null
                    ? responseIds[0] === currentResponseId
                    : false
                }
                onClick={() => {
                  if (onResponseIdChange && currentResponseId !== null) {
                    const currentIndex = responseIds.indexOf(currentResponseId)

                    if (currentIndex > 0) {
                      setUserVote(isPositiveResponse)
                      onResponseIdChange(responseIds[currentIndex - 1])
                    }
                  }
                }}
              >
                <NavArrowRight width={20} height={20} />
              </IconButton>
            </div>
          )}
          <span className="text-left text-xs font-normal leading-[14.52px]">
            How was this response?
          </span>
          <div className="flex items-center gap-1">
            <IconButton onClick={upVote}>
              <LikeSvg
                className={clsx({
                  "fill-onSurface-900": userVote === Vote.LIKED,
                })}
              />
            </IconButton>
            <IconButton onClick={downVote}>
              <LikeSvg
                className={clsx("-scale-100", {
                  "fill-onSurface-900": userVote === Vote.DISLIKED,
                })}
              />
            </IconButton>
            <IconButton
              color="black"
              onClick={() => {
                setGenerateNew(true)
              }}
            >
              <Refresh width={20} height={20} className="rotate-90" />
            </IconButton>
            {data && (
              <CopyToClipboard
                text={data || ""}
                onCopy={() => {
                  trackingService.trackEvent("ai_tool_output_copied", {
                    tool_type: toolType,
                  })

                  copyToClipboard()
                }}
              >
                <IconButton disabled={!data} color="black">
                  {copied ? (
                    <Check
                      width={20}
                      height={20}
                      className="text-success-600"
                    />
                  ) : (
                    <Copy width={20} height={20} className="rotate-90" />
                  )}
                </IconButton>
              </CopyToClipboard>
            )}
          </div>
        </div>
        {generateNew && (
          <form>
            <Divider className="my-1.5" weight="light" />
            <div className="flex flex-col">
              <Typography variant="smallBody" className="mb-1">
                Give additional instructions for regeneration
              </Typography>
              <Controller
                control={control}
                name="instructions"
                render={({ field: { value, onChange, onBlur } }) => (
                  <div className="flex flex-wrap items-center gap-1">
                    {regenerationInstructions.map(item => (
                      <ChipButton
                        key={item.value}
                        onBlur={onBlur}
                        isActive={value === item.value}
                        onClick={(e: { preventDefault: () => void }) => {
                          e.preventDefault()
                          onChange(item.value)
                        }}
                      >
                        {item.title}
                      </ChipButton>
                    ))}
                  </div>
                )}
                rules={{
                  required: {
                    value: true,
                    message: "Please select a valid option.",
                  },
                }}
              />
              {errors.instructions && (
                <ErrorMessage
                  className="mt-0.5"
                  title={errors.instructions.message}
                />
              )}

              {instruction === RegenerationInstructionsEnum.OTHERS && (
                <form>
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-ignore */}
                  <TextField
                    multiLine
                    fullWidth
                    className="mt-2"
                    inputLabelProps={{ required: true }}
                    error={Boolean(errors.customInstruction)}
                    helperText={errors.customInstruction?.message}
                    {...register("customInstruction")}
                    rows={3}
                    min={0}
                    max={10}
                  />
                </form>
              )}
              <GenerateButton
                // loading={Boolean(isLoading)}
                loading={false}
                label="Regenerate"
                className="mt-2 w-fit"
                onClick={onSubmit}
              />
            </div>
          </form>
        )}

        {!generateNew && userVote === Vote.DISLIKED && (
          <>
            <Divider className="my-1.5" weight="light" />
            <div>
              <Typography className="inline" variant="smallBody">
                Want to regenerate the result?
              </Typography>
              <Button
                variant="text"
                onClick={() => {
                  setGenerateNew(true)
                }}
                className="!outline-0"
              >
                Click here
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

const ChipButton = ({
  className,
  isActive,
  ...props
}: ButtonComponentProps<any> & { isActive?: boolean }) => (
  <button
    {...props}
    className={clsx(
      className,
      "rounded-full border border-solid  px-1.5 py-0.5 text-left text-sm font-medium leading-[18.2px] text-onSurface-800",
      {
        "bg-onSurface-800 border-onSurface-800 text-white": isActive,
        "bg-transparent border-onSurface-400 text-onSurface-800": !isActive,
      }
    )}
  />
)

export default OutputToolbar
