import { Typography } from "@suraasa/placebo-ui"
import { AIToolPrompts, UnitPlanTypeEnum } from "api/resources/aiTools/types"
import clsx from "clsx"
import {
  getBloomTaxonomyDisplay,
  rubricsTypeOptions,
} from "features/AItools/helper"
import {
  buildUserName,
  capitalizeFirstLetter,
  getAuthInfo,
  pluralize,
} from "utils/helpers"

import Paper from "../../../Paper"

const LEVEL_OF_DIFFICULTY = [
  { value: "easy", label: "Beginner" },
  { value: "medium", label: "Intermediate" },
  { value: "difficult", label: "Advanced" },
  { value: "expert", label: "Expert" },
]

const TitleAndSub = ({
  title,
  subTitle,
  className,
}: {
  title: string
  subTitle: string | number
  className?: string
}) => {
  return (
    <div className={className}>
      <Typography variant="smallBody" className="mb-0.5 text-onSurface-800">
        {capitalizeFirstLetter(title)}
      </Typography>
      <Typography variant="strong" className="capitalize text-onSurface-900">
        {subTitle}
      </Typography>
    </div>
  )
}

const convertToReadableDuration = (timeString?: string) => {
  if (!timeString) return undefined

  const [hours, minutes, seconds] = timeString.split(":").map(Number)

  let readableDuration = ""

  readableDuration += hours > 0 ? pluralize("hour", hours) + " " : ""
  readableDuration += minutes > 0 ? pluralize("minute", minutes) + " " : ""
  readableDuration += seconds > 0 ? pluralize("second", seconds) + " " : ""

  return readableDuration.trim()
}

const getUnitPlanTypeName = (unitPlanType?: UnitPlanTypeEnum) => {
  switch (unitPlanType) {
    case UnitPlanTypeEnum.academicYear:
      return "Academic Year"
    case UnitPlanTypeEnum.topic:
      return "Topic"
    default:
      return undefined
  }
}

const InputDisplay = ({
  className,
  data,
}: {
  data?: AIToolPrompts
  className?: string
}) => {
  if (!data) {
    return null
  }

  const authInfo = getAuthInfo()

  const taxonomies = data.bloomTaxonomyLevel || data.taxonomy

  return (
    <Paper className={clsx(className, "bg-white")}>
      {data.title && (
        <TitleAndSub
          className="mb-2"
          title="Title"
          subTitle={data.title.replaceAll("*", "")}
        />
      )}

      {data.topic && (
        <TitleAndSub className="mb-2" title="Topic" subTitle={data.topic} />
      )}
      {data.objective && (
        <TitleAndSub
          className="mb-2"
          title="Objective"
          subTitle={data.objective}
        />
      )}

      <div className="mb-2 flex max-w-[500px] flex-wrap items-center gap-4 xl:justify-start">
        {[
          {
            name: "Country",
            value: data.country,
          },
          {
            name: "Class/Grade",
            value: data.grade,
          },
          {
            name: "Curriculum",
            value: data.curriculum,
          },
          {
            name: "Subject",
            value: data.subject,
          },
          // {
          //   name: "Chapter/Topic",
          //   value: data.topic,
          // },
          {
            name: "Book",
            value: data.book,
          },
          {
            name: "Unit Plan Type",
            value: getUnitPlanTypeName(data.unitPlanType),
          },
          {
            name: "Rubric Type",
            value: rubricsTypeOptions.find(
              item => item.value === data.rubricType
            )?.label,
          },
          {
            name: "Performance Level",
            value: data.pointOfScale,
          },
          {
            name: "Criteria Level",
            value: data.criteriaLevel,
          },
          {
            name: "Rubric Point Scale",
            value: data.rubricScale,
          },
          {
            name: "Difficulty Level",
            value: LEVEL_OF_DIFFICULTY.find(
              item => item.value === data.difficultyLevel
            )?.label,
          },
          {
            name: "Number of Lessons",
            value: data.numberOfClasses,
          },
          {
            name: "Number of Questions",
            value: data.numberOfQuestions,
          },
          {
            name: "Number of Individual Questions",
            value: data.numberOfIndividualQuestions,
          },
          {
            name: "Number of Comprehension Passages",
            value: data.numberOfComprehensions,
          },
          {
            name: "Number of Questions (in each passage)",
            value: data.numberOfComprehensionQuestions,
          },
          {
            name: "Semester Duration",
            value:
              data.semesterDuration && Number(data.semesterDuration)
                ? pluralize("month", Number(data.semesterDuration))
                : data.semesterDuration,
          },
          {
            name: "Class Duration",
            value: convertToReadableDuration(data.classDuration),
          },
          {
            name: "Duration",
            value: convertToReadableDuration(data.duration),
          },
        ].map((item, index) =>
          item.value ? (
            <TitleAndSub key={index} title={item.name} subTitle={item.value} />
          ) : null
        )}
      </div>
      {taxonomies && (
        <TitleAndSub
          className="mb-2"
          title={pluralize("Bloom's Taxonomy Level", taxonomies.length, {
            skipCount: true,
          })}
          subTitle={getBloomTaxonomyDisplay(taxonomies)}
        />
      )}
      {authInfo && (
        <TitleAndSub
          title="Created By"
          subTitle={buildUserName(authInfo.user)}
        />
      )}
    </Paper>
  )
}

export { InputDisplay, TitleAndSub }
