import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { SETTINGS } from "api/resources/settings/types"
import { useEffect } from "react"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import { useResource } from "utils/hooks/useResource"

type Props = {
  isAuthenticated: boolean
}

export const useSetPlatformLanguage = ({ isAuthenticated }: Props) => {
  const preferences = useQuery({
    enabled: isAuthenticated,
    queryKey: queries.settings.preferencesList().queryKey,
    queryFn: () => api.settings.getUserPreferences(),
  })

  const { languages } = useResource(["languages"])

  useEffect(() => {
    if (preferences.isSuccess && languages.length > 0) {
      const userPreference = preferences.data.find(
        pref => pref.setting.slug === SETTINGS.preferredLanguage
      )?.value

      const localeCode = languages.find(
        x => x.uuid === userPreference
      )?.localeCode

      if (!localeCode) return

      const cache = localStorage.getItem(BROWSER_STORAGE_KEYS.language) || "en"

      if (!cache || cache !== localeCode) {
        localStorage.setItem(BROWSER_STORAGE_KEYS.language, localeCode)
        window.location.reload()
      }
    }
  }, [preferences, languages])
}
