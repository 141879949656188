import { CircularProgress } from "@suraasa/placebo-ui"
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import routes from "routes"

const ClaimSuraasaPlusPage = () => {
  const [searchParams] = useSearchParams()
  // const { isActive, showGetPlusSheet } = useSuraasaPlusContext()

  useEffect(() => {
    const redirectUrl = searchParams.get("redirectUrl")
    if (redirectUrl) {
      window.location.replace(redirectUrl)
    } else {
      window.location.replace(window.location.origin + routes.aiTools.home)
    }

    // if (isActive === false) {
    //   showGetPlusSheet()
    // }
  }, [])

  return (
    <div className="flex flex-col items-center justify-center p-5">
      {/* <Button onClick={() => showGetPlusSheet()}>
        Click here to claim Suraasa Plus
      </Button> */}

      <CircularProgress />
    </div>
  )
}

export default ClaimSuraasaPlusPage
