import { Button, TextArea, TextField } from "@suraasa/placebo-ui"
import { ActivityPlannerForm, ToolType } from "api/resources/aiTools/types"
import CreatingFor from "features/AItools/components/CreatingFor"
import Paper from "features/AItools/components/Paper"
import { getToolName } from "features/AItools/helper"
import { useSuraasaPlusContext } from "features/AItools/PRO/context/SuraasaPlusContext"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"

import { ActivityPlannerReturnType } from "../useActivityPlanner"

const toolType = ToolType.activityPlanner
type AutoFillableFields = "grade" | "curriculum" | "subject" | "country"

const ProForm = ({
  generateTool,
  preferredPromptData,
  setFormData,
  formData,
}: Pick<
  ActivityPlannerReturnType,
  "generateTool" | "formData" | "setFormData" | "preferredPromptData"
>) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    reset,
    watch,
  } = useForm<ActivityPlannerForm>({
    defaultValues: formData || {
      grade: preferredPromptData?.grade || "",
      curriculum: preferredPromptData?.curriculum || "",
      subject: preferredPromptData?.subject || "",
    },
  })

  const { isActive, showGetPlusSheet } = useSuraasaPlusContext()

  const onSubmit = handleSubmit(async data => {
    if (!isActive) {
      showGetPlusSheet()
      return
    }
    setFormData(data)
    await generateTool.mutateAsync({
      data: { ...data, duration: `00:${data.duration}:00` },
      setError,
    })
  })

  useEffect(() => {
    const prefillFields: AutoFillableFields[] = [
      "grade",
      "curriculum",
      "subject",
    ]

    if (prefillFields.every(field => preferredPromptData?.[field])) {
      setEditing(false)
    }

    reset(
      formData || {
        grade: preferredPromptData?.grade || "",
        curriculum: preferredPromptData?.curriculum || "",
        subject: preferredPromptData?.subject || "",
      }
    )
  }, [preferredPromptData])

  const [isEditing, setEditing] = useState(true)

  const subject = watch("subject")
  const grade = watch("grade")
  const curriculum = watch("curriculum")

  return (
    <Paper>
      <form onSubmit={onSubmit} className="flex w-full flex-col gap-2">
        {!isEditing && (
          <CreatingFor
            curriculum={curriculum}
            grade={grade}
            subject={subject}
            onEditClick={() => setEditing(true)}
          />
        )}
        {(!grade || !curriculum || isEditing) && (
          <div className="flex flex-wrap gap-2 md:flex-nowrap">
            {(!grade || isEditing) && (
              <TextField
                label="Class/Grade"
                required
                placeholder="Ex: 6th Grade"
                errors={errors.grade?.message}
                {...register("grade", {
                  required: { value: true, message: "Required" },
                })}
              />
            )}

            {(!curriculum || isEditing) && (
              <TextField
                {...register("curriculum", {
                  required: { value: true, message: "Required" },
                })}
                label="Curriculum"
                required
                placeholder="Ex: IB"
                errors={errors.curriculum?.message}
              />
            )}
          </div>
        )}
        <div className="flex flex-wrap gap-2 md:flex-nowrap">
          {(!subject || isEditing) && (
            <TextField
              label="Subject"
              required
              placeholder="Ex: Maths"
              errors={errors.subject?.message}
              {...register("subject", {
                required: { value: true, message: "Required" },
              })}
            />
          )}
          <TextField
            label="Duration (in minutes)"
            required
            placeholder="Ex: 30"
            errors={errors.duration?.message}
            {...register("duration", {
              required: {
                value: true,
                message: "Required",
              },
              pattern: {
                value: /^\d+$/,
                message: "Only numbers are allowed",
              },
            })}
          />
        </div>
        <TextArea
          rows={4}
          label="Objective/Description"
          required
          placeholder="Ex: Apply the Pythagorean Theorem to solve problems involving right-angled triangles, including finding missing side lengths, and demonstrate understanding through hands-on activities and real-world examples."
          errors={errors.topic?.message}
          {...register("topic", {
            required: { value: true, message: "Required" },
          })}
        />
        <Button type="submit" loading={isSubmitting}>
          Generate {getToolName(toolType)}
        </Button>
      </form>

      {/* <AiToolsLoading type={toolType} open={isLoading} /> */}
    </Paper>
  )
}

export default ProForm
