import {
  browserVersion,
  isChrome,
  isIOS,
  isIPad13,
  isIPhone13,
  isMacOs,
  isMobileSafari,
  isSafari,
  isSamsungBrowser,
} from "react-device-detect"

export enum Platforms {
  student = "Student",
  studentJobs = "Student Jobs",
  centerAdmin = "Centre Admin",
  professor = "Professor",
  serviceAdmin = "Service Admin",
  iqa = "Internal Quality Assessor",
  svp = "School Verification Portal",
  school = "School",
  apiDocs = "API Docs",
  olympiadPartner = "Olympiad Partner",
  applicationFormAdmin = "Application Form Admin",
}

export const USER_TYPE = Platforms.student
export const RTL_LANGUAGE_CODES = ["ar"]

export const DEFAULT_LANGUAGE_CODE = "en"

export const PAGE_SIZE = 10

export const BROWSER_STORAGE_KEYS = {
  auth: "suraasaAuth",
  language: "suraasaLanguage",
  suraasaMockOlympiadTourCompleted: "suraasaMockOlympiadTourCompleted",
  suraasaOnboardingCompleted: "suraasaOnboardingCompleted",
  pgctlApplicationFormBannerDismissed: "suraasaApplicationFormBannerDismissed",
  medApplicationFormBannerDismissed: "medApplicationFormBannerDismissed",
  tempDeviceId: "tempDeviceId",
  dismissITOinkReportsBanner: "dismissITOLinkReportsBanner",
  cameraPermissionsAllowedForITO: "itoCamPermissionAllowed",
  dismissedITOCongratulationsDialog: "dismissedITOCongratulationsDialog",
  reorderOnboarding: "suraasaSubjectsReorderOnboarding",
  hasSeenITOBadgeAnimation: "hasSeenITOBadgeAnimation",
  replyDiscussionSheetScrollPosition: "rdsScrollPosition",
  ryanSignupOnboardingCompleted: "ryanSignupOnboardingCompleted",
  courseExpiryWarning: "courseExpiryWarning",
  assignmentDueDateWarning: "assignmentDueDateWarning",
  assignmentExpiredWarning: "assignmentExpiredWarning",

  // Onboarding keys
  dashboardTourCompleted: "dashboardTourCompleted",
  profileTourCompleted: "profileTourCompleted",

  // SP Stands for Suraasa Plus
  hasClaimedTeacherMonthSP: "hasClaimedTeacherMonthSP",

  hasDismissedAIGeneratedSubmissionWarning:
    "hasDismissedAIGeneratedSubmissionWarning",

  // Synced with app
  suraasaAIToolsOnboardingCompleted: "suraasaAIToolsOnboardingCompleted",
  uploadResumeNudgeDismissedTime: "uploadResumeNudgeDismissedTime",
  videoLanguagePreference: "videoLanguagePreference",
  showVideoSubtitles: "showVideoSubtitles",
  // resumeFillerStage: "resumeFillerStage",

  // Session UTM Params
  sessionUtmParams: "sessionUtmParams",
} as const

export const PLATFORM_SETTINGS_SLUGS = {
  showChatbotV1: "sales-chatbot-v1",
} as const

export enum UpdateProfileEnum {
  PENDING = "pending",
  SUBJECTS = "subjects",
  LOCATIONS = "locations",
}

export enum SERVICE_PREFIX {
  ascella = "ascella",
  doppler = "doppler",
  mercury = "mercury",
  pollux = "pollux",
  castor = "castor",
  libra = "libra",
  gravity = "gravity",
  cygnus = "cygnus",
  hermes = "hermes",
  solis = "solis",
  helios = "helios",
  norma = "norma",
  perseus = "perseus",
  photon = "photon",
  sirius = "sirius",
  ophelia = "ophelia",
  polaris = "polaris",
  kepler = "kepler",
  phoenix = "phoenix",
  iris = "iris",
  axion = "axion",
  celestial = "celestial",
}

export const isRTL = RTL_LANGUAGE_CODES.includes(
  localStorage.getItem(BROWSER_STORAGE_KEYS.language) || ""
)

export const isAppleDevice =
  isIOS || isMacOs || isIPad13 || isIPhone13 || isSafari || isMobileSafari

const checkVersion = () => {
  const MIN_CHROME_VERSION = 100
  const MIN_SAFARI_VERSION = 16
  const MIN_SAMSUNG_BROWSER_VERSION = 19 // Based on Chromium 102. https://en.wikipedia.org/wiki/Samsung_Internet

  if (isSamsungBrowser) {
    const version = parseInt(browserVersion)
    return version >= MIN_SAMSUNG_BROWSER_VERSION
  }

  if (isChrome) {
    const version = parseInt(browserVersion)
    return version >= MIN_CHROME_VERSION
  }

  if (isSafari || isMobileSafari) {
    const version = parseInt(browserVersion)
    return version >= MIN_SAFARI_VERSION
  }
}

export const browserIsSupportedForITO = (() => {
  const vendorSupported =
    isChrome || isSafari || isMobileSafari || isSamsungBrowser
  const versionSupported = checkVersion()

  if (!vendorSupported) {
    return "unsupported_vendor"
  }

  if (!versionSupported) {
    return "unsupported_version"
  }

  return "supported"
})()

export const learningItemSlugs = {
  pgctl: "professional-graduate-certificate-in-teaching-learning",
  med: "masters-in-education",
}

export const applicationFormSlugs = {
  pgctl: "professional-graduate-certificate-in-teaching-learning",
  med: "masters-in-education",
} as const

export const importantSearchParams = {
  platformOverride: "po",
}

export const MAXIMUM_SCORE = {
  assignment: 3,
  assessment: 100,
}

export const isLocalhost = import.meta.env.DEV
