import { Tag } from "@suraasa/placebo-ui-legacy"
import {
  AssignmentList,
  SUBMISSION_STATUS,
  SubmissionResultEnum,
} from "api/resources/assignments/types"

type ActionConfig = {
  canAttempt: boolean
  isOverdue: boolean
  tag: JSX.Element
  isDraft: boolean | undefined
}

export const getActionConfig = (item: AssignmentList): ActionConfig => {
  const isDraft = item.submissionStatus === SUBMISSION_STATUS.savedForLater

  if (
    item.submissionRejectionReason?.reattemptRequestApproved &&
    item.submissionStatus === SUBMISSION_STATUS.rejected
  ) {
    const isOverdue = hasMissedDeadline(item.dueDate)

    if (isOverdue) {
      return {
        isDraft,
        canAttempt: true,
        ...getDueTag(item),
      }
    }

    return {
      isDraft,
      canAttempt: true,
      isOverdue: false,
      tag: <Tag size="sm" color="critical" label="Re-do" />,
    }
  }

  if (item.result === SubmissionResultEnum.PASSED) {
    return {
      isDraft,
      isOverdue: false,
      canAttempt: false,
      tag: <Tag size="sm" color="success" label="Accepted" />,
    }
  }
  if (item.result === SubmissionResultEnum.FAILED) {
    return {
      isDraft,
      isOverdue: false,
      canAttempt: false,
      tag: <Tag size="sm" color="critical" label="Rejected" />,
    }
  }

  switch (item.submissionStatus) {
    case SUBMISSION_STATUS.notReviewed:
    case SUBMISSION_STATUS.underReview:
      return {
        isDraft,
        canAttempt: false,
        isOverdue: false,
        tag: <Tag size="sm" color="onSurface" label="Under review" />,
      }
    case SUBMISSION_STATUS.plagiarised:
    case SUBMISSION_STATUS.rejected:
      return {
        isDraft,
        canAttempt: false,
        isOverdue: false,
        tag: <Tag size="sm" color="critical" label="Rejected" />,
      }
  }

  return {
    isDraft,
    canAttempt: true,
    ...getDueTag(item),
  }
}

const getDueTag = (
  item: AssignmentList
): Pick<ActionConfig, "isOverdue" | "tag"> => {
  if (item.dueDate === null)
    return {
      isOverdue: false,
      tag: <Tag size="sm" color="onSurface" label="Not allocated" />,
    }

  const isOverdue = hasMissedDeadline(item.dueDate)

  if (isOverdue)
    return {
      isOverdue,
      tag: <Tag size="sm" color="critical" label="Deadline Missed" />,
    }

  return { isOverdue, tag: <Tag size="sm" color="onSurface" label="Due" /> }
}

import { isAfter } from "date-fns"

export const hasMissedDeadline = (dueDate?: string | null) => {
  if (!dueDate) return false
  return isAfter(new Date(), new Date(dueDate))
}

type Params = { title: string; shortTitle: string }

export const buildAssignmentTitle = ({ title, shortTitle }: Params): string => {
  if (title === shortTitle) return title
  return `${shortTitle}: ${title}`
}
