import { Button, Select, TextArea, TextField } from "@suraasa/placebo-ui"
import { AssignmentForm, ToolType } from "api/resources/aiTools/types"
import CreatingFor from "features/AItools/components/CreatingFor"
import Paper from "features/AItools/components/Paper"
import { getToolName, pointScaleOptions } from "features/AItools/helper"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"

import { AssignmentReturnType } from "../useAssignment"

type AutoFillableFields = "grade" | "curriculum" | "subject"

const toolType = ToolType.assignment

type Props = Pick<
  AssignmentReturnType,
  "generateTool" | "formData" | "preferredPromptData"
>

const BasicForm = ({ generateTool, formData, preferredPromptData }: Props) => {
  const {
    register,
    control,
    handleSubmit,
    setError,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<AssignmentForm>({
    defaultValues: formData?.basicForm || {
      grade: preferredPromptData?.grade || "",
      curriculum: preferredPromptData?.curriculum || "",
      subject: preferredPromptData?.subject || "",
    },
  })

  useEffect(() => {
    const prefillFields: AutoFillableFields[] = [
      "grade",
      "curriculum",
      "subject",
    ]

    if (prefillFields.every(field => preferredPromptData?.[field])) {
      setEditing(false)
    }

    reset(
      formData?.basicForm || {
        grade: preferredPromptData?.grade || "",
        curriculum: preferredPromptData?.curriculum || "",
        subject: preferredPromptData?.subject || "",
      }
    )
  }, [preferredPromptData])

  const onSubmit = handleSubmit(async data => {
    await generateTool.mutateAsync({ data, setError })
  })

  const [isEditing, setEditing] = useState(true)

  const subject = watch("subject")
  const grade = watch("grade")
  const curriculum = watch("curriculum")

  return (
    <Paper>
      <form onSubmit={onSubmit} className="flex w-full flex-col gap-2">
        {!isEditing && (
          <CreatingFor
            curriculum={curriculum}
            grade={grade}
            subject={subject}
            onEditClick={() => setEditing(true)}
          />
        )}
        {(!grade || !curriculum || isEditing) && (
          <div className="flex flex-wrap gap-2 md:flex-nowrap">
            {(!grade || isEditing) && (
              <TextField
                required
                label="Class/Grade"
                placeholder="Ex: 10th Grade"
                errors={errors.grade?.message}
                {...register("grade", {
                  required: { value: true, message: "Required" },
                })}
              />
            )}

            {(!curriculum || isEditing) && (
              <TextField
                {...register("curriculum", {
                  required: { value: true, message: "Required" },
                })}
                required
                label="Curriculum"
                placeholder="Ex: CBSE"
                errors={errors.curriculum?.message}
              />
            )}
          </div>
        )}
        <div className="flex flex-wrap gap-2 md:flex-nowrap">
          {(!subject || isEditing) && (
            <TextField
              required
              label="Subject"
              placeholder="Ex: Economics"
              errors={errors.subject?.message}
              {...register("subject", {
                required: { value: true, message: "Required" },
              })}
            />
          )}
          <Controller
            control={control}
            name="rubricScale"
            render={({ field: { value, onChange, onBlur } }) => (
              <Select
                required
                errors={errors.rubricScale?.message}
                label="Rubric Point Scale"
                options={pointScaleOptions}
                placeholder="Ex: 5"
                mountOnBody
                onBlur={onBlur}
                value={pointScaleOptions.find(c => c.value === value)}
                onChange={val => onChange(val?.value)}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
        </div>
        <TextArea
          required
          rows={6}
          label="Objective/Description"
          placeholder={`Ex: Define resources and their contribution to economic development.\nRecognize the different types of resources.\nUnderstand the importance of resource planning and management.\nEvaluate the role of technology in sustainable resource management.\nExamine resource-related issues and challenges in contemporary India.`}
          errors={errors.assignmentObjectives?.message}
          {...register("assignmentObjectives", {
            required: { value: true, message: "Required" },
          })}
        />
        <Button type="submit" loading={isSubmitting}>
          Generate {getToolName(toolType)}
        </Button>
      </form>
    </Paper>
  )
}

export default BasicForm
