import { CircularProgress } from "@suraasa/placebo-ui"
import { ToolType } from "api/resources/aiTools/types"
import { getToolName } from "features/AItools/helper"
import { usePlusFeature } from "features/AItools/PRO/utils/usePlusFeature"
import { useEffect } from "react"

import Heading from "../../Heading"
import TogglePro from "../../TogglePro"
import ContentActions from "../GenerationOutput/Export/ContentActions"
import LessonPlanOutput from "../GenerationOutput/LessonPlanOutput"
import BasicForm from "./BasicForm"
import ProForm from "./ProForm"
import { useLessonPlanner } from "./useLessonPlanner"

const toolType = ToolType.lessonPlan

const LessonGenerator = ({
  toggleHistorySideBar,
}: {
  toggleHistorySideBar: (state: boolean) => void
}) => {
  const [enablePlusMode, setEnablePlusMode] = usePlusFeature({
    ensurePlusAccess: false,
  })

  const {
    promptDataId,
    currentResponseId,
    setCurrentResponseId,
    generateHandout,
    generateNarration,
    pageLoading,
    onBack,
    ...lessonContext
  } = useLessonPlanner()

  if (promptDataId && pageLoading) {
    return (
      <div className="grid place-items-center p-4">
        <CircularProgress />
      </div>
    )
  }

  if (promptDataId && currentResponseId) {
    return (
      <>
        {lessonContext.overviewData && promptDataId ? (
          <ContentActions
            onBack={onBack}
            currentResponseId={promptDataId}
            toolType={ToolType.lessonPlan}
            title={lessonContext.overviewData?.title}
            responseIds={lessonContext.overviewData?.responseIds?.map(item =>
              item.responseIds.includes(currentResponseId)
                ? currentResponseId
                : item.responseIds[0]
            )}
            toggleHistorySideBar={toggleHistorySideBar}
            hideExportOutputButton={
              lessonContext.stream.isError || lessonContext.streamPro.isError
            }
          />
        ) : (
          <div className="h-[84px]" />
        )}

        <LessonPlanOutput
          {...lessonContext}
          onBack={onBack}
          promptDataId={promptDataId}
          currentResponseId={currentResponseId}
          setCurrentResponseId={setCurrentResponseId}
          autoGenerateHandout={generateHandout}
          autoGenerateNarration={generateNarration}
          resetState={() => {
            lessonContext.setGenerateHandout(false)
            lessonContext.setGenerateNarration(false)
          }}
        />
      </>
    )
  }

  return (
    <>
      <Heading
        title={`${getToolName(toolType)} Generator`}
        subtitle="Craft comprehensive lesson plans effortlessly, using just a few keywords."
        toggleHistorySideBar={toggleHistorySideBar}
      />
      <TogglePro
        isPro={enablePlusMode}
        onChange={state => setEnablePlusMode(state)}
        className="mb-2"
      />
      {enablePlusMode ? (
        <ProForm {...lessonContext} />
      ) : (
        <BasicForm
          {...lessonContext}
          onGenerateHandout={() => {
            lessonContext.setGenerateHandout(true)
          }}
          onGenerateNarration={() => {
            lessonContext.setGenerateNarration(true)
          }}
        />
      )}
    </>
  )
}

export default LessonGenerator
