import * as SliderPrimitive from "@radix-ui/react-slider"
import { cn, Typography } from "@suraasa/placebo-ui"
import React, { useEffect, useState } from "react"

const timeToSeconds = (time?: string) => {
  if (!time) return 0
  const [hrs, mins, secs] = time.split(":").map(Number)
  return hrs * 3600 + mins * 60 + secs
}

const secondsToTime = (seconds: number) => {
  const hrs = Math.floor(seconds / 3600)
  const mins = Math.floor((seconds % 3600) / 60)
  const secs = seconds % 60
  return `${hrs.toString().padStart(2, "0")}:${mins
    .toString()
    .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`
}

const SliderRoot = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(
      "relative flex w-full touch-none select-none items-center",
      className
    )}
    {...props}
  />
))

SliderRoot.displayName = SliderPrimitive.Root.displayName

const TimeRangeSlider = ({
  minTime,
  maxTime,
  onChangeMin,
  onChangeMax,
  disabled,
}: {
  minTime: string
  maxTime?: string | null
  onChangeMin: (value: string) => void
  onChangeMax: (value: string) => void
  disabled?: boolean
}) => {
  const minValue = timeToSeconds(minTime)

  const [maxValue, setMaxValue] = useState(0)
  const isDisabled = disabled || !maxValue

  const [currentMinValue, setCurrentMinValue] = useState(minValue)
  const [currentMaxValue, setCurrentMaxValue] = useState(0)

  useEffect(() => {
    if (maxTime) {
      setCurrentMaxValue(timeToSeconds(maxTime))
      setMaxValue(timeToSeconds(maxTime))
      onChangeMin(minTime)
      onChangeMax(maxTime)
    } else {
      setCurrentMaxValue(0)
      setMaxValue(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxTime])

  const handleMinChange = (minValue: number) => {
    onChangeMin(secondsToTime(minValue))
    setCurrentMinValue(minValue)
  }

  const handleMaxChange = (maxValue: number) => {
    onChangeMax(secondsToTime(maxValue))
    setCurrentMaxValue(maxValue)
  }
  if (!maxValue || isDisabled)
    return (
      <div className="relative flex w-full items-center gap-0.5">
        <Typography variant="button" className="shrink-0">
          --:--:--
        </Typography>
        <div className="relative h-0.5 w-full grow rounded-full bg-surface-300">
          <div className=" absolute left-0 top-1/2 block size-2 -translate-y-1/2  rounded-full  border-2 border-surface-300 bg-white shadow-[1px_1px_5px_0px_#0000001A]" />
          <div className=" absolute right-0 top-1/2 block size-2 -translate-y-1/2  rounded-full  border-2 border-surface-300 bg-white shadow-[1px_1px_5px_0px_#0000001A]" />
        </div>
        <Typography variant="button" className="shrink-0">
          --:--:--
        </Typography>
      </div>
    )

  return (
    <div className="relative flex w-full items-center gap-0.5">
      <Typography variant="button" className="shrink-0">
        {secondsToTime(currentMinValue)}
      </Typography>

      <SliderRoot
        min={minValue}
        max={maxValue}
        defaultValue={[currentMinValue, maxValue]}
        onValueChange={value => {
          const [min, max] = value
          handleMinChange(min)
          handleMaxChange(max)
        }}
      >
        <SliderPrimitive.Track className="relative h-0.5 w-full grow overflow-hidden rounded-full bg-surface-300">
          <SliderPrimitive.Range className="absolute h-full bg-primary" />
        </SliderPrimitive.Track>

        <SliderPrimitive.Thumb className="peer block size-2 cursor-pointer rounded-full border-2 border-surface-300 bg-white shadow-[1px_1px_5px_0px_#0000001A]  transition-colors disabled:pointer-events-none disabled:opacity-50" />
        <SliderPrimitive.Thumb className="peer block size-2 cursor-pointer rounded-full border-2 border-surface-300 bg-white shadow-[1px_1px_5px_0px_#0000001A]  transition-colors disabled:pointer-events-none disabled:opacity-50" />
      </SliderRoot>
      <Typography variant="button" className="shrink-0">
        {secondsToTime(currentMaxValue)}
      </Typography>
    </div>
  )
}

export default TimeRangeSlider
