import { CircularProgress } from "@suraasa/placebo-ui"
import { context } from "global/Context/context"
import { useContext, useLayoutEffect } from "react"

export const Logout = () => {
  const { logout } = useContext(context)

  useLayoutEffect(() => {
    logout({ redirect: false })
    window.location.href = "/"
  }, [])

  return (
    <div className="grid h-screen w-screen place-items-center">
      <CircularProgress size="lg" />
    </div>
  )
}
