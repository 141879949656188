import { useCallback, useEffect, useRef, useState } from "react"

const useHasVisited = (props?: { disabled?: boolean }) => {
  const [hasVisited, setHasVisited] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement | null>(null)

  const checkDivVisiting = useCallback(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect()
      if (!hasVisited && rect.bottom <= 0) {
        setHasVisited(true)
      }
      if (hasVisited && rect.bottom > 0) {
        setHasVisited(false)
      }
    }
  }, [hasVisited])

  useEffect(() => {
    if (!props?.disabled) {
      window.addEventListener("scroll", checkDivVisiting)
    }
    return () => {
      if (!props?.disabled) {
        window.removeEventListener("scroll", checkDivVisiting)
      }
    }
  }, [checkDivVisiting, props?.disabled])

  return { hasVisited, ref }
}

export default useHasVisited
