import { ToolType } from "api/resources/aiTools/types"
import clsx from "clsx"
import Paper from "features/AItools/components/Paper"

import { ActivityPlannerReturnType } from "../../ActivityPlannerGenerator/useActivityPlanner"
import AIToolsError from "../../helpers/AIToolsError"
import { InputDisplay } from "../../helpers/InputDisplay"
import OutputToolbar from "../OutputToolbar"
import ToolOutput from "../ToolOutput"
import styles from "./ActivityPlanner.module.css"

const ActivityPlannerOutput = ({
  regenerateResponse,
  isPositiveResponse,
  userVote,
  overviewData,
  responseIds,
  currentResponseId,
  setCurrentResponseId,
  output,
  isLoading,
  onBack,
  stream,
}: Pick<
  ActivityPlannerReturnType,
  | "regenerateResponse"
  | "isPositiveResponse"
  | "userVote"
  | "overviewData"
  | "responseIds"
  | "currentResponseId"
  | "setCurrentResponseId"
  | "output"
  | "isLoading"
  | "onBack"
  | "stream"
>) => {
  return (
    <div className="mb-4 flex flex-col gap-2">
      {overviewData && !stream.isError && (
        <InputDisplay
          data={{
            curriculum: overviewData?.curriculum,
            duration: overviewData?.duration,
            grade: overviewData?.grade,
            subject: overviewData?.subject,
            topic: overviewData?.topic,
            title: overviewData?.title,
          }}
        />
      )}
      {stream.isError ? (
        <AIToolsError onClick={onBack} />
      ) : (
        <Paper
          className="p-0"
          endSlot={
            !isLoading ? (
              <OutputToolbar
                toolType={ToolType.activityPlanner}
                responseIds={responseIds}
                currentResponseId={currentResponseId}
                copyText={output}
                isPositiveResponse={isPositiveResponse}
                onResponseIdChange={responseId => {
                  setCurrentResponseId(responseId)
                }}
                onUserReaction={reaction => {
                  userVote.mutate({ reaction })
                }}
                onRegenerate={reason => {
                  regenerateResponse.mutate({ reason })
                }}
              />
            ) : undefined
          }
        >
          <div className={clsx("overflow-x-auto", styles.tableContainer)}>
            <ToolOutput
              isLoading={isLoading}
              className="px-3"
              onClick={() => {
                if (responseIds) {
                  setCurrentResponseId(
                    responseIds?.length > 1
                      ? responseIds[responseIds?.length - 1]
                      : responseIds[0]
                  )
                }
              }}
            >
              {output}
            </ToolOutput>
          </div>
        </Paper>
      )}
    </div>
  )
}

export default ActivityPlannerOutput
