import { ToolType } from "api/resources/aiTools/types"
import clsx from "clsx"
import Paper from "features/AItools/components/Paper"

import AIToolsError from "../../helpers/AIToolsError"
import { InputDisplay } from "../../helpers/InputDisplay"
import { UnitPlannerReturnType } from "../../UnitPlanGenerator/useUnitPlanner"
import OutputToolbar from "../OutputToolbar"
import ToolOutput from "../ToolOutput"

type Props = Pick<
  UnitPlannerReturnType,
  | "output"
  | "isLoading"
  | "isPositiveResponse"
  | "currentResponseId"
  | "setCurrentResponseId"
  | "regenerateResponse"
  | "overviewData"
  | "responseIds"
  | "userVote"
  | "onBack"
  | "stream"
>
const UnitPlanOutput = (props: Props) => {
  const {
    output,
    overviewData,
    responseIds,
    currentResponseId,
    isPositiveResponse,
    setCurrentResponseId,
    userVote,
    regenerateResponse,
    isLoading,
    onBack,
    stream,
  } = props
  // const { overviewData, refetchOverviewData } = useContext(AIToolsContext)

  // if (!overviewData)
  //   return (
  //     <div className="flex flex-col items-center justify-center p-5">
  //       <CircularProgress />
  //     </div>
  //   )

  // const { unitPlanResponses, unitDivisionResponses } = overviewData

  // const getContent = () => {
  //   if (!unitPlanResponses || !unitDivisionResponses)
  //     throw new Error(
  //       "unitPlanResponses and unitDivisionResponses should not be undefined"
  //     )

  //   const outputMode = getOutputMode({
  //     unitPlanResponses,
  //     unitDivisionResponses,
  //   })

  //   if (outputMode === "unitPlanTopic") {
  //     return <UnitPlanTopicOutput refetchPromptData={refetchOverviewData} />
  //   }

  //   if (outputMode === "unitPlanIntermediate") {
  //     return (
  //       <UnitPlanIntermediateOutput
  //         unitDivisionResponses={unitDivisionResponses}
  //         refetchPromptData={refetchOverviewData}
  //       />
  //     )
  //   }

  //   if (outputMode === "unitPlanDivision") {
  //     return (
  //       <UnitPlanDivisionOutput
  //         unitPlanResponses={unitPlanResponses}
  //         refetchPromptData={refetchOverviewData}
  //       />
  //     )
  //   }

  //   return (
  //     <div className="flex flex-col items-center justify-center p-5">
  //       <CircularProgress />
  //     </div>
  //   )
  // }

  return (
    <div className="mb-4 flex flex-col gap-2">
      {overviewData && !stream.isError && (
        <InputDisplay data={{ ...overviewData }} />
      )}
      {stream.isError ? (
        <AIToolsError onClick={onBack} />
      ) : (
        <Paper
          className="p-0"
          endSlot={
            isLoading ? undefined : (
              <OutputToolbar
                toolType={ToolType.unitPlanner}
                responseIds={responseIds}
                currentResponseId={currentResponseId}
                copyText={output}
                isPositiveResponse={isPositiveResponse}
                onResponseIdChange={responseId => {
                  setCurrentResponseId(responseId)
                }}
                onUserReaction={reaction => {
                  userVote.mutate({ reaction })
                }}
                onRegenerate={reason => {
                  regenerateResponse.mutate({ reason })
                }}
              />
            )
          }
        >
          <div className={clsx("overflow-x-auto")}>
            <ToolOutput
              isLoading={isLoading}
              onClick={() => {
                if (responseIds) {
                  setCurrentResponseId(
                    responseIds?.length > 1
                      ? responseIds[responseIds?.length - 1]
                      : responseIds[0]
                  )
                }
              }}
            >
              {output}
            </ToolOutput>
          </div>
        </Paper>
      )}

      {/* <UnitPlanTopicOutput /> */}
    </div>
  )
}

export default UnitPlanOutput
