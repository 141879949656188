import { Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import {
  ApplicationFormOverview,
  ApplicationStatus,
  InterviewStatus,
  ReviewStatus,
} from "api/resources/applicationForm/types"
import ITUShield from "assets/logos/itu-shield-crimson.png"
import clsx from "clsx"
import { useCallback, useState } from "react"
import routes from "routes"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import { formatDate, generateAuthCode, getPlatformURL } from "utils/helpers"
type Props = {
  data: ApplicationFormOverview
  refresh: () => void
}

type BannerConfig = {
  title: string
  subTitle?: string
  icon?: "success" | "critical"
  link?: string
  isUnderReview?: boolean
  buttonName?:
    | "Proceed to pay"
    | "Dismiss"
    | "Get Started"
    | "Complete Form"
    | "Schedule Interview"
    | "Join Interview"
    | "Schedule Interview"
    | "Update changes"
    | "Schedule Interview"
  theme: "primary" | "success" | "critical" | "warning"
}

const getBannerDetails = (
  data: ApplicationFormOverview
): BannerConfig | null => {
  const { interview, review, status, eligibleForInterview } = data

  switch (status) {
    case ApplicationStatus.ACCEPTED:
      // return {
      //   title: "Congrats! Your application has been accepted",
      //   subTitle:
      //     "Your application for M.Ed. has been accepted. Pay registration fee to start M.Ed..",
      //   icon: "success",
      //   theme: "success",
      //   buttonName: "Proceed to pay",
      // }
      return {
        title: "Congrats! Your M.Ed. application has been accepted",
        subTitle:
          "Our team has reviewed your application and we’d like to inform you that you are eligible for M.Ed. certification.",
        icon: "success",
        theme: "success",
        buttonName: "Dismiss",
      }

    case ApplicationStatus.REJECTED:
      return {
        title: "Your M.Ed. application has been rejected!",
        subTitle:
          "Our team has reviewed your application and we’d like to inform you that you are not eligible for M.Ed. right now. We encourage you to apply again in future",
        theme: "critical",
        buttonName: "Dismiss",
      }

    case ApplicationStatus.NOT_STARTED:
      return {
        title: "M.Ed. Application form is ready!",
        subTitle: "Now you can fill the form.",
        buttonName: "Get Started",
        theme: "success",
      }

    case ApplicationStatus.IN_PROGRESS:
      return {
        title: "Complete your M.Ed. application form",
        subTitle: "Please submit your remaining details",
        buttonName: "Complete Form",
        theme: "warning",
      }

    case ApplicationStatus.SUBMITTED:
      return {
        title: "Your M.Ed. Application is under review",
        subTitle: "You will be notified when it is reviewed.",
        theme: "primary",
        isUnderReview: true,
      }
  }

  if (
    eligibleForInterview &&
    (!interview || interview.status === InterviewStatus.CANCELLED)
  ) {
    return {
      title: "Schedule your M.Ed. interview!",
      subTitle:
        "Please pick a day and time of interview from the given calendar",

      theme: "primary",
      buttonName: "Schedule Interview",
      link: SCHEDULE_INTERVIEW_LINK,
    }
  }

  if (interview) {
    if (interview?.meetingUrl && interview.scheduledDate) {
      return {
        title: "Join your M.Ed. interview!",
        link: interview.meetingUrl,
        subTitle: `on ${formatDate(
          interview.scheduledDate,
          "dd MMM yyyy"
        )} at ${formatDate(interview.scheduledDate, "h:m aaa")}`,
        theme: "primary",
        buttonName: "Join Interview",
      }
    }
    switch (interview.status) {
      case InterviewStatus.NO_SHOW:
      case InterviewStatus.CANCELLED: {
        return {
          title: "Schedule your M.Ed. interview!",
          subTitle:
            "Please pick a day and time of interview from the given calendar",

          theme: "primary",
          buttonName: "Schedule Interview",
          link: SCHEDULE_INTERVIEW_LINK,
        }
      }
    }
  }

  if (review) {
    switch (review.status) {
      case ReviewStatus.CHANGES_STARTED:
      case ReviewStatus.CHANGES_REQUESTED:
        return {
          title: "Update requested changes on your M.Ed. Application form!",
          subTitle: "Changes requested by the Admissions Team",
          theme: "critical",
          buttonName: "Update changes",
        }

      case ReviewStatus.NOT_STARTED:
      case ReviewStatus.REVIEW_STARTED:
      case ReviewStatus.CHANGES_DONE: {
        return {
          title: "Your M.Ed. Application is under review",
          subTitle: "You will be notified when it is reviewed.",
          theme: "primary",
          isUnderReview: true,
        }
      }
    }
  }

  return null
}

const SCHEDULE_INTERVIEW_LINK = routes.scheduleInterview

const MEdApplicationFormStatusCard = ({ data, refresh }: Props) => {
  const { medApplicationFormBannerDismissed } = BROWSER_STORAGE_KEYS
  const hasAlreadyDismissed =
    localStorage.getItem(medApplicationFormBannerDismissed) === "true"

  const config = getBannerDetails(data)

  const [loadingBtn, setLoadingBtn] = useState(false)

  const [isNewTabOpen, setIsNewTabOpen] = useState(false)

  const [showBanner, setShowBanner] = useState(!hasAlreadyDismissed)

  const handleVisibilityChange = useCallback(() => {
    if (!document.hidden) {
      refresh()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clearListener = () => {
    window.document.removeEventListener(
      "visibilitychange",
      handleVisibilityChange
    )
  }

  const handleOnClick = async ({
    link,
    buttonName,
  }: Exclude<ReturnType<typeof getBannerDetails>, null>) => {
    if (isNewTabOpen) {
      window.alert("Application is already open in different tab")
      return
    }

    if (buttonName === "Dismiss") {
      localStorage.setItem(medApplicationFormBannerDismissed, "true")
      setShowBanner(false)
      return
    }

    if (link) {
      window.document.addEventListener(
        "visibilitychange",
        handleVisibilityChange
      )

      const scheduleWindow = window.open(link, "_blank")

      if (scheduleWindow) {
        const timer = setInterval(function () {
          if (scheduleWindow.closed) {
            clearInterval(timer)
            clearListener()
          }
        }, 1000)
      }
      return
    }

    setLoadingBtn(true)
    const redirectLink = "/application-form/masters-in-education"

    const newTab = window.open()
    if (newTab) {
      const code = await generateAuthCode()

      if (code) {
        newTab.location.href = getPlatformURL(
          "suraasa",
          code
            ? `${redirectLink}/?authCode=${code}&redirect=${getPlatformURL(
                "suraasa",
                redirectLink
              )}`
            : redirectLink
        )
      } else {
        newTab.location.href = getPlatformURL("suraasa", redirectLink)
      }
    }

    setIsNewTabOpen(true)
    setLoadingBtn(false)

    const timer = setInterval(checkNewTab, 500)
    function checkNewTab() {
      if (newTab && newTab.closed) {
        clearInterval(timer)
        setIsNewTabOpen(false)
        window.location.reload()
      }
    }
  }

  if (!config) return null
  if (!showBanner) return null

  return (
    <div className="mb-4">
      <div className={clsx("rounded-xl bg-surface-100 p-3")}>
        <div
          className={clsx(
            "flex flex-col items-stretch justify-between gap-2 sm:flex-row sm:items-center"
          )}
        >
          <div
            className={clsx({
              "flex items-center justify-between gap-2": config.isUnderReview,
            })}
          >
            <div>
              <img src={ITUShield} alt="" width={35} className="mb-1" />

              <Typography variant="title3" className="mb-0.5">
                {config.title}
              </Typography>
              <Typography variant="smallBody">{config.subTitle}</Typography>
            </div>
          </div>
          {config.buttonName && (
            <Button
              loading={loadingBtn}
              size="sm"
              color="black"
              className="!bg-[#820027] hover:bg-[#820027]"
              onClick={() => handleOnClick(config)}
            >
              {config.buttonName}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default MEdApplicationFormStatusCard
