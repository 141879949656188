import "drag-drop-touch"

import { theme } from "@suraasa/placebo-ui-legacy"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import AssignmentResult from "features/AssignmentResult"
import Authentication from "features/Authentication"
import LearningModule from "features/LearningModule"
import Profile from "features/Profile"
import GlobalContext from "global/Context"
import { PostHogProvider } from "posthog-js/react"
import { Suspense, useEffect } from "react"
import { ThemeProvider } from "react-jss"
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom"

import Home from "./features/Home"
import AppLayout from "./layout/AppLayout"
import { useDOMDirection } from "./utils/hooks/useDOMDirection"
const Schedule = safeLazyImport(() => import("features/Schedule"))
const Assignments = safeLazyImport(() => import("features/Assignments"))
const ITO = safeLazyImport(() => import("features/ITO/Reports"))

import * as Sentry from "@sentry/react"
import { PageWithMeta } from "components/Metadata"
import ScheduleInterview from "features/ApplicationForm/ApplicationFormStatusBanners/Pgctl/ScheduleInterview"
import AttemptAssessment from "features/Assessments/Attempt/"
import AssessmentResult from "features/Assessments/Result"
import DiscussionDetailPage from "features/Discussions/DiscussionDetailPage"
import CourseAssessments from "features/LearningItems/Course/Assessments"
import SentryErrorTesting from "features/Misc/SentryErrorTesting"
import MyCertificates from "features/MyCertificates"
import Orders from "features/Orders"
import OrderDetails from "features/Orders/OrderDetails"
import Payment from "features/Payment"
// import HassleFreePaymentPlan from "features/Orders/OrderDetails/Payments/HassleFreePlan"
import Settings from "features/Settings"
import AccountDetails from "features/Settings/AccountDetails"
import NotificationSettings from "features/Settings/NotificationSettings"
import Privacy from "features/Settings/Privacy"
import SettingsRouteElement from "features/Settings/SettingsRouteElement"
import SkillProfile from "features/SkillProfile"
import routes from "routes"
import { Platforms } from "utils/constants"
const ErrorPage404 = safeLazyImport(() => import("components/ErrorPage404"))

// import SlidingSheet from "components/SlidingSheet"
// import CaptchaTest from "components/CaptchaTest"
import { ToastProvider } from "@suraasa/placebo-ui"
import { ToolType } from "api/resources/aiTools/types"
import Redirect from "components/Redirect"
import AItools from "features/AItools"
import Generator from "features/AItools/components/Generator"
import UploadDocuments from "features/ApplicationForm/UploadDocuments"
import GlobalAuthObserver from "features/Authentication/GlobalAuthObserver"
import { Logout } from "features/Authentication/Logout"
// import DeviceInfo from "DeviceInfo"
import ITOAssessmentWrapper from "features/ITO/Assessments/Attempt/Wrapper"
import AssessmentInstructions from "features/ITO/Assessments/Instructions"
import Dashboard from "features/ITO/Dashboard"
import LearnerHandbook from "features/LearnerHandbook"
import GradeBook from "features/LearningItems/Qualification/Gradebook"
// import Leaderboard from "features/LearningItems/Qualification/Leaderboard"
import QualificationClasses from "features/LearningItems/Qualification/QualificationClasses"
import { AdditionalResources } from "features/LearningItems/Resources"
import MarkdownGenerator from "features/MarkdownTransform"
import PosthogTest from "features/Misc/PosthogTest"
import RetryPaymentPage from "features/Payment/RetryPaymentPage"
import ProfileAsDifferentPlatform from "features/Profile/ProfileAsDifferentPlatform"
// import ProctoringDemo from "features/Misc/ProctoringDemo"
import PublicProfile from "features/Profile/PublicProfile"
import TIA from "features/TIA"
import ClaimSuraasaPlusPage from "features/WorldTeachersMonth/ClaimSuraasaPlusPage"
import { safeLazyImport } from "safeImport"
import { getAuthInfo } from "utils/helpers"
import { webengage } from "utils/webengage"

import Discussions from "./features/Discussions"
import Course from "./features/LearningItems/Course"
import CourseAssignments from "./features/LearningItems/Course/Assignments"
import CourseClasses from "./features/LearningItems/Course/Classes"
import CourseOverview from "./features/LearningItems/Course/Overview"
import Qualification from "./features/LearningItems/Qualification"
import QualificationAssessments from "./features/LearningItems/Qualification/Assessments"
import QualificationCertificates from "./features/LearningItems/Qualification/Certificates"
import QualificationOverview from "./features/LearningItems/Qualification/QualificationOverview"
import Library from "./features/Library"
import Notifications from "./features/Notifications"

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
const WEBENGAGE_KEY = import.meta.env.VITE_WEBENGAGE_LICENSE_CODE

const IS_LOCAL = import.meta.env.MODE === "development"

if (SENTRY_DSN) {
  Sentry.init({
    ignoreErrors: [
      // Can't fix this
      "Failed to get document because the client is offline",
      "The play() request was interrupted by a call to pause()",
      "Error: The Google Maps JavaScript API could not load.",
    ],
    release: "suraasa-lms-web@" + APP_VERSION,
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.httpClientIntegration({
        failedRequestStatusCodes: [400, 403, 401, [500, 599]],
      }),
    ],
    sendDefaultPii: true,
  })

  const authInfo = getAuthInfo()
  if (authInfo)
    Sentry.setUser({ id: authInfo.user.uuid, email: authInfo.user.email })
}

const sentryCreateBrowserRouter = SENTRY_DSN
  ? Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  : createBrowserRouter

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <GlobalAuthObserver />,
    children: [
      {
        path: routes.profile.asCentre,
        element: (
          <PageWithMeta name="profile">
            <ProfileAsDifferentPlatform platform={Platforms.centerAdmin} />
          </PageWithMeta>
        ),
      },
      {
        path: routes.profile.asSchool,
        element: (
          <PageWithMeta name="profile">
            <ProfileAsDifferentPlatform platform={Platforms.school} />
          </PageWithMeta>
        ),
      },
      {
        path: "markdown-generator",
        element: <MarkdownGenerator />,
      },
      {
        path: "posthog-testing",
        element: <PosthogTest />,
      },
      {
        path: "/",
        element: <GlobalContext />,
        children: [
          {
            path: "/trigger-error",
            element: <SentryErrorTesting />,
          },
          {
            path: routes.logout,
            element: <Logout />,
          },
          {
            path: routes.profile.public,
            element: (
              <PageWithMeta name="profile">
                <PublicProfile />
              </PageWithMeta>
            ),
          },
          {
            path: "/",
            // Don't wrap metadata here
            element: <AppLayout />,
            children: [
              {
                path: routes.claimSuraasaPlus,
                element: <ClaimSuraasaPlusPage />,
              },
              {
                path: routes.skillProfile,
                element: <SkillProfile />,
              },
              // Authenticated Routes
              {
                path: "/",
                // Don't wrap metadata here
                element: <Authentication />,
                children: [
                  {
                    path: routes.learnerHandbook,
                    element: (
                      <PageWithMeta name="learnerHandbook">
                        <LearnerHandbook />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.tia,
                    element: (
                      <PageWithMeta name="tia">
                        <TIA />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: "/dashboard/tia",
                    element: <Redirect to={routes.tia} />,
                  },
                  {
                    path: "/tia",
                    element: <Redirect to={routes.tia} />,
                  },
                  {
                    path: routes.ito,
                    element: (
                      <PageWithMeta name="ito">
                        <Dashboard />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.home,
                    element: (
                      <PageWithMeta name="home">
                        <Home />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.applicationForm.uploadPgctlDocuments,
                    element: (
                      <PageWithMeta name="applicationFormUploadDocuments">
                        <UploadDocuments />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.scheduleInterview,
                    element: (
                      <PageWithMeta name="scheduleInterview">
                        <ScheduleInterview />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.learning,
                    element: (
                      <PageWithMeta name="learning">
                        <Library />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.attemptAssessment,
                    element: (
                      <PageWithMeta name="attemptAssessment">
                        <AttemptAssessment />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.instructionsITOAssessment,
                    element: (
                      <PageWithMeta name="itoInstructions">
                        <AssessmentInstructions parentSlug="ito" />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.attemptITOAssessment,
                    element: (
                      <PageWithMeta name="itoAssessment">
                        <ITOAssessmentWrapper />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.assessmentResult,
                    element: (
                      <PageWithMeta name="assessmentResult">
                        <AssessmentResult />
                      </PageWithMeta>
                    ),
                  },

                  {
                    path: routes.settings,
                    element: (
                      <PageWithMeta name="settings">
                        <Settings />
                      </PageWithMeta>
                    ),
                    children: [
                      {
                        element: <SettingsRouteElement />,
                        index: true,
                      },
                      {
                        path: routes.accountDetails,
                        element: <AccountDetails />,
                      },
                      {
                        path: routes.privacy,
                        element: <Privacy />,
                      },
                      {
                        path: routes.notificationSettings,
                        element: <NotificationSettings />,
                      },
                    ],
                  },
                  {
                    path: routes.learningModule,
                    element: (
                      <PageWithMeta name="learningModule">
                        <LearningModule />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.learningModuleWithParent,
                    element: (
                      <PageWithMeta name="learningModule">
                        <LearningModule />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.parentItem,
                    // Can't wrap meta here. Because it is dependent on page URL.
                    // It is handled inside
                    element: <Qualification />,
                    children: [
                      {
                        element: <Navigate to="overview" replace />,
                        index: true,
                      },
                      {
                        path: routes.parentItemOverview,
                        element: <QualificationOverview />,
                      },
                      {
                        path: routes.parentItemAssessments,
                        element: <QualificationAssessments />,
                      },
                      {
                        path: routes.parentItemCertificates,
                        element: <QualificationCertificates />,
                      },
                      {
                        path: routes.parentItemClasses,
                        element: <QualificationClasses />,
                      },
                      {
                        path: routes.parentItemResources,
                        element: <AdditionalResources qualification />,
                      },
                      {
                        path: routes.parentItemGradebook,
                        element: <GradeBook />,
                      },
                      // {
                      //   path: routes.parentItemLeaderboard,
                      //   element: <Leaderboard />,
                      // },
                    ],
                  },
                  {
                    path: routes.course,
                    element: (
                      <PageWithMeta name="course">
                        <Course />
                      </PageWithMeta>
                    ),
                    children: [
                      {
                        element: <Navigate to="overview" replace />,
                        index: true,
                      },
                      {
                        path: "/course/:slug/overview",
                        element: <CourseOverview />,
                      },
                      {
                        path: "/course/:slug/classes",
                        element: <CourseClasses />,
                      },
                      {
                        path: "/course/:slug/assignments",
                        element: (
                          <PageWithMeta name="assignments">
                            <CourseAssignments />
                          </PageWithMeta>
                        ),
                      },
                      {
                        path: "/course/:slug/assessments",
                        element: <CourseAssessments />,
                      },
                      {
                        path: "/course/:slug/resources",
                        element: <AdditionalResources course />,
                      },
                    ],
                  },
                  {
                    path: routes.parentItemCourse,
                    element: (
                      <PageWithMeta name="course">
                        <Course />
                      </PageWithMeta>
                    ),
                    children: [
                      {
                        element: <Navigate to="overview" replace />,
                        index: true,
                      },
                      {
                        path: `${routes.parentItemCourse}/overview`,
                        element: <CourseOverview />,
                      },
                      {
                        path: `${routes.parentItemCourse}/classes`,
                        element: <CourseClasses />,
                      },
                      {
                        path: `${routes.parentItemCourse}/assignments`,
                        element: <CourseAssignments />,
                      },
                      {
                        path: `${routes.parentItemCourse}/assessments`,
                        element: <CourseAssessments />,
                      },
                      {
                        path: `${routes.parentItemCourse}/resources`,
                        element: <AdditionalResources course />,
                      },
                    ],
                  },
                  {
                    path: routes.notifications,
                    element: (
                      <PageWithMeta name="notifications">
                        <Notifications />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: "/ito",
                    element: <Redirect to={routes.ito} />,
                  },
                  {
                    path: "/",
                    element: <Redirect to={routes.home} />,
                  },
                  {
                    path: "/ito-dashboard",
                    element: <Redirect to={routes.ito} />,
                  },
                  {
                    path: "/library",
                    element: <Redirect to={routes.learning} />,
                  },
                  {
                    path: routes.old.assignments,
                    element: <Redirect to={routes.learning} />,
                  },
                  {
                    path: routes.old.assessments,
                    element: <Redirect to={routes.learning} />,
                  },
                  {
                    path: routes.old.home,
                    element: <Redirect to={routes.home} />,
                  },
                  {
                    path: routes.old.ito,
                    element: <Redirect to={routes.ito} />,
                  },
                  {
                    path: routes.old.orders,
                    element: <Redirect to={routes.orders} />,
                  },
                  {
                    path: routes.old.payment,
                    element: <Redirect to={routes.payment} />,
                  },
                  {
                    path: routes.skillProfile,
                    element: <SkillProfile />,
                  },
                  {
                    path: routes.aiTools.home,
                    element: (
                      <PageWithMeta name="aiTools">
                        <AItools />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.aiTools.lessonPlan,
                    element: (
                      <PageWithMeta name="aiLessonPlanGenerator">
                        <Generator type={ToolType.lessonPlan} />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.aiTools.rubrics,
                    element: (
                      <PageWithMeta name="aiRubricsGenerator">
                        <Generator type={ToolType.rubrics} />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.aiTools.quiz,
                    element: (
                      <PageWithMeta name="aiQuizGenerator">
                        <Generator type={ToolType.quiz} />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.aiTools.handout,
                    element: (
                      <PageWithMeta name="aiHandoutGenerator">
                        <Generator type={ToolType.handout} />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.aiTools.narration,
                    element: (
                      <PageWithMeta name="aiNarrationGenerator">
                        <Generator type={ToolType.narration} />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.aiTools.subjectiveAssessment,
                    element: (
                      <PageWithMeta name="aiSubjectiveAssessmentGenerator">
                        <Generator type={ToolType.subjectiveAssessment} />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.aiTools.assignment,
                    element: (
                      <PageWithMeta name="aiAssignmentGenerator">
                        <Generator type={ToolType.assignment} />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.aiTools.activityPlanner,
                    element: (
                      <PageWithMeta name="aiActivityPlannerGenerator">
                        <Generator type={ToolType.activityPlanner} />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.aiTools.videoSummary,
                    element: (
                      <PageWithMeta name="aiVideoSummaryGenerator">
                        <Generator type={ToolType.videoSummary} />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.aiTools.unitPlanner,
                    element: (
                      <PageWithMeta name="aiUnitPlanGenerator">
                        <Generator type={ToolType.unitPlanner} />
                      </PageWithMeta>
                    ),
                  },
                  // Even though these routes are supposedly "public", They still require authentication.
                  // Business use case: You need to have an account on Suraasa to view someone's skill portfolio.
                  {
                    path: routes.skillProfile,
                    element: <SkillProfile />,
                  },
                  {
                    path: routes.profile.self,
                    element: (
                      <PageWithMeta name="profile">
                        <Profile />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.ito2022,
                    element: (
                      <Suspense
                        fallback={
                          <div className="flex justify-center p-5">
                            Loading...
                          </div>
                        }
                      >
                        <ITO />
                      </Suspense>
                    ),
                  },
                  {
                    path: routes.assignment,
                    element: (
                      <PageWithMeta name="viewAssignment">
                        <Suspense
                          fallback={
                            <div className="flex justify-center p-5">
                              Loading Skill Evidence (Assignment)...
                            </div>
                          }
                        >
                          <Assignments />
                        </Suspense>
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.submission,
                    element: (
                      <PageWithMeta name="submission">
                        <AssignmentResult />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.mySchedule,
                    element: (
                      <PageWithMeta name="schedule">
                        <Suspense
                          fallback={
                            <div className="flex justify-center p-5">
                              Loading Schedule...
                            </div>
                          }
                        >
                          <Schedule />
                        </Suspense>
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.discussions,
                    element: (
                      <PageWithMeta name="discussions">
                        <Discussions />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.certificates,
                    element: (
                      <PageWithMeta name="certificates">
                        <MyCertificates />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.orders,
                    element: (
                      <PageWithMeta name="orders">
                        <Orders />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.payment,
                    element: (
                      <PageWithMeta name="payment">
                        <Payment />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.retryPayment,
                    element: (
                      <PageWithMeta name="payment">
                        <RetryPaymentPage />
                      </PageWithMeta>
                    ),
                  },
                  {
                    path: routes.orderDetailsPage,
                    element: (
                      <PageWithMeta name="orderDetails">
                        <OrderDetails />
                      </PageWithMeta>
                    ),
                  },
                  // {
                  //   path: routes.orderPaymentPlan,
                  //   element: <HassleFreePaymentPlan />,
                  // },
                  {
                    path: "/discussions/:id",
                    element: (
                      <PageWithMeta name="viewDiscussion">
                        <DiscussionDetailPage />
                      </PageWithMeta>
                    ),
                  },
                ],
              },
            ],
          },
          // {
          //   path: "/misc/captcha",
          //   element: <CaptchaTest />,
          // },
          // {
          //   path: routes.misc.proctoringDemo,
          //   element: (
          //     <PageWithMeta name="proctoringDemo">
          //       <ProctoringDemo />
          //     </PageWithMeta>
          //   ),
          // },
          // {
          //   path: routes.misc.components,
          //   element: (
          //     <PageWithMeta name="components">
          //       <ComponentsTest />
          //     </PageWithMeta>
          //   ),
          // },
          {
            path: "/*",
            element: (
              <PageWithMeta name="pageNotFound">
                <Suspense
                  fallback={
                    <div className="flex justify-center p-5">Loading...</div>
                  }
                >
                  <ErrorPage404 />
                </Suspense>
              </PageWithMeta>
            ),
          },
        ],
      },
    ],
  },
])

const App = () => {
  const { direction } = useDOMDirection(<App />)

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        /**
         * We don't want failed requests to be retried by default
         * We can enable this on a per-API basis
         */
        retry: false,
      },
    },
  })

  useEffect(() => {
    if (WEBENGAGE_KEY) {
      webengage?.init(WEBENGAGE_KEY)
      webengage?.options("safariWebPush", true)
      if (IS_LOCAL) {
        webengage?.debug(true)
      }
    }

    if (WEBENGAGE_KEY && !webengage) {
      console.log("WebEngage was not available")
    }
  }, [])

  return (
    <PostHogProvider
      apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
      options={{
        api_host: "https://learn.dev.suraasa.com/ingest",
        autocapture: false,
        person_profiles: "identified_only",
        capture_pageview: false,
        capture_pageleave: false,
      }}
    >
      <ThemeProvider theme={{ ...theme, direction }}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
          <ToastProvider />

          {/* {import.meta.env.MODE === "development" && <ReactQueryDevtools />} */}
        </QueryClientProvider>
      </ThemeProvider>
    </PostHogProvider>
  )
}

export default App
