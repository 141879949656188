import { Typography } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import ResumeCourseCard from "features/Library/ResumeCourseCard"

const ResumeLearning = () => {
  const { data: lastAccessedItem, isError } = useQuery({
    queryFn: () => api.learningItems.getLastAccessedItem(),
  })

  if (isError || !lastAccessedItem) return null

  return (
    <div className="mb-4">
      <ResumeCourseCard
        item={lastAccessedItem}
        className="mt-2"
        header={<Typography variant="title3">Resume Learning</Typography>}
      />
    </div>
  )
}

export default ResumeLearning
