export default {
  login: "/login",
  logout: "/logout",

  old: {
    home: "/dashboard/library",
    assessments: "/dashboard/assessments",
    assignments: "/dashboard/assignments",
    payment: "/dashboard/orders/payment",
    orders: "/dashboard/orders",
    ito: "/dashboard/ito",
  },

  claimSuraasaPlus: "/claim-suraasa-plus",

  skillProfile: "/profile/skill-profile/:username?",

  profile: {
    self: "/profile",
    edit: "/profile/edit",
    public: "/profile/:username",

    asCentre: "/centre/profile/:username",
    asSchool: "/school/profile/:username",
  },

  ito2022: "/past-events/international-teachers-olympiad-2022",
  ito: "/international-teachers-olympiad",
  tia: "/teacher-impact-awards",

  notifications: "/notifications",

  learnerHandbook: "/suraasa-learner-handbook",

  home: "/home",
  learning: "/learning",
  certificates: "/certificates",
  mySchedule: "/my-schedule",

  discussions: "/discussions",

  applicationForm: {
    uploadPgctlDocuments: "/application-form/pgctl/upload-documents",
  },

  // Learning Module Routes
  learningModule: "/learning/course/:slug",
  learningModuleWithParent:
    "/learning/:learningItemType/:parentSlug/course/:slug",

  // Course Routes
  course: "/course/:slug",
  scheduleInterview: "/application-form/schedule-interview",

  // Qualification Routes
  parentItem: "/:learningItemType/:slug",
  parentItemCourse: "/:learningItemType/:parentSlug/course/:slug",
  parentItemOverview: "/:learningItemType/:slug/overview",
  parentItemAssessments: "/:learningItemType/:slug/assessments",
  parentItemCertificates: "/:learningItemType/:slug/certificates",
  parentItemResources: "/:learningItemType/:slug/resources",
  parentItemClasses: "/:learningItemType/:slug/classes",

  parentItemGradebook: "/:learningItemType/:slug/gradebook",
  parentItemLeaderboard: "/:learningItemType/:slug/leaderboard",

  // Assessment Routes
  attemptAssessment: "/assessments/attempt/:assessmentId",
  assessmentResult: "/assessments/result/:attemptId",
  instructionsITOAssessment:
    "/ito/assessments/instructions/:assessmentId/:attemptId?",
  attemptITOAssessment: "/ito/assessments/attempt/:attemptId",

  // Assignment Routes
  assignment: "/:learningItemType/:slug/assignments/:assignmentId",
  submission:
    "/:learningItemType/:slug/assignments/:assignmentId/:submissionId",

  // Orders Routes
  orders: "/orders",
  orderDetailsPage: "/orders/:id",
  orderPaymentPlan: "/orders/:id/payment-plan",
  payment: "/orders/payment",
  retryPayment: "/orders/payment/retry",

  // Settings Routes
  settings: "/settings",
  privacy: "/settings/privacy",
  accountDetails: "/settings/account-details",
  notificationSettings: "/settings/notifications",

  misc: {
    components: "/misc/components",
    proctoringDemo: "/testing/proctoring/",
    browserInfo: "/browser-info",
  },

  aiTools: {
    home: "/ai-tools",
    lessonPlan: "/ai-tools/lesson-plan/",
    rubrics: "/ai-tools/rubrics/",
    quiz: "/ai-tools/quiz/",
    handout: "/ai-tools/handout/",
    narration: "/ai-tools/narration/",
    subjectiveAssessment: "/ai-tools/subjective-assessment/",
    assignment: "/ai-tools/assignment/",
    activityPlanner: "/ai-tools/activity-planner/",
    videoSummary: "/ai-tools/video-summary/",
    unitPlanner: "/ai-tools/unit-planner/",
  },
}
