import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import MainImage from "assets/Onboarding/profile/main.webp"
import { Xmark } from "iconoir-react"

const GetStarted = ({
  open,
  handleContinue,
  handleClose,
}: {
  open: boolean
  handleContinue: () => void
  handleClose?: () => void
}) => {
  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="rounded-2xl">
        {handleClose && (
          <DialogClose className="absolute right-2 top-2">
            <IconButton color="black">
              <Xmark />
            </IconButton>
          </DialogClose>
        )}
        <div className="flex flex-col">
          <div className="h-[213px] w-full sm:h-[274px]">
            <img
              src={MainImage}
              alt="main-img"
              className="size-full rounded-2xl object-cover"
            />
          </div>
          <div className="p-3">
            <Typography variant="title2" className="mb-1.5">
              Take a tour of your profile
            </Typography>
            <Typography variant="largeBody" className="mb-5 text-onSurface-500">
              A complete and updated profile not only reflects who you are but
              also helps you stand out from the competition.
            </Typography>
            <Button onClick={handleContinue} className="ml-auto flex">
              Show Me Around
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default GetStarted
