import { Typography } from "@suraasa/placebo-ui"
import { IconButton, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { ListPromptHistory, ToolType } from "api/resources/aiTools/types"
import clsx from "clsx"
import SlidingSheet from "components/SlidingSheet"
import { Xmark } from "iconoir-react"
import { useTheme } from "react-jss"

import TabContent from "../TabContent"

const HistorySidebar = ({
  slideIn = false,
  positioned,
  onClose,
  type,
  tempHistory,
  isGenerator,
  openHistorySideBar,
  toggleHistorySideBar,
}: React.PropsWithChildren<{
  slideIn?: boolean
  positioned?: boolean
  onClose?: () => void
  type: ToolType
  tempHistory: ListPromptHistory[]
  isGenerator?: boolean
  openHistorySideBar: boolean
  toggleHistorySideBar: (state: boolean) => void
}>) => {
  const theme = useTheme<Theme>()
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <>
      <div className="h-full">
        <SlidingSheet
          open={slideIn}
          fullScreen={isSmDown}
          onClose={() => onClose && onClose()}
          from="end"
        >
          <div
            className={clsx(
              "h-full overflow-scroll border border-surface-200 bg-common-white-500 md:w-[416px]"
            )}
          >
            <div className="flex items-center justify-between px-2 py-[18.5px] sm:px-3">
              <div className="flex items-center">
                <IconButton
                  color="black"
                  onClick={() => {
                    onClose && onClose()
                  }}
                  className="me-1"
                >
                  <Xmark />
                </IconButton>
                <Typography variant="title2">History</Typography>
              </div>
            </div>
            <div className="mx-2">
              <TabContent
                type={type}
                tempHistory={tempHistory}
                useReplaceWhileNavigating
                isGenerator={isGenerator}
                openHistorySideBar={openHistorySideBar}
                toggleHistorySideBar={toggleHistorySideBar}
              />
            </div>
          </div>
        </SlidingSheet>

        {positioned && (
          <div
            className={clsx(
              "h-full w-[420px] overflow-auto border border-surface-200 bg-common-white-500"
            )}
          >
            <div className="flex items-center justify-between border-b px-3 py-[18.5px]">
              <Typography variant="title3">History</Typography>
            </div>

            <div className="mx-2">
              <TabContent
                type={type}
                tempHistory={tempHistory}
                useReplaceWhileNavigating
                isGenerator={isGenerator}
                openHistorySideBar={openHistorySideBar}
                toggleHistorySideBar={toggleHistorySideBar}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default HistorySidebar
