import { Typography } from "@suraasa/placebo-ui"
import AiToolsImg from "assets/Onboarding/home/aiTools.webp"
import JobsImg from "assets/Onboarding/home/jobs.webp"
import ProfileImg from "assets/Onboarding/home/profile.webp"
import { useEffect, useState } from "react"
import Joyride, { Step } from "react-joyride"
import { BROWSER_STORAGE_KEYS, isLocalhost } from "utils/constants"
import { getAuthInfo, sleep } from "utils/helpers"
import { UserPreferenceManager } from "utils/userPreferenceManager"

import { HOME_ONBOARDING_TARGETS } from "./constants"
import GetStarted from "./GetStarted"
import OnboardingStep from "./OnboardingStep"

const { dashboardTourCompleted } = BROWSER_STORAGE_KEYS

const HomeOnboarding = () => {
  const isCompleted =
    UserPreferenceManager.get(dashboardTourCompleted) === "true"

  const [step, setStep] = useState<"intro" | "joyride" | null>(null)

  useEffect(() => {
    const auth = getAuthInfo()
    if (!auth || auth.shadow) return

    if (isCompleted) {
      setStep(null)
      return
    }

    ;(async () => {
      // Start the onboarding after a delay
      await sleep(1500)
      setStep("intro")
    })()
  }, [isCompleted])

  const onComplete = () => {
    setStep(null)
    UserPreferenceManager.set(dashboardTourCompleted, "true")
  }

  const steps: Step[] = [
    {
      target: `.${HOME_ONBOARDING_TARGETS.aiTools}`,
      content: (
        <div>
          <div className="mb-2.5 px-1 pt-1">
            <div className="h-14 bg-white">
              <img
                src={AiToolsImg}
                className="size-full rounded-t-xl object-cover"
                alt="ai-tools-img"
              />
            </div>
          </div>
          <div className="px-2.5">
            <Typography variant="title3" className="mb-1 text-start">
              Make your Teaching Life Easier with our AI Tools
            </Typography>
            <Typography
              variant="smallBody"
              className="mb-3 text-start text-onSurface-600"
            >
              Generate lesson plans, assignments, assessments, and more in just
              a few seconds.
            </Typography>
          </div>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: `.${HOME_ONBOARDING_TARGETS.jobs}`,
      content: (
        <div>
          <div className="mb-2.5 px-1 pt-1">
            <div className="h-14 bg-white">
              <img
                src={JobsImg}
                className="size-full rounded-t-xl object-cover"
                alt="jobs-img"
              />
            </div>
          </div>
          <div className="px-2.5">
            <Typography variant="title3" className="mb-1 text-start">
              Explore Global Teaching Opportunities
            </Typography>
            <Typography
              variant="smallBody"
              className="mb-3 text-start text-onSurface-600"
            >
              Explore teaching positions, apply directly, and let recruiters
              find you based on your profile.
            </Typography>
          </div>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: `.${HOME_ONBOARDING_TARGETS.profile}`,
      content: (
        <div>
          <div className="mb-2.5 px-1 pt-1">
            <div className="h-14 bg-white">
              <img
                src={ProfileImg}
                className="size-full rounded-t-xl object-cover"
                alt="profile-img‚"
              />
            </div>
          </div>
          <div className="px-2.5">
            <Typography variant="title3" className="mb-1 text-start">
              Build a Profile that Stands Out
            </Typography>
            <Typography
              variant="smallBody"
              className="mb-3 text-start text-onSurface-600"
            >
              Your profile on Suraasa is your professional identity. It
              showcases your qualifications, teaching experience, skills, and
              achievements.
            </Typography>
          </div>
        </div>
      ),
      disableBeacon: true,
    },
  ]

  const handleJoyrideCallback = data => {
    const { status, lifecycle } = data

    const tourCompletedStatuses = ["finished"]
    if (tourCompletedStatuses.includes(status) && lifecycle === "complete") {
      onComplete()
    }
  }

  return (
    <div>
      <GetStarted
        open={step === "intro"}
        handleContinue={() => {
          setStep("joyride")
        }}
        handleClose={isLocalhost ? () => setStep(null) : undefined}
      />
      <Joyride
        steps={steps}
        run={step === "joyride"}
        tooltipComponent={props => (
          <OnboardingStep toolTipRenderProps={props} onComplete={onComplete} />
        )}
        continuous
        disableOverlayClose
        disableScrolling
        disableCloseOnEsc
        hideBackButton
        hideCloseButton
        callback={handleJoyrideCallback}
      />
    </div>
  )
}

export default HomeOnboarding
