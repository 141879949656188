import { ToolType } from "api/resources/aiTools/types"
import useTabs from "components/TabBar/hooks/useTabs"
import Paper from "features/AItools/components/Paper"
import { getToolName } from "features/AItools/helper"
import { useEffect, useMemo, useState } from "react"

import AIToolsError from "../../../helpers/AIToolsError"
import { InputDisplay } from "../../../helpers/InputDisplay"
import { useHandoutWithLessonPlanner } from "../../../LessonPlanGenerator/useHandoutWithLessonPlanner"
import { LessonPlannerReturnType } from "../../../LessonPlanGenerator/useLessonPlanner"
import { useNarrationWithLessonPlanner } from "../../../LessonPlanGenerator/useNarrationWithLessonPlanner"
import TemplateHandoutsList from "../../HandoutOutput/TemplateHandoutsList"
import TemplateNarrationsList from "../../NarrationOutput/TemplateNarrationsList"
import OutputToolbar from "../../OutputToolbar"
import ToolOutput from "../../ToolOutput"
import LessonPlanTabs from "../LessonPlanTabs"
import { generateUuidV4 } from "../ProOutput/Content/helper"
import Content from "./Content"

const initialTabs = [
  {
    name: getToolName(ToolType.lessonPlan),
    content: Content,
  },
  {
    name: getToolName(ToolType.handout),
    content: () => <div />,
  },
  {
    name: getToolName(ToolType.narration),
    content: () => <div />,
  },
]
export type BasicOutputProps = Pick<
  LessonPlannerReturnType,
  | "currentStreamValues"
  | "isLoading"
  | "isPositiveResponse"
  | "setCurrentResponseId"
  | "userVote"
  | "regenerateResponse"
  | "responseIds"
  | "currentResponseId"
  | "overviewData"
  | "finalizedOutputs"
  | "refetchOverview"
  | "promptDataId"
  | "onBack"
  | "stream"
>
const BasicOutput = ({
  autoGenerateHandout = false,
  autoGenerateNarration = false,
  resetState,
  currentStreamValues,
  isLoading,
  isPositiveResponse,
  setCurrentResponseId,
  userVote,
  regenerateResponse,
  responseIds,
  currentResponseId,
  overviewData,
  finalizedOutputs,
  refetchOverview,
  promptDataId,
  onBack: toolOnBack,
  stream,
}: {
  autoGenerateHandout?: boolean
  autoGenerateNarration?: boolean
  resetState: () => void
} & BasicOutputProps) => {
  const [tempHandout, setTempHandout] = useState<
    | {
        id: number
        responseIds: number[]
      }[]
    | undefined
  >()
  const [tempNarration, setTempNarration] = useState<
    | {
        id: number
        responseIds: number[]
      }[]
    | undefined
  >()
  const handoutIds = useMemo(
    () =>
      overviewData && overviewData.responseIds.length > 0
        ? overviewData.responseIds[0].handoutIds
        : [],
    [overviewData]
  )
  const narrationIds = useMemo(
    () =>
      overviewData && overviewData.responseIds.length > 0
        ? overviewData.responseIds[0].narrationIds
        : [],
    [overviewData]
  )

  const handoutCallback = () => {
    resetState()
  }

  const narrationCallback = () => {
    resetState()
  }

  const handoutContext = useHandoutWithLessonPlanner({
    handoutIds: handoutIds,
    onSuccess() {
      handoutCallback()
      refetchOverview(promptDataId)
    },
  })

  const narrationContext = useNarrationWithLessonPlanner({
    narrationIds: narrationIds,
    onSuccess() {
      narrationCallback()
      refetchOverview(promptDataId)
    },
  })

  const onBack = () => {
    toolOnBack()
    handoutContext.handleCancelApi()
    narrationContext.handleCancelApi()
  }
  const [activeTab, setActiveTab] = useTabs({
    tabs: initialTabs,
    initialTab: initialTabs[0].name,
  })

  const outputData =
    finalizedOutputs.find(item => item.id === currentResponseId)?.output ||
    currentStreamValues

  const loadingHandoutState = useMemo(() => {
    if (autoGenerateHandout) {
      if (!(overviewData && overviewData.responseIds.length > 0)) {
        return true
      }
      if (handoutContext.isLoading && !handoutContext.output) {
        return true
      }
      return false
    }
    return false
  }, [
    autoGenerateHandout,
    handoutContext.isLoading,
    handoutContext.output,
    overviewData,
  ])
  const loadingNarrationState = useMemo(() => {
    if (autoGenerateNarration) {
      if (!(overviewData && overviewData.responseIds.length > 0)) {
        return true
      }
      if (narrationContext.isLoading && !narrationContext.output) {
        return true
      }
      return false
    }
    return false
  }, [
    autoGenerateNarration,
    narrationContext.isLoading,
    narrationContext.output,
    overviewData,
  ])

  useEffect(() => {
    if (
      autoGenerateHandout &&
      overviewData &&
      overviewData.responseIds.length > 0 &&
      currentResponseId &&
      (tempHandout?.length === 0 || handoutIds.length === 0)
    ) {
      handoutContext.generateToolWithLessonPlan.mutate({
        data: {
          lessonPlanResponse: currentResponseId,
          generatedWithLessonPlan: true,
          groupId: generateUuidV4(),
        },
        skipOnSuccess: true,
        onSuccess: id => {
          setTempHandout([{ id, responseIds: [id] }])
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoGenerateHandout, currentResponseId, overviewData])

  useEffect(() => {
    if (
      autoGenerateNarration &&
      overviewData &&
      overviewData.responseIds.length > 0 &&
      currentResponseId &&
      (tempNarration?.length === 0 || narrationIds.length === 0)
    ) {
      narrationContext.generateToolWithLessonPlan.mutate({
        data: {
          lessonPlanResponse: currentResponseId,
          generatedWithLessonPlan: true,
          groupId: generateUuidV4(),
        },
        skipOnSuccess: true,
        onSuccess: id => {
          setTempNarration([{ id, responseIds: [id] }])
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoGenerateNarration, currentResponseId, overviewData])

  return (
    <div className="mb-4 flex flex-col gap-2">
      {overviewData && !stream.isError && (
        <InputDisplay data={{ ...overviewData }} />
      )}
      <LessonPlanTabs
        className="mx-0 border-0 pb-0 pt-1"
        tabs={initialTabs}
        activeTabName={activeTab.name}
        isNarrationLoading={loadingNarrationState}
        isHandoutLoading={loadingHandoutState}
        disable={
          overviewData && overviewData.responseIds.length === 0
            ? initialTabs
                .map(item => item.name)
                .filter(i => i !== activeTab.name)
            : []
        }
        onClick={state => {
          setActiveTab(state)
        }}
      />
      {activeTab.name === getToolName(ToolType.lessonPlan) && (
        <div>
          {stream.isError ? (
            <AIToolsError onClick={onBack} />
          ) : (
            <Paper
              className="p-0"
              endSlot={
                !isLoading ? (
                  <OutputToolbar
                    toolType={ToolType.lessonPlan}
                    responseIds={responseIds}
                    currentResponseId={currentResponseId}
                    copyText={outputData}
                    isPositiveResponse={isPositiveResponse}
                    onResponseIdChange={responseId => {
                      setCurrentResponseId(responseId)
                    }}
                    onUserReaction={reaction => {
                      userVote.mutate({ reaction, isPro: false })
                    }}
                    onRegenerate={reason => {
                      regenerateResponse.mutate({ reason, isPro: false })
                    }}
                  />
                ) : undefined
              }
            >
              <ToolOutput
                isLoading={isLoading}
                onClick={() => {
                  if (responseIds) {
                    setCurrentResponseId(
                      responseIds?.length > 1
                        ? responseIds[responseIds?.length - 1]
                        : responseIds[0]
                    )
                  }
                }}
              >
                {outputData}
              </ToolOutput>
            </Paper>
          )}
        </div>
      )}
      {activeTab.name === getToolName(ToolType.handout) && (
        <div>
          <TemplateHandoutsList
            {...handoutContext}
            handoutIds={handoutIds.length ? handoutIds : tempHandout || []}
            lessonPlanId={currentResponseId}
            lessonPlanLoading={
              isLoading ||
              loadingHandoutState ||
              (overviewData && overviewData.responseIds.length === 0)
            }
            onGeneration={id => {
              setTempHandout([{ id, responseIds: [id] }])
            }}
            resetTempHandout={() => {
              setTempHandout([])
            }}
            awaitLessonPlan={Boolean(
              overviewData && overviewData.responseIds.length === 0
            )}
          />
        </div>
      )}

      {activeTab.name === getToolName(ToolType.narration) && (
        <div>
          <TemplateNarrationsList
            {...narrationContext}
            narrationIds={
              narrationIds.length ? narrationIds : tempNarration || []
            }
            lessonPlanId={currentResponseId}
            lessonPlanLoading={
              isLoading ||
              loadingNarrationState ||
              (overviewData && overviewData.responseIds.length === 0)
            }
            onGeneration={id => {
              setTempNarration([{ id, responseIds: [id] }])
            }}
            resetTempNarration={() => {
              setTempNarration([])
            }}
            awaitLessonPlan={Boolean(
              overviewData && overviewData.responseIds.length === 0
            )}
          />
        </div>
      )}
      {/* {currentResponseId && (
        <>
          {activeTab.name === getToolName(ToolType.lessonPlan) &&
            !hasHandout && (
              <GenerateHandoutNudge
                {...handoutContext}
                autoGenerate={autoGenerateHandout}
                lessonPlanId={currentResponseId}
                onGeneration={id => {
                  setTempHandout([{ id, responseIds: [id] }])
                  onGenerateHandout()
                }}
                awaitLessonPlan={Boolean(
                  overviewData && overviewData.responseIds.length > 0
                )}
              />
            )}
          {activeTab.name === getToolName(ToolType.lessonPlan) &&
            !narrationIds.length && (
              <GenerateNarrationNudge
                {...narrationContext}
                autoGenerate={autoGenerateNarration}
                lessonPlanId={currentResponseId}
                onGeneration={onGenerateNarration}
                awaitLessonPlan={isLoading}
              />
            )}
        </>
      )} */}
    </div>
  )
}

// const GenerateHandoutNudge = memo(
//   ({
//     autoGenerate,
//     lessonPlanId,
//     isLoading,
//     output,
//     generateToolWithLessonPlan,
//     awaitLessonPlan,
//     onGeneration,
//   }: {
//     onGeneration: (id: number) => void
//     lessonPlanId: number
//     autoGenerate: boolean
//     awaitLessonPlan: boolean
//   } & Pick<
//     HandoutReturnType,
//     "isLoading" | "output" | "generateToolWithLessonPlan"
//   >) => {
//     useEffect(() => {
//       if (autoGenerate && awaitLessonPlan) {
//         generate()
//       }
//       // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [awaitLessonPlan])

//     const generate = async () => {
//       if (!awaitLessonPlan) {
//         toast.info("Please wait while lesson plan generates")
//       } else {
//         await generateToolWithLessonPlan.mutateAsync({
//           data: {
//             lessonPlanResponse: lessonPlanId,
//             generatedWithLessonPlan: true,
//             groupId: generateUuidV4(),
//           },
//           onSuccess: onGeneration,
//         })
//       }
//     }

//     const loadingState = useMemo(() => {
//       if (autoGenerate) {
//         if (!awaitLessonPlan) {
//           return true
//         }
//         if (isLoading && !output) {
//           return true
//         }
//         return false
//       }
//       return false
//     }, [autoGenerate, awaitLessonPlan, isLoading, output])

//     return (
//       <Paper className="flex flex-wrap items-center justify-between gap-1">
//         <Typography variant="title3">Handout for the Lesson</Typography>

//         <GenerateButton
//           onClick={generate}
//           loading={loadingState}
//           label="Generate Handout"
//         />
//       </Paper>
//     )
//   }
// )
// GenerateHandoutNudge.displayName = "GenerateHandoutNudge"

// const GenerateNarrationNudge = memo(
//   ({
//     autoGenerate,
//     lessonPlanId,
//     isLoading,
//     output,
//     generateToolWithLessonPlan,
//     awaitLessonPlan,
//     onGeneration,
//   }: {
//     onGeneration: () => void
//     lessonPlanId: number
//     autoGenerate: boolean
//     awaitLessonPlan: boolean
//   } & Pick<
//     NarrationReturnType,
//     "isLoading" | "output" | "generateToolWithLessonPlan"
//   >) => {
//     useEffect(() => {
//       if (autoGenerate && !awaitLessonPlan) {
//         generate()
//       }
//       // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [autoGenerate, awaitLessonPlan])

//     const generate = async () => {
//       if (awaitLessonPlan) {
//         toast.info("Please wait while lesson plan generates")
//       } else {
//         await generateToolWithLessonPlan.mutateAsync({
//           data: {
//             lessonPlanResponse: lessonPlanId,
//             generatedWithLessonPlan: true,
//             groupId: generateUuidV4(),
//           },
//           onSuccess: onGeneration,
//         })
//       }
//     }

//     return (
//       <Paper className="flex flex-wrap items-center justify-between gap-1">
//         <Typography variant="title3">Narration for the Lesson</Typography>

//         <GenerateButton
//           onClick={generate}
//           loading={(isLoading && !output) || awaitLessonPlan}
//           label="Generate Narration"
//         />
//       </Paper>
//     )
//   }
// )
// GenerateNarrationNudge.displayName = "GenerateNarrationNudge"
export default BasicOutput
