import { ToolType } from "api/resources/aiTools/types"
import clsx from "clsx"

import Paper from "../../../Paper"
import AIToolsError from "../../helpers/AIToolsError"
import { InputDisplay } from "../../helpers/InputDisplay"
import { RubricsReturnType } from "../../RubricsGenerator/useRubrics"
import OutputToolbar from "../OutputToolbar"
import ToolOutput from "../ToolOutput"
import styles from "./Rubrics.module.css"

const cleanOutputText = (output: string | undefined) => {
  if (!output) return ""

  return output
    .replace("### Rubrics:", "")
    .replace("### Rubric:", "")
    .replace("**Rubrics:**", "")
    .replace("**Rubric:**", "")
}

const RubricOutput = (
  props: Pick<
    RubricsReturnType,
    | "output"
    | "isLoading"
    | "isPositiveResponse"
    | "currentResponseId"
    | "setCurrentResponseId"
    | "regenerateResponse"
    | "overviewData"
    | "responseIds"
    | "userVote"
    | "onBack"
    | "stream"
    | "streamPro"
  >
) => {
  const {
    output,
    overviewData,
    responseIds,
    currentResponseId,
    isPositiveResponse,
    setCurrentResponseId,
    userVote,
    regenerateResponse,
    isLoading,
    onBack,
    stream,
    streamPro,
  } = props
  const hasError = stream.isError || streamPro.isError
  return (
    <div className="mb-4 flex flex-col gap-2">
      {overviewData && !hasError && <InputDisplay data={{ ...overviewData }} />}
      {hasError ? (
        <AIToolsError onClick={onBack} />
      ) : (
        <Paper
          className="p-0"
          endSlot={
            isLoading ? undefined : (
              <OutputToolbar
                toolType={
                  overviewData?.isPro ? ToolType.rubricsPro : ToolType.rubrics
                }
                responseIds={responseIds}
                currentResponseId={currentResponseId}
                copyText={output}
                isPositiveResponse={isPositiveResponse}
                onResponseIdChange={responseId => {
                  setCurrentResponseId(responseId)
                }}
                onUserReaction={reaction => {
                  userVote.mutate({ reaction, isPro: overviewData!.isPro })
                }}
                onRegenerate={reason => {
                  regenerateResponse.mutate({
                    reason,
                    isPro: overviewData!.isPro,
                  })
                }}
              />
            )
          }
        >
          <div className={clsx("overflow-x-auto", styles.tableContainer)}>
            <ToolOutput
              isLoading={isLoading}
              onClick={() => {
                if (responseIds) {
                  setCurrentResponseId(
                    responseIds?.length > 1
                      ? responseIds[responseIds?.length - 1]
                      : responseIds[0]
                  )
                }
              }}
            >
              {cleanOutputText(output)}
            </ToolOutput>
          </div>
        </Paper>
      )}
    </div>
  )
}

export default RubricOutput
