import { Button, Typography } from "@suraasa/placebo-ui"
import { ArrowRight } from "iconoir-react"
import { TooltipRenderProps } from "react-joyride"
import { useNavigate } from "react-router-dom"
import routes from "routes"

const OnboardingStep = ({
  toolTipRenderProps,
  onComplete,
}: {
  toolTipRenderProps: TooltipRenderProps
  onComplete: () => void
}) => {
  const navigate = useNavigate()
  const { closeProps, index, primaryProps, step, isLastStep, size } =
    toolTipRenderProps

  return (
    <div className="min-h-[310px] w-full max-w-[calc(100vw_-_32px)] overflow-hidden rounded-xl bg-white sm:w-[345px] sm:max-w-none">
      <div>{step.content}</div>
      <div className="flex w-full items-center justify-between px-2.5 pb-2.5">
        <Typography variant="preTitle" className="text-onSurface-500">
          {index + 1} OF {size}
        </Typography>
        <div className="flex items-center gap-2">
          {isLastStep && (
            <Button
              variant="text"
              onClick={closeProps.onClick}
              className="text-onSurface-900"
            >
              Done
            </Button>
          )}
          <Button
            size="sm"
            endAdornment={!isLastStep ? <ArrowRight /> : undefined}
            onClick={e => {
              if (isLastStep) {
                onComplete()
                navigate(routes.profile.self)
              } else {
                primaryProps.onClick(e)
              }
            }}
          >
            {isLastStep ? "Explore Profile" : "Next"}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default OnboardingStep
