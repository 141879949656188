import { useQuery } from "@tanstack/react-query"
import api from "api"
import { ToolType } from "api/resources/aiTools/types"
import { APIError } from "api/utils"
import { getToolName } from "features/AItools/helper"
import { useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import routes from "routes"

import CreateFromScratchOrSelect from "../../CreateFromScratchOrSelect"
import Heading from "../../Heading"
import SubjectiveAssessmentForm from "./Form"
import NoLessonPlannerDialog from "./NoLessonPlannerDialog"
import { useSubjectiveAssessment } from "./useSubjectiveAssessment"

const getMode = (searchParams: URLSearchParams) => {
  const param = searchParams.get("mode")
  if (param === "new") return "new"
  if (param === "template") return "template"

  return null
}

const SubjectiveAssessmentGenerator = ({
  toggleHistorySideBar,
}: {
  toggleHistorySideBar: (state: boolean) => void
}) => {
  const subjectiveAssessmentContext = useSubjectiveAssessment()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const [openDialog, setOpenDialog] = useState(false)

  const mode = subjectiveAssessmentContext.promptDataId
    ? "new"
    : getMode(searchParams)

  const onClose = () => {
    setOpenDialog(false)
  }

  const onCreateFromScratch = () => {
    searchParams.set("mode", "new")
    setSearchParams(searchParams)
    onClose()
  }
  const onCreateLessonPlan = () => {
    navigate(routes.aiTools.lessonPlan)
  }

  const { refetch } = useQuery({
    queryFn: x =>
      api.aiTools.listHistory({
        type: ToolType.lessonPlan,
        params: {
          page: x.pageParam || 1,
        },
      }),
    onSuccess: data => {
      if (data.total > 0) {
        searchParams.set("mode", "template")
        setSearchParams(searchParams)
      } else {
        setOpenDialog(true)
      }
    },
    onError: err => {
      if (err instanceof APIError) {
        console.log(err.statusCode)
        console.log(err.is500)
        console.log(err.__errors)
      }
    },

    getNextPageParam: lastPage => {
      return lastPage.nextPage ?? undefined
    },
    enabled: false,
  })

  return (
    <div>
      {!mode && (
        <>
          <Heading
            title={`${getToolName(ToolType.subjectiveAssessment)} Generator`}
            backButtonLabel={mode ? "Back" : undefined}
            subtitle="Craft customised subjective evaluations, ensuring a comprehensive learning experience."
            toggleHistorySideBar={toggleHistorySideBar}
          />
          <NoLessonPlannerDialog
            open={openDialog}
            onClose={onClose}
            onCreateFromScratch={onCreateFromScratch}
            onCreateLessonPlan={onCreateLessonPlan}
          />
          <CreateFromScratchOrSelect
            type={ToolType.subjectiveAssessment}
            onCreateExisting={() => {
              refetch()
            }}
            onCreateNew={() => {
              searchParams.set("mode", "new")
              setSearchParams(searchParams)
            }}
          />
        </>
      )}

      {!subjectiveAssessmentContext.promptDataId ? (
        <>
          {mode === "new" && (
            <SubjectiveAssessmentForm
              toggleHistorySideBar={toggleHistorySideBar}
              subjectiveAssessmentContext={subjectiveAssessmentContext}
            />
          )}
          {mode === "template" && (
            <SubjectiveAssessmentForm
              toggleHistorySideBar={toggleHistorySideBar}
              subjectiveAssessmentContext={subjectiveAssessmentContext}
              openLessonPlanSelector
            />
          )}
        </>
      ) : (
        <SubjectiveAssessmentForm
          toggleHistorySideBar={toggleHistorySideBar}
          subjectiveAssessmentContext={subjectiveAssessmentContext}
        />
      )}
    </div>
  )
}

export default SubjectiveAssessmentGenerator
