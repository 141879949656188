import { Button, Typography } from "@suraasa/placebo-ui"
import BackButton from "components/BackButton"
import React from "react"

type Props = {
  slot?: React.ReactNode
  title?: string
  subtitle?: string
  backButtonLabel?: string
  isPro?: boolean

  toggleHistorySideBar: (state: boolean) => void
}

const Heading = ({
  slot,
  title = "",
  subtitle = "",
  backButtonLabel = "AI Tools",
  toggleHistorySideBar,
}: Props) => {
  return (
    <>
      <div className="mt-1 flex items-center  justify-between">
        <BackButton className="!-ml-2" nudge="left" label={backButtonLabel} />
        <Button
          variant="text"
          className="lg:!hidden"
          onClick={() => {
            toggleHistorySideBar(true)
          }}
        >
          View History
        </Button>
      </div>
      <div className="mb-2 mt-1 flex flex-col items-start justify-between gap-2 md:flex-row">
        <div>
          <Typography variant="title2">{title}</Typography>
          <Typography variant="body" className="mt-1 text-onSurface-500">
            {subtitle}
          </Typography>
        </div>
        {slot}
      </div>
    </>
  )
}
export default Heading
