import { ToolType } from "api/resources/aiTools/types"
import Paper from "features/AItools/components/Paper"

import { HandoutReturnType } from "../../HandoutGenerator/useHandout"
import AIToolsError from "../../helpers/AIToolsError"
import { InputDisplay } from "../../helpers/InputDisplay"
import OutputToolbar from "../OutputToolbar"
import ToolOutput from "../ToolOutput"

const HandoutOutput = (
  props: Pick<
    HandoutReturnType,
    | "output"
    | "isLoading"
    | "responseIds"
    | "currentResponseId"
    | "output"
    | "isPositiveResponse"
    | "setCurrentResponseId"
    | "userVote"
    | "regenerateResponse"
    | "overviewData"
    | "onBack"
    | "stream"
  >
) => {
  const {
    output,
    isLoading,
    responseIds,
    currentResponseId,
    isPositiveResponse,
    setCurrentResponseId,
    userVote,
    regenerateResponse,
    overviewData,
    onBack,
    stream,
  } = props
  return (
    <div className="mb-4 space-y-4">
      {overviewData && !stream.isError && (
        <InputDisplay data={{ ...overviewData }} />
      )}
      {stream.isError ? (
        <AIToolsError onClick={onBack} />
      ) : (
        <Paper
          className="p-0"
          endSlot={
            !isLoading ? (
              <OutputToolbar
                toolType={ToolType.handout}
                responseIds={responseIds}
                currentResponseId={currentResponseId}
                copyText={output}
                isPositiveResponse={isPositiveResponse}
                onResponseIdChange={responseId => {
                  setCurrentResponseId(responseId)
                }}
                onUserReaction={reaction => {
                  userVote.mutate({ reaction })
                }}
                onRegenerate={reason => {
                  regenerateResponse.mutate({ reason })
                }}
              />
            ) : undefined
          }
        >
          {/* {overviewData?.title && (
          <div className="flex items-center justify-between gap-1">
            <Typography variant="title2" className="grow">
              {capitalizeFirstLetter(overviewData.title)}
            </Typography>
          </div>
        )} */}

          <ToolOutput
            isLoading={isLoading}
            onClick={() => {
              if (responseIds) {
                setCurrentResponseId(
                  responseIds?.length > 1
                    ? responseIds[responseIds?.length - 1]
                    : responseIds[0]
                )
              }
            }}
          >
            {output}
          </ToolOutput>
        </Paper>
      )}
    </div>
  )
}

export default HandoutOutput
