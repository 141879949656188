import { Avatar, Typography } from "@suraasa/placebo-ui"
import { Button, Divider } from "@suraasa/placebo-ui-legacy"
import AISparkle from "assets/icons/system/AISparkle"
import User from "assets/icons/system/User"
import clsx from "clsx"
import SlidingSheet from "components/SlidingSheet"
import { DEFAULT_PROFILE_COVER, getCoverImageURL } from "features/Profile/utils"
import { context } from "global/Context/context"
import { useFeatureToggle } from "global/FeatureToggleProvider"
import {
  Book,
  Calendar,
  HeadsetHelp,
  Journal,
  LogOut,
  Medal,
  MediaVideoList,
  MicrophoneSpeaking,
  NavArrowDown,
  Settings,
  Shop,
  ShoppingBag,
  ViewGrid,
} from "iconoir-react"
import { useContext, useState } from "react"
import { Link } from "react-router-dom"
import routes from "routes"
import { buildUserName, getPlatformURL } from "utils/helpers"
import { useEnrollments } from "utils/hooks/useEnrollments"

import { ReactComponent as ItoLogo } from "../../assets/OlympiadLogo.svg"
import styles from "./Sidebar.module.css"

const svgProps = {
  width: 20,
  height: 20,
}

type Props = {
  open: boolean
  handleClose: () => void
}

type MenuItem = {
  key?: string
  name: string
  icon: any
  endIcon?: JSX.Element
  bottomBorder?: boolean
  disabled?: boolean
} & ({ href: string } | { key: "more" | "sign_out" })

const menuItems: MenuItem[] = [
  {
    name: "Suraasa Profile",
    href: routes.profile.self,
    icon: User,
  },
  {
    name: "My Certificates",
    href: routes.certificates,
    icon: Medal,
  },
  {
    name: "My Schedule",
    href: routes.mySchedule,
    icon: Calendar,
    bottomBorder: true,
  },
  {
    name: "Orders and Payments",
    href: routes.orders,
    icon: ShoppingBag,
  },
  {
    name: "Settings",
    href: routes.settings,
    icon: Settings,
  },
  {
    name: "Help Center",
    href: getPlatformURL("sso", "/help"),
    icon: HeadsetHelp,
  },
  {
    name: "Sign Out",
    key: "sign_out",
    icon: LogOut,
    bottomBorder: true,
  },
  {
    name: "More",
    key: "more",
    icon: ViewGrid,
  },
]

const moreMenuItems: MenuItem[] = [
  {
    key: "ito",
    name: "ITO 2022",
    href: routes.ito2022,
    icon: ItoLogo,
    disabled: false,
  },
  {
    key: "learnerHandbook",
    name: "Learner Handbook",
    href: routes.learnerHandbook,
    icon: Book,
    disabled: false,
  },
  {
    name: "AI Tools",
    href: routes.aiTools.home,
    icon: AISparkle,
    disabled: false,
  },
  {
    name: "Store",
    href: getPlatformURL("suraasa", "/store"),
    icon: Shop,
  },
  {
    name: "Masterclasses",
    href: getPlatformURL("suraasa", "/masterclasses"),
    icon: MediaVideoList,
  },
  {
    name: "Blogs",
    href: getPlatformURL("suraasa", "/blog"),
    icon: Journal,
  },
  {
    name: "Podcasts",
    href: "https://podcasters.spotify.com/pod/show/suraasa",
    icon: MicrophoneSpeaking,
  },
  // {
  //   name: "Past Events (Coming Soon)",
  //   href: getPlatformURL("suraasa", "/"),
  //   disabled: true,
  //   icon: BoxIso,
  // },
]

const SidebarMenuItem = ({
  item,
  handleAction,
}: {
  item: MenuItem
  handleAction: () => void
}) => {
  const display = (
    <div
      className={clsx("flex w-full grow items-center gap-1 py-2", {
        "border-b border-onSurface-200": item.bottomBorder,
        "text-onSurface-500": item.disabled,
        "text-critical-500": ("key" in item && item.key) === "sign_out",
      })}
    >
      <item.icon {...svgProps} />
      <Typography variant="subtitle2">{item.name}</Typography>
    </div>
  )
  if ("href" in item && !item.disabled) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link to={item.href} onClick={handleAction}>
        {display}
      </Link>
    )
  }

  return (
    <button
      className="flex items-center justify-between"
      onClick={handleAction}
      disabled={item.disabled}
    >
      {display}
      {item.endIcon && item.endIcon}
    </button>
  )
}

const Sidebar = ({ open, handleClose }: Props) => {
  const { authInfo, itoParticipationDetails, profile, logout } =
    useContext(context)

  const featureToggle = useFeatureToggle()

  const isAIToolsEnabled = featureToggle.aiTools.isEnabled

  const [showMore, setShowMore] = useState(false)

  const { isPGCTLUser } = useEnrollments()

  const userName = authInfo ? buildUserName(authInfo.user) : ""

  const handleAction = (item: MenuItem) => {
    if ("key" in item) {
      switch (item.key) {
        case "more":
          setShowMore(prevState => !prevState)
          return
        case "sign_out":
          logout()
          return
        default:
          throw new Error("Action not handled in profile sidebar")
      }
    }
  }

  const getEndIcon = (item: MenuItem) => {
    if ("key" in item) {
      if (item.key === "more") {
        return (
          <div
            className={clsx(styles.arrowDown, {
              [styles.arrowDownActive]: showMore,
            })}
          >
            <NavArrowDown />
          </div>
        )
      }
    }
  }

  return (
    <SlidingSheet
      mountOnBody
      className={clsx(
        "flex min-w-[320px] flex-col justify-between",
        styles.sidebarContainer
      )}
      open={open}
      onClose={handleClose}
      from="start"
    >
      <div className="flex flex-col">
        <div className="relative">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            onClick={handleClose}
            to={routes.profile.self}
            className="relative mb-[101px] block w-full px-0.5 pt-0.5"
          >
            <div
              style={{
                backgroundImage: `url(${getCoverImageURL(
                  profile.coverStyle || DEFAULT_PROFILE_COVER,
                  true
                )})`,
              }}
              className="relative h-[65px] w-full rounded bg-cover text-black"
            />
            <div className="absolute left-0 top-4.5 flex w-full flex-col items-center">
              <Avatar
                src={profile.picture}
                name={userName}
                className="mb-1 size-9"
              />
              <Typography variant="title4">{userName}</Typography>
              <Typography variant="smallBody" className="text-onSurface-500">
                View Profile
              </Typography>
            </div>
          </Link>
        </div>

        <div className="flex flex-col px-2">
          <Divider color="onSurface.200" weight="light" />
          {menuItems.map((item, index) => (
            <SidebarMenuItem
              item={{ ...item, endIcon: getEndIcon(item) }}
              key={index}
              handleAction={() => {
                if ("href" in item && !item.disabled) {
                  handleClose()
                } else {
                  handleAction(item)
                }
              }}
            />
          ))}

          <div
            className={clsx(styles.moreMenuItem, {
              [styles.moreMenuItemActive]: showMore,
            })}
          >
            <div className="overflow-hidden">
              {moreMenuItems
                // Don't show Ai Tools route if disabled
                .filter(x => (isAIToolsEnabled ? true : x.key !== "aiTools"))
                // Don't show ITO links to non-participants
                .filter(x =>
                  itoParticipationDetails.pastYears.isITO2022Participant
                    ? true
                    : x.key !== "ito"
                )
                .filter(x => (x.key === "learnerHandbook" ? isPGCTLUser : true))
                .map((item, index) => (
                  <SidebarMenuItem
                    item={item}
                    key={index}
                    handleAction={() => {
                      if ("href" in item && !item.disabled) {
                        handleClose()
                      } else {
                        handleAction(item)
                      }
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col px-2 pb-2">
        <Button
          className={clsx(styles.termsConditionsButton)}
          component="a"
          href={getPlatformURL("suraasa", "/privacy-policy")}
          variant="text"
        >
          <Typography variant="smallBody" className="text-onSurface-400">
            Privacy Policy
          </Typography>
        </Button>
        <Button
          className={styles.termsConditionsButton}
          component="a"
          href={getPlatformURL("suraasa", "/terms-of-use")}
          variant="text"
        >
          <Typography variant="smallBody" className="text-onSurface-400">
            Terms and Conditions
          </Typography>
        </Button>

        <Typography variant="smallBody" className="mt-0.25 text-onSurface-400">
          v{APP_VERSION}
        </Typography>
      </div>
    </SlidingSheet>
  )
}

export default Sidebar
