import { Button, TextField } from "@suraasa/placebo-ui"
import {
  ToolType,
  UnitPlanForm,
  UnitPlanTypeEnum,
} from "api/resources/aiTools/types"
import CreatingFor from "features/AItools/components/CreatingFor"
import Paper from "features/AItools/components/Paper"
import { getToolName } from "features/AItools/helper"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"

import { UnitPlannerReturnType } from "../useUnitPlanner"

const toolType = ToolType.unitPlanner
type AutoFillableFields = "grade" | "curriculum" | "subject" | "country"

type Props = Pick<
  UnitPlannerReturnType,
  "generateUnitPlanPrompt" | "formData" | "preferredPromptData"
>
const ProForm = ({
  generateUnitPlanPrompt,
  formData,
  preferredPromptData,
}: Props) => {
  const {
    register,
    handleSubmit,
    setError,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<UnitPlanForm>({
    defaultValues: formData || {
      grade: preferredPromptData?.grade || "",
      curriculum: preferredPromptData?.curriculum || "",
      subject: preferredPromptData?.subject || "",
      country: preferredPromptData?.country || "",
      unitPlanType: UnitPlanTypeEnum.topic,
    },
  })

  useEffect(() => {
    const prefillFields: AutoFillableFields[] = [
      "grade",
      "curriculum",
      "subject",
      "country",
    ]

    if (prefillFields.every(field => preferredPromptData?.[field])) {
      setEditing(false)
    }

    reset(
      formData || {
        grade: preferredPromptData?.grade || "",
        curriculum: preferredPromptData?.curriculum || "",
        subject: preferredPromptData?.subject || "",
        country: preferredPromptData?.country || "",
      }
    )
  }, [preferredPromptData])

  const unitPlanType = watch("unitPlanType")

  const subject = watch("subject")
  const country = watch("country")
  const grade = watch("grade")
  const curriculum = watch("curriculum")

  const onSubmit = handleSubmit(async data => {
    if (unitPlanType === UnitPlanTypeEnum.topic && !data.topic) {
      setError("topic", {
        message: "Required",
      })
    }
    if (unitPlanType === UnitPlanTypeEnum.topic && !data.numberOfDays) {
      setError("numberOfDays", {
        message: "Required",
      })
    }
    if (
      unitPlanType === UnitPlanTypeEnum.academicYear &&
      !data.semesterDuration
    ) {
      setError("semesterDuration", {
        message: "Required",
      })
    }

    await generateUnitPlanPrompt.mutateAsync({
      data: { ...data, unitPlanType: UnitPlanTypeEnum.topic },
      setError,
    })
  })

  const [isEditing, setEditing] = useState(true)

  return (
    <Paper>
      <form onSubmit={onSubmit} className="flex w-full flex-col gap-2">
        {!isEditing && (
          <CreatingFor
            country={country}
            curriculum={curriculum}
            grade={grade}
            subject={subject}
            onEditClick={() => setEditing(true)}
          />
        )}
        {(!grade || !curriculum || isEditing) && (
          <div className="flex flex-wrap gap-2 md:flex-nowrap">
            {(!grade || isEditing) && (
              <TextField
                required
                label="Class/Grade"
                placeholder="Ex: 11th Grade"
                errors={errors.grade?.message}
                {...register("grade", {
                  required: { value: true, message: "Required" },
                })}
              />
            )}

            {(!curriculum || isEditing) && (
              <TextField
                {...register("curriculum", {
                  required: { value: true, message: "Required" },
                })}
                required
                label="Curriculum"
                placeholder="Ex: CBSE"
                errors={errors.curriculum?.message}
              />
            )}
          </div>
        )}
        {(!subject || !country || isEditing) && (
          <div className="flex flex-wrap gap-2 md:flex-nowrap">
            {(!subject || isEditing) && (
              <TextField
                required
                label="Subject"
                placeholder="Ex: Economics"
                errors={errors.subject?.message}
                {...register("subject", {
                  required: { value: true, message: "Required" },
                })}
              />
            )}
            {(!country || isEditing) && (
              <TextField
                required
                label="Country"
                placeholder="Ex: India"
                errors={errors.country?.message}
                {...register("country", {
                  required: { value: true, message: "Required" },
                })}
              />
            )}
          </div>
        )}
        {/* <div className="flex flex-wrap items-center gap-2">
          <Typography variant="strong">Unit Plan for:</Typography>
          <Controller
            control={control}
            name="unitPlanType"
            render={({ field: { value, onChange } }) => (
              <RadioGroup
                className="flex items-center gap-2"
                defaultValue={value}
                onValueChange={onChange}
              >
                <RadioGroupItem
                  containerClass="flex space-x-1 items-center"
                  value={UnitPlanTypeEnum.topic}
                  label="Topic"
                />
                <RadioGroupItem
                  containerClass="flex space-x-1 items-center"
                  value={UnitPlanTypeEnum.academicYear}
                  label="Entire Academic Year"
                />
              </RadioGroup>
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
        </div> */}
        {/* {unitPlanType === UnitPlanTypeEnum.academicYear && (
          <TextField
            label="Book Name (Optional)"
            placeholder="Ex: Introduction to Microeconomics"
            errors={errors.book?.message}
            {...register("book")}
          />
        )} */}
        {/* {unitPlanType === UnitPlanTypeEnum.topic && (
          <> */}
        <TextField
          required
          label="Topic"
          placeholder="Ex: Statistics"
          errors={errors.topic?.message}
          {...register("topic")}
        />
        {/* </>
        )} */}
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          <TextField
            required
            label="Class Duration (in minutes)"
            placeholder="Ex: 45"
            helperText="Duration of a single period/lecture"
            errors={errors.classDuration?.message}
            {...register("classDuration", {
              required: { value: true, message: "Required" },
              pattern: {
                value: /^\d+$/,
                message: "Only numbers are allowed",
              },
            })}
          />
          {/* {unitPlanType === UnitPlanTypeEnum.academicYear && (
            <TextField
              required
              label="Complete Duration of the Semester"
              placeholder="Ex: 6"
              errors={errors.semesterDuration?.message}
              {...register("semesterDuration", {
                pattern: {
                  value: /^\d+$/,
                  message: "Only numbers are allowed",
                },
                min: {
                  value: 1,
                  message: "A semester should be at least 1 month",
                },
              })}
            />
          )} */}
          {/* {unitPlanType === UnitPlanTypeEnum.topic && ( */}
          <TextField
            required
            label="Number of Days"
            placeholder="Ex: 7"
            errors={errors.numberOfDays?.message}
            {...register("numberOfDays", {
              pattern: {
                value: /^\d+$/,
                message: "Only numbers are allowed",
              },
            })}
          />
          {/* )} */}
        </div>

        <Button type="submit" loading={isSubmitting}>
          Generate {getToolName(toolType)}
        </Button>
      </form>
    </Paper>
  )
}

export default ProForm
