import { Typography } from "@suraasa/placebo-ui"
import AiLogo from "assets/AITools/TogglePro/ai-logo.webp"
import AiLogoWhite from "assets/AITools/TogglePro/ai-logo-white.webp"
import { AISparkleFilled } from "assets/icons/system/AISparkle"
import clsx from "clsx"
import React from "react"
import { Link } from "react-router-dom"

import styles from "./Card.module.css"

const Card = ({
  title,
  to,
  gradient,
  description,
  isPro,
  isFree,
}: {
  title: string
  to: string
  gradient: string
  description: string
  isPro?: boolean
  isProSubscribed?: boolean
  isFree?: boolean
  isProAvailable?: boolean
}) => {
  return (
    <Link
      to={to}
      className={clsx(
        styles.parentContainer,
        "group rounded-2xl border border-solid border-surface-300"
      )}
      style={
        {
          "--backgroundColor": gradient,
        } as React.CSSProperties
      }
    >
      <div className="flex h-[190px] w-full flex-col justify-between rounded-2xl p-2">
        <div className="flex items-center justify-between">
          <div>
            <AISparkleFilled fill={gradient} className={styles.svgColor} />
          </div>
          <div>
            {isPro && (
              <div className="flex flex-row items-center gap-0.5">
                <span className="relative text-base font-extrabold leading-6 tracking-[-0.02em]">
                  Plus
                  <img
                    src={AiLogoWhite}
                    alt="ai-logo-white"
                    className={clsx(
                      "absolute -right-1 -top-0.25 hidden size-[11.28px] group-hover:block"
                    )}
                  />
                  <img
                    src={AiLogo}
                    alt="ai-logo"
                    className={clsx(
                      "absolute -right-1 -top-0.25 block size-[11.28px] group-hover:hidden"
                    )}
                  />
                </span>
              </div>
            )}

            {isFree && (
              <div className="rounded bg-[#0F172A1A] px-0.5">
                <Typography
                  style={{
                    fontFamily: "'Clash Display', sans-serif",
                    fontSize: "15.5px",
                    fontWeight: 500,
                  }}
                  className="text-onSurface-900 group-hover:text-inherit"
                >
                  Free
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div className="w-[210px] lg:w-[250px]">
          <Typography variant="title3">{title}</Typography>
          <Typography
            variant={"smallBody"}
            className="mt-1 text-onSurface-500 group-hover:text-inherit"
          >
            {description}
          </Typography>
        </div>
      </div>
    </Link>
  )
}

export default Card
