import {
  CircularProgress,
  Container,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import api from "api"
import {
  Award,
  Certification,
  Interest,
  LanguageResponse,
  MasterclassCertificate,
  Profile as ProfileType,
  Project,
  Publication,
  Qualification,
  Skill,
  SkillEvidenceOverview,
  SubjectResponse,
  TestScore,
  WorkExperienceType,
  WorkLocationInterest,
} from "api/resources/profile/types"
import { APIResponse } from "api/types"
import { APIError } from "api/utils"
import CoverStyle from "features/CoverStyle"
// import { GlobalContext } from "components/GlobalState"
import About from "features/Profile/components/About"
import Academics from "features/Profile/components/Academics"
import Achievements from "features/Profile/components/Achievements"
import Interests from "features/Profile/components/Interests"
import Languages from "features/Profile/components/Languages"
import PersonalInfo from "features/Profile/components/PersonalInfo"
import Subjects from "features/Profile/components/Subjects"
import WorkExperience from "features/Profile/components/WorkExperience"
import WorkLocationInterests from "features/Profile/components/WorkLocationInterests"
import { context } from "global/Context/context"
// import Navbar from "components/shared/Navbar"
import { useContext, useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { handleErrors } from "utils/helpers"
// import { trackUser } from "utils/helpers"
import useArray from "utils/hooks/useArray"

import CheckListBanner from "./components/CheckListBanner"
import EducationWithSuraasa from "./components/EducationWithSuraasa"
import MasterclassesAttended from "./components/MasterclassesAttended"
import Overview from "./components/Overview"
import AddResume from "./components/Overview/AddResume"
import Resume from "./components/Resume"
import SkillProfileOverview from "./components/SkillProfileOverview"
import Skills from "./components/Skills"
import UploadResumeBanner from "./components/UploadResumeBanner"
// import ReOrderingFeatureOnboarding from "./components/ReOrderingFeatureOnboarding"
import ProfileContext from "./context"
import PersonalDetailsSheet from "./Edit/PersonalDetailsSheet"
import ProfileOnboarding from "./ProfileOnboarding"
import { usePreviewMode } from "./utils"
import VideoPortfolio from "./VideoPortfolio"
import AddVideoBanner from "./VideoPortfolio/AddVideoBanner"

const Profile = () => {
  const [searchParams] = useSearchParams()

  const { previewMode, isViewingAsOtherPlatform } = usePreviewMode()

  const { fetchProfile } = useContext(context)
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const [hideVideoBanner, setHideVideoBanner] = useState(false)

  const [loading, setLoading] = useState(true)
  const { username } = useParams<{ username?: string }>()

  const isPublic = !!username || previewMode || isViewingAsOtherPlatform

  const [changeCoverStyle, setChangeCoverStyle] = useState(false)

  const [openAddResume, setOpenAddResume] = useState(false)

  const [profile, setProfile] = useState<ProfileType>({
    id: "",
    totalWorkingExperience: 0,
    careerAspiration: null,
    countriesWorkedIn: [],
    country: null,
    currentWorkingExperience: null,
    dateOfBirth: null,
    coverStyle: undefined,
    gender: null,
    isVerified: false,
    lookingForJobs: false,
    nationality: null,
    phoneNumber: null,
    picture: null,
    state: null,
    user: {
      uuid: "",
      email: "",
      firstName: "",
      lastName: null,
      middleName: "",
      username: "",
    },
  })

  const workExperiences = useArray<WorkExperienceType>([])
  const [masterclasses, setMasterclasses] = useState<MasterclassCertificate[]>(
    []
  )
  const languages = useArray<LanguageResponse>([])
  const awards = useArray<Award>([])
  const projects = useArray<Project>([])
  const publications = useArray<Publication>([])
  const testScores = useArray<TestScore>([])
  const subjects = useArray<SubjectResponse>([])
  const qualifications = useArray<Qualification>([])
  const certifications = useArray<Certification>([])
  const workLocationInterest = useArray<WorkLocationInterest>([])
  const interests = useArray<Interest>([])
  const skills = useArray<Skill>([])
  const [skillEvidences, setSkillEvidences] = useState<SkillEvidenceOverview>({
    advancedSkillCount: 0,
    beginnerSkillCount: 0,
    intermediateSkillCount: 0,
    professional: 0,
  })
  const [videoPortfolioURL, setVideoPortfolioURL] = useState<string | null>(
    null
  )
  const [editProfile, setEditProfile] = useState(false)

  const [videoPortfolioDialogOpen, setVideoPortfolioDialogOpen] =
    useState(false)

  const handleUpdate = async () => {
    try {
      const profile = await api.profile.retrieve({
        urlParams: {
          username: username ?? "",
        },
      })

      setProfile(profile)
    } catch (e) {
      console.error(e)
    }
  }

  const toggleChangeCoverStyle = () => {
    setChangeCoverStyle(!changeCoverStyle)
  }
  const toggleAddResume = () => {
    setOpenAddResume(!openAddResume)
  }

  useEffect(() => {
    if (searchParams.get("edit") === "true") {
      setEditProfile(true)
    }
  }, [searchParams])

  useEffect(() => {
    const fetchApi = (
      api: Promise<any>,
      onSuccess: (res: APIResponse<any>) => void,
      skipHandleError?: boolean
    ) => {
      api
        .then(res => {
          onSuccess(res)
        })
        .catch(err => {
          if (skipHandleError) {
            return
          }
          if (err instanceof APIError) {
            handleErrors(err)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }

    const getData = async () => {
      const apis: {
        api: Promise<any>
        onSuccess: (res: APIResponse<any>) => void
        skipHandleError?: boolean
      }[] = [
        {
          api: api.profile.retrieve({
            urlParams: {
              username: username ?? "",
            },
          }),
          onSuccess(res) {
            setProfile(res)
          },
        },
        {
          api: api.profile.listAcademics({
            urlParams: { username: username ?? "" },
          }),
          onSuccess(res) {
            qualifications.set(res.qualifications)
            certifications.set(res.certifications)
          },
        },
        {
          api: api.profile.workExperiences.list({
            urlParams: { username: username ?? "" },
          }),
          onSuccess(res) {
            workExperiences.set(res)
          },
        },
        {
          api: api.profile.listAchievements({
            urlParams: { username: username ?? "" },
          }),
          onSuccess(res) {
            awards.set(res.awards)
            publications.set(res.publications)
            projects.set(res.projects)
            testScores.set(res.testScores)
          },
        },
        {
          api: api.profile.languages.list({
            urlParams: { username: username ?? "" },
          }),
          onSuccess(res) {
            languages.set(res)
          },
        },
        {
          api: api.profile.interests.list({
            urlParams: { username: username ?? "" },
          }),
          onSuccess(res) {
            workLocationInterest.set(res.preferredWorkLocations)
            subjects.set(res.subjects)
            interests.set(res.interests)
          },
        },
        {
          api: username
            ? api.profile.videoPortfolio.retrieve({
                urlParams: { username: username },
              })
            : api.profile.videoPortfolio.retrieveSelf(),
          onSuccess(res) {
            setVideoPortfolioURL(res.videoPortfolio)
          },
          skipHandleError: true,
        },
        {
          api: api.profile.skillEvidences.overview({
            urlParams: { username: username ?? "" },
          }),
          onSuccess(res) {
            setSkillEvidences(res)
          },
        },
        {
          api: api.profile.skills.list({
            urlParams: { username: username ?? "" },
          }),
          onSuccess(res) {
            skills.set(res)
          },
        },
        {
          api: api.profile.credentials.listMasterclassCertificates({
            urlParams: { username: username ?? "" },
          }),
          onSuccess(res) {
            setMasterclasses(res)
          },
        },
      ]

      apis.forEach(item =>
        fetchApi(item.api, item.onSuccess, item.skipHandleError)
      )
    }
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <ProfileContext.Provider
      value={{
        isPublic,
        profile,
        updateProfile: newState => {
          setProfile(prevState => ({
            ...prevState,
            ...newState,
          }))
          fetchProfile()
        },
        languages: {
          data: languages.array,
          update: languages.updateByKey,
          remove: languages.removeByKey,
          add: languages.push,
        },
        achievements: {
          awards: {
            data: awards.array,
            update: awards.updateByKey,
            remove: awards.removeByKey,
            add: awards.push,
          },
          projects: {
            data: projects.array,
            update: projects.updateByKey,
            remove: projects.removeByKey,
            add: projects.push,
          },
          publications: {
            data: publications.array,
            update: publications.updateByKey,
            remove: publications.removeByKey,
            add: publications.push,
          },
          testScores: {
            data: testScores.array,
            update: testScores.updateByKey,
            remove: testScores.removeByKey,
            add: testScores.push,
          },
        },
        workExperiences: {
          data: workExperiences.array,
          update: workExperiences.updateByKey,
          remove: workExperiences.removeByKey,
          add: workExperiences.unshift,
        },
        academics: {
          qualifications: {
            data: qualifications.array,
            update: qualifications.updateByKey,
            remove: qualifications.removeByKey,
            add: qualifications.unshift,
          },
          certifications: {
            data: certifications.array,
            update: certifications.updateByKey,
            remove: certifications.removeByKey,
            add: certifications.unshift,
          },
        },
        subjects: {
          data: subjects.array,
          update: subjects.updateByKey,
          remove: subjects.removeByKey,
          add: subjects.push,
          set: subjects.set,
        },
        workLocationInterest: {
          data: workLocationInterest.array,
          update: workLocationInterest.updateByKey,
          remove: workLocationInterest.removeByKey,
          add: workLocationInterest.push,
          set: workLocationInterest.set,
        },
        interests: {
          data: interests.array,
          update: interests.updateByKey,
          remove: interests.removeByKey,
          add: interests.push,
        },
        skillEvidences,
        skills: {
          data: skills.array,
          update: skills.updateByKey,
          remove: skills.removeByKey,
          add: skills.push,
          set: skills.set,
        },
        masterclasses,
      }}
    >
      <div>
        {loading ? (
          <div className="mt-5 flex justify-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            {changeCoverStyle ? (
              <CoverStyle toggleChangeCoverStyle={toggleChangeCoverStyle} />
            ) : (
              <>
                <Overview
                  toggleAddResume={toggleAddResume}
                  toggleChangeCoverStyle={toggleChangeCoverStyle}
                  openVideoPortfolioDialog={() => {
                    setVideoPortfolioDialogOpen(true)
                  }}
                  videoPortfolioURL={videoPortfolioURL}
                  openEditProfile={() => {
                    setEditProfile(true)
                  }}
                />
                <Container
                  fullWidth={isXsDown}
                  className="pb-5"
                  // this is for 10 column layout
                  // className={clsx({
                  //   "!grid grid-cols-12 gap-2 pb-5 sm:[&>div]:col-span-10 sm:[&>div]:col-start-2 [&>div]:col-span-12 [&>div]:!min-w-[auto] xl:[&>div]:!min-w-[1280px]":
                  //     isPublic,
                  // })}
                >
                  <div>
                    {isPublic && (
                      <div className="flex flex-col gap-2 md:gap-3">
                        <About />
                        <Resume />
                        <EducationWithSuraasa />
                        <SkillProfileOverview />
                        <Academics />
                        <WorkExperience onUpdate={handleUpdate} />
                        <Achievements />
                        <MasterclassesAttended />
                        <Languages />
                        <Subjects />
                        <WorkLocationInterests />
                        <Interests />
                        <Skills />
                        <PersonalInfo />
                      </div>
                    )}
                  </div>
                  {!isPublic && (
                    <div className="grid grid-cols-12 sm:gap-2 md:gap-3">
                      <div className="col-span-12 flex flex-col gap-2 sm:col-span-7 md:col-span-8">
                        {!videoPortfolioURL &&
                          !hideVideoBanner &&
                          !isPublic && (
                            <AddVideoBanner
                              onCloseBanner={() => setHideVideoBanner(false)}
                              openVideoPortfolioDialog={() =>
                                setVideoPortfolioDialogOpen(true)
                              }
                            />
                          )}
                        {!profile.resume && !isPublic && (
                          <UploadResumeBanner
                            toggleAddResume={toggleAddResume}
                          />
                        )}
                        {/* {!isPublic && <ProfileStrength />} */}
                        {!isPublic && (
                          <CheckListBanner
                            handleOpenPersonalDetailsSheet={() => {
                              setEditProfile(true)
                            }}
                          />
                        )}

                        <About />
                        <Resume />
                        <EducationWithSuraasa />
                        <SkillProfileOverview />
                        <Academics />
                        <WorkExperience onUpdate={handleUpdate} />
                        <Achievements />
                        <MasterclassesAttended />
                      </div>

                      <div className="col-span-12 flex flex-col gap-2 sm:col-span-5 md:col-span-4">
                        <Skills />
                        <Languages />
                        <Subjects />
                        <WorkLocationInterests />
                        <Interests />
                        <PersonalInfo />
                      </div>
                    </div>
                  )}
                </Container>
              </>
            )}
          </>
        )}
        {!isPublic && (
          <AddResume open={openAddResume} onClose={toggleAddResume} />
        )}
        <VideoPortfolio
          handleClose={() => setVideoPortfolioDialogOpen(false)}
          openSheet={videoPortfolioDialogOpen}
          onUpload={url => {
            setVideoPortfolioURL(url)
          }}
          setOpenSheet={setVideoPortfolioDialogOpen}
          onDelete={() => {
            setVideoPortfolioURL(null)
          }}
          videoURL={videoPortfolioURL}
        />
        <ProfileOnboarding />
        <PersonalDetailsSheet
          openSheet={editProfile}
          setOpenSheet={setEditProfile}
        />
      </div>
    </ProfileContext.Provider>
  )
}

export default Profile
