import {
  Sheet,
  SheetBody,
  SheetContent,
  SheetHeader,
  Typography,
} from "@suraasa/placebo-ui"
import {
  Button,
  CircularProgress,
  Container,
  IconButton,
  Tag,
  Theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { AssessmentTypeString } from "api/resources/assessments/types"
import HandHoldingPen from "assets/Fallback/hand-holding-pen.svg"
import Monitor from "assets/Fallback/monitor.svg"
import Fallback from "components/Fallback"
import FullPageLoading from "components/FullPageLoading"
import PageNavigation from "components/PageNavigation"
import ReactHelmet from "components/ReactHelmet"
import Paper from "features/AssignmentResult/Paper"
import ReattemptBanner, {
  ReattemptReason,
} from "features/AssignmentResult/ReattemptBanner"
import ReattemptDialog from "features/AssignmentResult/ReattemptDialog"
import { Xmark } from "iconoir-react"
import { useState } from "react"
import { useTheme } from "react-jss"
import { useNavigate, useParams } from "react-router-dom"
import { MAXIMUM_SCORE } from "utils/constants"

import Answer from "./Answer"

// const defaultBoxShadow = "2px 3px 6px rgb(0 0 0 / 13%)"
// const highlightedQuestionShadow = "2px 3px 8px rgb(0 0 0 / 39%)"

export default function AssessmentResult() {
  const { attemptId } = useParams() as { attemptId: string }
  const navigate = useNavigate()

  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))

  const [openReattemptDialog, setOpenReattemptDialog] = useState(false)
  const [showTopicWiseScore, setShowTopicWiseScore] = useState(false)

  const scores = useQuery({
    queryFn: () =>
      api.assessments.getTopicWiseScore({
        urlParams: { attemptId },
      }),
    queryKey: queries.assessments.topicWiseScore(attemptId).queryKey,
    enabled: Boolean(attemptId) && showTopicWiseScore,
  })

  const { data, isLoading, isError, refetch } = useQuery({
    queryFn: () =>
      api.assessments.getSolutions({
        urlParams: { attemptId },
        params: {
          fields: [
            "user_reattempt_counter",
            "reattempt_type",
            "reattempt_request",
          ],
        },
      }),
    enabled: Boolean(attemptId),
    queryKey: queries.assessments.solutions(attemptId).queryKey,
  })

  const onCloseReattemptDialog = () => {
    setOpenReattemptDialog(false)
  }

  if (isError) {
    return (
      <Fallback
        data={{
          image: Monitor,
          title: "",
          description: "",
        }}
        hasError
        className="fixed left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 px-2 text-center sm:w-fit sm:px-0"
      />
    )
  }
  const assessmentName =
    data && "assessmentName" in data ? data.assessmentName : ""

  if (!data || isLoading || !attemptId) return <FullPageLoading />
  console.log(data)

  if ("attemptStatus" in data && data.attemptStatus === "missed") {
    return (
      <>
        <ReattemptDialog
          open={openReattemptDialog}
          onClose={onCloseReattemptDialog}
          title={assessmentName}
          type="assessment"
          onSubmit={() => {
            refetch()
          }}
          learningContentId={data.assessmentId.toString()}
          userPlannerItemId={data.userPlannerItemId}
        />

        <Container className="mt-2" fullWidth={isXs}>
          <PageNavigation />
          <ReactHelmet
            data={{ title: "Assessment Analytics" }}
            dynamicContent={assessmentName}
          />
          <div className="mt-3 flex flex-col flex-wrap justify-between px-2 sm:flex-row sm:items-center sm:px-0">
            <div className="flex items-center justify-between sm:justify-start">
              <Typography variant="title3">{assessmentName} Result</Typography>
              <Tag
                size="md"
                color="primary"
                label="Score: 0"
                className="ms-2 font-bold text-primary-500"
              />
            </div>
            <Tag size="md" color="critical" label="Missed" />
          </div>
          <Paper className="mt-2 py-3 text-center">
            {data.reattemptRequest === "under_review" ? (
              <Typography className="mx-auto max-w-[348.87px]">
                Your request to re-attempt has been submitted. Please wait while
                we process.
              </Typography>
            ) : (
              <>
                <Typography className="mx-auto max-w-[348.87px]">
                  Your score has been marked zero for this assessment as you
                  missed it.{" "}
                  {data.isLatest && (
                    <span>
                      If you wish to improve your score, you can request a
                      reattempt.
                    </span>
                  )}
                </Typography>
                {data.isLatest && (
                  <Button
                    className="mx-auto mt-2"
                    size="sm"
                    onClick={() => {
                      setOpenReattemptDialog(true)
                    }}
                  >
                    Request a Reattempt
                  </Button>
                )}
              </>
            )}
          </Paper>
        </Container>
      </>
    )
  }

  if ("showAnswers" in data || data.score === null) {
    return (
      <div className="flex flex-col items-center">
        <Fallback
          data={{
            image: HandHoldingPen,
            title: "Result is not available for this assessment",
          }}
          className="mb-3 mt-9 sm:mt-20 md:mt-18"
        />
        <Button onClick={() => navigate(-1)}>Go back</Button>
      </div>
    )
  }

  return (
    <>
      <ReattemptDialog
        open={openReattemptDialog}
        onClose={onCloseReattemptDialog}
        title={assessmentName}
        type="assessment"
        onSubmit={() => {
          refetch()
        }}
        learningContentId={data.assessmentId.toString()}
        userPlannerItemId={data.userPlannerItemId}
      />
      {data.assessmentType === AssessmentTypeString.LIVE &&
        data.isLatest &&
        data.score < MAXIMUM_SCORE.assessment && (
          <ReattemptBanner
            onClick={() => {
              setOpenReattemptDialog(true)
            }}
            reattemptRequest={data.reattemptRequest}
            userReattemptCounter={data.userReattemptCounter}
            reason={
              data.score <= data.assessmentPassingPercentage
                ? ReattemptReason.failed
                : ReattemptReason.scoreImprovement
            }
          />
        )}

      <Container className="mt-2" fullWidth={isXs}>
        <PageNavigation />
        <ReactHelmet
          data={{ title: "Assessment Analytics" }}
          dynamicContent={data.assessmentName}
        />

        <div className="mt-3 flex flex-col flex-wrap justify-between px-2 sm:flex-row sm:items-center sm:px-0">
          <div className="flex items-center justify-between sm:justify-start">
            <Typography variant="title3">
              {data.assessmentName} Result
            </Typography>
            {data.score != null && (
              <Tag
                size="md"
                color="primary"
                label={`Score: ${data.score.toFixed(0)}`}
                className="ms-2 font-bold text-primary-500"
              />
            )}
          </div>
          <div className="mt-1 flex items-center gap-1 sm:mt-0">
            <Button size="sm" onClick={() => setShowTopicWiseScore(true)}>
              View Topic Wise Score
            </Button>
            {/* {data.isLatest && data.score !== MAXIMUM_SCORE.assessment && (
              <Menu
                menuButton={
                  <IconButton className="!bg-surface-100 !text-onSurface-900">
                    <MoreVert />
                  </IconButton>
                }
              >
                <MenuItem
                  onClick={() => {
                    setOpenReattemptDialog(true)
                  }}
                  color="critical"
                >
                  Request a Reattempt
                </MenuItem>
              </Menu>
            )} */}
          </div>
        </div>

        <div className="pt-2">
          {data.solutions !== null ? (
            data.solutions.length > 0 ? (
              <div>
                {data.solutions.map((question, index) => (
                  <div
                    key={index}
                    className="mb-2"
                    id={question.questionNumber.toString()}
                  >
                    <Answer question={question} />
                  </div>
                ))}
              </div>
            ) : (
              // This case should never come from backend.
              <div>
                <Typography variant="body">
                  Solutions for this assessment are not available.
                </Typography>
              </div>
            )
          ) : (
            <FullPageLoading />
          )}
        </div>
        {/* Topic wise score sliding sheet */}
        <Sheet
          open={showTopicWiseScore}
          onOpenChange={() => setShowTopicWiseScore(false)}
        >
          <SheetContent height={90} className="max-w-4xl">
            <SheetHeader hideClose>
              <div className="flex items-center">
                <IconButton
                  onClick={() => setShowTopicWiseScore(false)}
                  color="black"
                >
                  <Xmark />
                </IconButton>
                <Typography variant="title2" className="ms-1">
                  Topic-wise Scores
                </Typography>
              </div>
            </SheetHeader>
            <SheetBody>
              {scores.isLoading && (
                <div className="h-40 flex items-center justify-center">
                  <CircularProgress />
                </div>
              )}

              {scores.isSuccess && (
                <div className="px-3 sm:px-6">
                  {scores.data.topicWiseScores.length > 0 ? (
                    scores.data.topicWiseScores.map((topic, index) => (
                      <div
                        style={{
                          boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
                        }}
                        key={index}
                        className="mb-1.25 flex items-center justify-between rounded-lg border border-surface-200 bg-surface-500 px-2 py-2.5"
                      >
                        <Typography variant="body">{topic.topic}</Typography>
                        <Typography
                          variant="title2"
                          className="ms-1 shrink-0 text-primary-500 sm:ms-0"
                        >
                          {topic.score.toFixed(1)}%
                        </Typography>
                      </div>
                    ))
                  ) : (
                    <Typography>Topic-wise scores not available</Typography>
                  )}
                </div>
              )}
              {scores.isError && (
                <Typography variant="title4">Error loading scores</Typography>
              )}
            </SheetBody>
          </SheetContent>
        </Sheet>
      </Container>
    </>
  )
}
