import { Avatar, cn, Divider, Typography } from "@suraasa/placebo-ui"
import { CoverStyle } from "api/resources/profile/types"
import { HOME_ONBOARDING_TARGETS } from "features/Home/HomeOnboarding/constants"
import { DEFAULT_PROFILE_COVER, getCoverImageURL } from "features/Profile/utils"
import { NavArrowDown } from "iconoir-react"
import { useState } from "react"
import { Link, useLocation } from "react-router-dom"
import routes from "routes"
import { getPlatformURL, redirectToLogout } from "utils/helpers"

import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/HoverCard"

const profileDefinedRoutes = [
  routes.profile.self,
  routes.certificates,
  routes.mySchedule,
  routes.orders,
  routes.settings,
]
enum MenuOptions {
  suraasaProfile = "Suraasa Profile",
  myCertificates = "My Certificates",
  mySchedule = "My Schedule",
  ordersAndPayments = "Orders and Payments",
  settings = "Settings",
  helpCenter = "Help Center",
  signOut = "Sign Out",
}

const menuLink = [
  { label: MenuOptions.suraasaProfile, link: routes.profile.self },
  { label: MenuOptions.myCertificates, link: routes.certificates },
  { label: MenuOptions.mySchedule, link: routes.mySchedule },
  { label: MenuOptions.ordersAndPayments, link: routes.orders },
  { label: MenuOptions.settings, link: routes.settings },
  {
    label: MenuOptions.helpCenter,
    link: getPlatformURL("sso", "/help"),
  },
  { label: MenuOptions.signOut, onClick: redirectToLogout },
]

const ProfileMenuButton = ({
  coverStyle,
  src,
  name,
}: {
  coverStyle?: CoverStyle
  src: string | null
  name: string
}) => {
  const location = useLocation()
  const active = profileDefinedRoutes.includes(location.pathname)
  const [open, setOpen] = useState(false)

  const toggleHover = (state: boolean) => {
    setOpen(state)
  }

  return (
    <HoverCard
      openDelay={100}
      closeDelay={100}
      onOpenChange={toggleHover}
      open={open}
    >
      <HoverCardTrigger onClick={() => toggleHover(true)} asChild>
        <button
          className={cn(
            "flex flex-col items-center px-1.5 py-0.75 text-onSurface-500",
            {
              "text-primary-500": active,
            },
            HOME_ONBOARDING_TARGETS.profile
          )}
        >
          <Avatar src={src} name={name} className="size-3" />

          <p className="flex text-sm font-semibold">
            <span className="me-0.25">Me</span>
            <NavArrowDown />
          </p>
        </button>
      </HoverCardTrigger>
      <HoverCardContent className="w-[266px] rounded-lg border border-onSurface-200 bg-white">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          className="relative mb-[101px] block w-full px-0.5 pt-0.5"
          onClick={() => {
            toggleHover(false)
          }}
          to={routes.profile.self}
        >
          <div
            style={{
              backgroundImage: `url(${getCoverImageURL(
                coverStyle || DEFAULT_PROFILE_COVER,
                true
              )})`,
            }}
            className="relative h-[65px] w-full rounded bg-cover text-black"
          />
          <div className="absolute left-0 top-4.5 flex w-full flex-col items-center">
            <Avatar src={src} name={name} className="mb-1 size-9" />
            <Typography variant="title4">{name}</Typography>
            <Typography variant="smallBody" className="text-onSurface-500">
              View Profile
            </Typography>
          </div>
        </Link>
        <Divider className="my-1 bg-onSurface-200" />
        <div className="flex flex-col gap-0.25 px-1">
          {menuLink.map(i => {
            if (i.link) {
              return (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                  key={i.label}
                  to={i.link}
                  onClick={() => {
                    toggleHover(false)
                  }}
                >
                  <button
                    className={cn(
                      "w-full rounded p-1 text-start text-onSurface-900 hover:bg-[#F1F5F9]",
                      {
                        "text-critical-500 hover:bg-critical-50":
                          i.label === MenuOptions.signOut,
                        "bg-primary-50 text-primary-500":
                          i.link === location.pathname,
                      }
                    )}
                  >
                    <Typography variant="strongSmallBody">{i.label}</Typography>
                  </button>
                </Link>
              )
            }

            if (i.onClick) {
              return (
                <button
                  key={i.label}
                  onClick={() => {
                    i.onClick()
                    toggleHover(false)
                  }}
                  className={cn(
                    "w-full rounded p-1 text-start text-onSurface-900 hover:bg-[#F1F5F9]",
                    {
                      "text-critical-500 hover:bg-critical-50":
                        i.label === MenuOptions.signOut,
                      "bg-primary-50 text-primary-500":
                        i.link === location.pathname,
                    }
                  )}
                >
                  <Typography variant="strongSmallBody">{i.label}</Typography>
                </button>
              )
            }
          })}
        </div>
        <Divider className="my-1 bg-onSurface-200" />
        <div className="flex items-center justify-start gap-0.5 pb-1 pl-2 text-left text-xs font-normal leading-[15.6px] text-onSurface-500">
          <Link to={getPlatformURL("suraasa", "/terms-of-use")}>
            Terms and Conditions
          </Link>
          <span>•</span>
          <Link to={getPlatformURL("suraasa", "/privacy-policy")}>
            Privacy Policy
          </Link>
        </div>
      </HoverCardContent>
    </HoverCard>
  )
}

export default ProfileMenuButton
