import { Button, Select, TextArea, TextField } from "@suraasa/placebo-ui"
import {
  RubricsProForm,
  RubricType,
  ToolType,
} from "api/resources/aiTools/types"
import CreatingFor from "features/AItools/components/CreatingFor"
import Paper from "features/AItools/components/Paper"
import {
  criteriaLevelOptions,
  getToolName,
  proPointScaleOptions,
  rubricsTypeOptions,
} from "features/AItools/helper"
import { useSuraasaPlusContext } from "features/AItools/PRO/context/SuraasaPlusContext"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"

import { RubricsReturnType } from "../useRubrics"

const toolType = ToolType.rubricsPro
type AutoFillableFields = "grade" | "curriculum"

type Props = Pick<
  RubricsReturnType,
  "generateProTool" | "formData" | "preferredPromptData"
>

const ProForm = ({ generateProTool, formData, preferredPromptData }: Props) => {
  const {
    register,
    control,
    setError,
    resetField,
    clearErrors,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<RubricsProForm>({
    defaultValues: formData?.proForm || {
      grade: preferredPromptData?.grade || "",
      curriculum: preferredPromptData?.curriculum || "",
    },
  })

  useEffect(() => {
    const prefillFields: AutoFillableFields[] = ["grade", "curriculum"]

    if (prefillFields.every(field => preferredPromptData?.[field])) {
      setEditing(false)
    }

    reset(
      formData?.basicForm || {
        grade: preferredPromptData?.grade || "",
        curriculum: preferredPromptData?.curriculum || "",
      }
    )
  }, [preferredPromptData])

  const { ensurePlusAccess } = useSuraasaPlusContext()

  const onSubmit = handleSubmit(async data => {
    ensurePlusAccess(async () => {
      await generateProTool.mutateAsync({ data, setError })
    })
  })

  const rubricType = watch("rubricType")

  useEffect(() => {
    resetField("pointOfScale")
    clearErrors("pointOfScale")
    setValue("pointOfScale", undefined)

    resetField("criteriaLevel")
    clearErrors("criteriaLevel")
    setValue("criteriaLevel", undefined)
  }, [rubricType])

  const [isEditing, setEditing] = useState(true)

  const grade = watch("grade")
  const curriculum = watch("curriculum")

  return (
    <>
      <Paper>
        <form onSubmit={onSubmit} className="flex flex-col gap-2">
          {!isEditing && (
            <CreatingFor
              curriculum={curriculum}
              grade={grade}
              onEditClick={() => setEditing(true)}
            />
          )}

          {(!grade || !curriculum || isEditing) && (
            <div className="flex flex-wrap gap-2 md:flex-nowrap">
              {(!grade || isEditing) && (
                <TextField
                  {...register("grade", {
                    required: { value: true, message: "Required" },
                  })}
                  required
                  label="Class/Grade"
                  placeholder="Ex: 6th Grade"
                  errors={errors.grade?.message}
                />
              )}
              {(!curriculum || isEditing) && (
                <TextField
                  {...register("curriculum", {
                    required: { value: true, message: "Required" },
                  })}
                  required
                  label="Curriculum"
                  placeholder="Ex: CBSE"
                  errors={errors.curriculum?.message}
                />
              )}
            </div>
          )}

          <div className="flex flex-wrap gap-2 md:flex-nowrap">
            <TextField
              {...register("objective", {
                required: { value: true, message: "Required" },
              })}
              required
              placeholder="Ex: Evaluate creativity of stories written using literary techniques"
              label="Standard/Objective"
              errors={errors.objective?.message}
            />
            <Controller
              control={control}
              name="rubricType"
              render={({ field: { value, onChange, onBlur } }) => (
                <Select
                  required
                  errors={errors.rubricType?.message}
                  label="Rubric Type"
                  options={rubricsTypeOptions}
                  placeholder="Ex: Analytic"
                  mountOnBody
                  onBlur={onBlur}
                  value={rubricsTypeOptions.find(c => c.value === value)}
                  onChange={val => onChange(val?.value)}
                />
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />
          </div>

          {rubricType && rubricType !== RubricType.singlePoint && (
            <Controller
              control={control}
              name="pointOfScale"
              render={({ field: { value, onChange, onBlur } }) => (
                <Select
                  required
                  errors={errors.pointOfScale?.message}
                  label="Performance Level"
                  options={proPointScaleOptions}
                  placeholder="Ex: 5"
                  mountOnBody
                  onBlur={onBlur}
                  value={
                    proPointScaleOptions.find(c => c.value === value) ?? null
                  }
                  onChange={val => onChange(val?.value)}
                />
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />
          )}

          {rubricType && rubricType !== RubricType.holistic && (
            <Controller
              control={control}
              name="criteriaLevel"
              render={({ field: { value, onChange, onBlur } }) => (
                <Select
                  errors={errors.criteriaLevel?.message}
                  label="Criteria Level"
                  options={criteriaLevelOptions}
                  placeholder="Ex: 5"
                  mountOnBody
                  onBlur={onBlur}
                  value={
                    criteriaLevelOptions.find(c => c.value === value) ?? null
                  }
                  onChange={val => onChange(val?.value)}
                />
              )}
            />
          )}

          <TextArea
            {...register("assignmentDescription", {
              required: { value: true, message: "Required" },
            })}
            required
            label="Description"
            placeholder={`Ex: Students will write original short stories inspired by a theme or prompt, using literary techniques like imagery and dialogue. They should focus on clear structure, character/setting development, and personal voice. The assignment includes drafting, revising, and editing. Rubrics should assess creativity, technique, structure, and revisions.`}
            errors={errors.assignmentDescription?.message}
            rows={6}
          />

          <Button type="submit" loading={isSubmitting}>
            Generate {getToolName(toolType)}
          </Button>
        </form>
      </Paper>
    </>
  )
}

export default ProForm
