import { Button, Typography } from "@suraasa/placebo-ui"
import { ArrowRight } from "iconoir-react"
import { TooltipRenderProps } from "react-joyride"

const CustomTooltip = (props: TooltipRenderProps) => {
  const { index, primaryProps, step, isLastStep, size } = props
  return (
    <div className="min-h-[168px] w-full max-w-[calc(100vw_-_32px)] overflow-hidden rounded-xl bg-white p-2.5 sm:w-[345px] sm:max-w-none">
      <div>{step.content}</div>
      <div className="flex w-full items-center justify-between">
        <Typography variant="preTitle" className="text-onSurface-500">
          {index + 1} OF {size}
        </Typography>
        <div className="flex items-center gap-2">
          <Button
            size="sm"
            endAdornment={!isLastStep ? <ArrowRight /> : undefined}
            onClick={primaryProps.onClick}
          >
            {isLastStep ? "Done" : "Next"}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CustomTooltip
